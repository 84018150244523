/*  eslint-disable max-len */
import React from 'react';

function RefundAndCancellationPolicy() {
  return (
    <div style={{ backgroundColor: 'gray' }}>
      <p className="p1"><span className="s1">Refund and Cancellation Policy</span></p>
      <p className="p1"><span className="s1">There will be no refunds or cancellations entertained by Curvsort Pvt Ltd. All orders and terms need to be checked by the customer before placing a order and before making a payment. As a courtesy, attempt will be made to minimse any loss incurring to a party if it is seen and observed as a helping hand.</span></p>
      <p className="p2"><span className="s1">Only Services and Products bought exclusively from the CurvSort Pvt Ltd Private Limited site can be subject to consideration. We will certainly not accept returns of Services bought from any other third-party websites. To reach out us in case of any changes, updates or clarifications, mail us at: support@curvsort.com. Please allow 48-72 working hours to revert on the same. If at all returns are processed in full or portion, you will be sent a message on registered mobile no. This processing will take 2 months.</span></p>
      <p className="p3"><span className="s1">Terms &amp; Conditions:</span></p>
      <ul className="ul1">
        <li className="li4"><span className="s2" /><span className="s1">All Services purchased have to be accompanied by a copy of the original invoice.</span></li>
        <li className="li4"><span className="s1">The service is not eligible for refund once the project is approved and initiated.</span></li>
        <li className="li4"><span className="s1">The service purchased can be considered for cancellation only 24 hours prior to project initiation.</span></li>
        <li className="li4"><span className="s1">We interact with our clients and do our best to solve all their queries and start work after their approval, therefore, there is no provision for any kind of refund after project initiation. We hence, clearly mention that the paid amount is non-refundable in any circumstance.</span></li>
      </ul>
      <p className="p2"><span className="s1">Please read and make sure you fully understand our refund and cancellation policy prior to making a payment. Should you have other questions about our policies, feel free to mail us or call us. Please note: Although we have tried to precisely show the actual videos of the Services, there may be a minor variation based on the operating system you are using.</span></p>
    </div>
  );
}

export default RefundAndCancellationPolicy;
/*  eslint-enable max-len */
