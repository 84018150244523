import React from 'react';
import './Furl.css';

const Furl = ({ response }) => (
  <div className="furl">
    {console.debug('Furl')}
    Your transaction is failed
    <div>
      {response}
    </div>
  </div>
);

export default Furl;
