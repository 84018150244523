import React, { useState } from 'react';
import './EdTech.css';

function SidebarItem(props) {
  const [open, setOpen] = useState(false);
  const { item, page, setPage } = props;

  if (item.childrens) {
    //  item.childrens.map((child) => ((child.path === page) ? setOpen(true) : null));
    return (
      <div className={open ? 'sidebar-item open' : 'sidebar-item'}>
        <div className="sidebar-title">
          <span>
            { item.icon && <i className={item.icon} /> }
            {item.title}
          </span>
          <i className="bi-chevron-down toggle-btn" onClick={() => setOpen(!open)} />
        </div>
        <div className="sidebar-content">
          { item.childrens.map((child, index) => <SidebarItem key={index} item={child} page={page} setPage={setPage} />) }
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        margin: '15px',
        cursor: 'pointer',
        textDecoration: (page === item.path) ? 'underline' : 'none',
        color: (page === item.path) ? 'lightblue' : 'white',
        fontWeight: (page === item.path) ? 'bold' : 'normal',
      }}
      onClick={() => setPage(item.path)}
    >
      { item.icon && <i className={item.icon} style={{ marginRight: '5px' }} /> }
      {item.title}
    </div>
  );
}

export default SidebarItem;
