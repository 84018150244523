import React from 'react';
import ACStarter from './ACStarter';
import './AddContent.css';

function AddContent(props) {
  const { clCardItem, setClCardItem } = props;

  return (
    <div className="AC">
      <ACStarter clCardItem={clCardItem} setClCardItem={setClCardItem} />
    </div>
  );
}

export default AddContent;
