/*  eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
//  import PropTypes from 'prop-types';
import './DropFileInput.css';
//  https://www.youtube.com/watch?v=Aoz0eQAbEUo

import { ImageConfig } from './DropFileInput_ImageConfig';
import uploadImg from '../../../../../../../../../../../../assets/cloud-upload-regular-240.png';
import S3Upload from '../../../../../../../../../../../../components/BackendServices/S3Upload';
// import SaveAsset from '../../../../../../../../../../../../components/BackendServices/SaveAsset';
import CourseCurriculum from '../../../../../../../../../../../../components/BackendServices/CourseCurriculum';

const DropFileInput = (props) => {
  const { curriculum, setCurriculum, onFileChange, lessonParent, isUploadingViaApi, setIsUploadingViaApi } = props;
  // console.log('DropFileInput curriculum: ', curriculum);
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);
  //  eslint-disable-next-line no-unused-vars
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  const fileUpload = async () => {
    console.log('upload: ', fileList);
    setIsUploadingViaApi(true);
    await Promise.all(
      fileList.map(async (file) => {
        // const formData = new FormData();
        // formData.append('file', `videos/${file.name}`);
        // formData.append('api_key', 'abcd1234');
        // formData.append('file', file);
        const assetFilename = `videos/${file.name}`; // "videos/" prefix is reqd for file to land in videos/ folder in s3 bucket
        const contentType = file.type; // 'video/mp4';
        // passing file as parameter in functions should better be replaced with handling it here itself only.
        let response11 = '';
        try {
          response11 = await S3Upload(file, assetFilename, /* formData, */ contentType, setUploadProgress);
          if (response11.status !== 200) {
            console.log('error in S3Upload()');
          }
          console.log('S3Upload response:', response11);
        } catch (error) {
          console.log('TTTTTTTTTTT', error);
          console.log('TTTTTTTTTTT', response11);
        }
        // const response1 = await SaveAsset(assetFilename, lessonParent, category);
        const sectionIndex = lessonParent.SectionIndex;
        const lessonIndex = lessonParent.LessonIndex;

        // update curriculum with video filename
        const updatedLessonList = curriculum.sectionList[sectionIndex].lessonList;
        updatedLessonList[lessonIndex].assets.video.push({
          videoName: `${file.name}`,
          videoID: '',
        });
        const updatedSectionList = curriculum.sectionList;
        updatedSectionList.lessonList = updatedLessonList;
        const updatedCurriculum = { ...curriculum, ...{ sectionList: updatedSectionList } };
        console.log('updatedCurriculum: ', updatedCurriculum);
        const response1 = await CourseCurriculum({ curriculum: updatedCurriculum, assetFilename, lessonParent, action: 'POST_VIDEO_ASSET' });
        if (response1.status !== 200) {
          console.log('error while calling api, handle error.');
        }
        console.log('CourseCurriculum POST_VIDEO_ASSET: ', response1);

        // update curriculum with assetID returned from api response
        const videoID = response1.data.assetID.slice('ASSET_ID#'.length);
        const updatedLessonList2 = updatedCurriculum.sectionList[sectionIndex].lessonList;
        const len = updatedCurriculum.sectionList[sectionIndex].lessonList[lessonIndex].assets.video.length;
        updatedLessonList2[lessonIndex].assets.video[len - 1].videoID = videoID;
        const updatedSectionList2 = curriculum.sectionList;
        updatedSectionList2.lessonList = updatedLessonList2;
        const updatedCurriculum2 = { ...curriculum, ...{ sectionList: updatedSectionList2 } };
        console.log('updatedCurriculum2: ', updatedCurriculum2);
        await setCurriculum(updatedCurriculum2);

        // store updated curriculum in db
      }),
    );
    setIsUploadingViaApi(false);
    setFileList([]);
  };

  if (isUploadingViaApi === true) {
    return (
      <div>
        Uploading ({uploadProgress}%)
      </div>
    );
  }

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={uploadImg} alt="" />
          <p>Drag & Drop your files here</p>
        </div>
        <input type="file" value="" onChange={onFileDrop} />
      </div>
      {
        fileList.length > 0 ? (
          <div className="drop-file-preview">
            <p className="drop-file-preview__title">
              Ready to upload
              <button type="button" style={{ cursor: 'pointer', marginLeft: '10px', color: 'blue', fontWeight: 'bold' }} onClick={fileUpload}>
                Upload
              </button>
            </p>
            {
              fileList.map((item, index) => (
                <div key={index} className="drop-file-preview__item">
                  <img src={ImageConfig[item.type.split('/')[1]] || ImageConfig.default} alt="" />
                  <div className="drop-file-preview__item__info">
                    <p>{item.name}</p>
                    <p>{item.size}B</p>
                  </div>
                  <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                </div>
              ))
            }
          </div>
        ) : null
      }
    </>
  );
};

/*
DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};
*/

export default DropFileInput;
/*  eslint-enable no-unused-vars */
