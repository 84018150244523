import { React, useId } from 'react';

function Subscribe() {
  const subscribeInputId = useId();

  function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.debug('Subscribe Form Data', form.method, formData, formJson.emailID);
  }

  return (
    <div>
      <h4>Subscribe</h4>
      <h4>Join the community recieving weekly updates on </h4>
      <form method="post" onSubmit={handleSubmit}>
        <label htmlFor={subscribeInputId}>
          Email ID:
          <input id={subscribeInputId} name="emailID" type="" defaultValue="name@company.com" />
        </label>
        <button type="submit">Submit form</button>
      </form>
    </div>
  );
}

export default Subscribe;
