import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faLock, faPlay, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
/*
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from '@aws-sdk/client-secrets-manager';
*/
import Navbar from '../Navbar/Navbar';
import PayuPostForm from '../PayU/PayuPostForm';
import './VideoCard.css';
import SocialHandles from '../SocialHandles/SocialHandles';
import FetchGroups from '../BackendServices/FetchGroups';

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}
async function sha512(string) {
  /*
  console.log('AA1');
  const secretName = 'DownloadOriginSalt';
  const client = new SecretsManagerClient({
    region: 'ap-south-1',
  });
  console.log('AA2');
  let response;
  */
  /* eslint-disable-next-line no-useless-catch */
  /*
  try {
    console.log('AA3');
    response = await client.send(
      new GetSecretValueCommand({
        SecretId: secretName,
        VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
      }),
    );
    console.log('AA4');
  } catch (error) {
    // For a list of exceptions thrown, see
    // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
    console.log('Error: ', error);
    throw error;
  }
  const secretSalt = response.SecretString;
  console.log(`AAAAAAAAAA: secretSalt: ${secretSalt}`);
  */
  //  console.log('sha512()');
  const utf8 = new TextEncoder().encode(string);
  await timeout(1000);
  return crypto.subtle.digest('SHA-512', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  });
}

const VideoCard = () => {
  //  console.debug('VideoCard');
  //  const [isLoading, setIsloading] = useState(true);
  //  const [isAllowed, setIsallowed] = useState(true);
  //  let isAllowed = true;
  //  let userCheckGroups = {};
  const navigate = useNavigate();
  const [groups, setGroups] = useState({});
  const [hash1, setHash1] = useState(false);

  /*
  const reqObjVC = {
    key: 'Z4iLcO',
    SALT: '112233',
    name: 'video/vid1.mov',
    //  ttxnid: `${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}`,
  };
  const str = `${reqObjVC.key}|${reqObjVC.SALT}|${reqObjVC.name}`;
  const clientHash = crypto.createHash('sha512').update(str, 'utf-8').digest('hex');
  setHash1(clientHash);
  console.log('clientHash');
  console.log(clientHash);
  console.log(str);
  */
  //  sha512(`${reqObjVC.key}|${reqObjVC.SALT}|${reqObjVC.name}`).then((hcode) => setHash1(hcode));

  /*
    const fetchUpdatedAccessToken = async (tokens) => {
    console.log('fetchRefreshedAccessToken');
    const baseURL = `https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/user/${tokens.userpoolid}/${tokens.username}/refreshedAccessToken`;
    const response = await axios.get(baseURL, {
      headers: {
        //  "Content-Type": "text/json",
        Authorization: `Bearer ${tokens.jwtAccessToken}`,
      },
      params: {
        clientId: tokens.clientid,
        refreshToken: tokens.refToken,
      },
    })
    //  eslint-disable-next-line prefer-arrow-callback
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    console.log('axios.get() refreshedToken response: ', response);

    if ((response !== undefined) && (response.data !== undefined)) {
      return response.data.AccessToken;
    }
    return '';
  };

  const fetchJwtToken = async () => {
    let tokens = {
      jwtAccessToken: '',
      jwtIdToken: '',
      username: '',
      userpoolid: '',
      clientid: '',
      refToken: '',
    };
    try {
      const session = await Auth.currentSession();
      const accesstoken = session.getAccessToken().getJwtToken();
      const idtoken = session.getIdToken().getJwtToken();
      const refreshToken = session.getRefreshToken().getToken();
      const userInfo = await Auth.currentUserInfo();
      const authUser = await Auth.currentAuthenticatedUser();
      tokens = {
        jwtAccessToken: accesstoken,
        jwtIdToken: idtoken,
        username: userInfo.username,
        userpoolid: authUser.pool.userPoolId,
        clientid: authUser.pool.clientId,
        refToken: refreshToken,
        freshAccessToken: '',
      };
      //
      //  If user pays then backend adds him to UserGroup1. This membership is not reflected
      //  i existing jwtAccessToken until user signsout and relogin. To avoid that need
      //  fetch the updated access token and if they are different, use new one going forward.
      //
      const freshAccessToken = await fetchUpdatedAccessToken(tokens);
      tokens.freshAccessToken = freshAccessToken;
      if (tokens.freshAccessToken !== tokens.jwtAccessToken) {
        console.log('freshAccessToken is different. Taking this and moving forward.');
        tokens.jwtAccessToken = tokens.freshAccessToken;
      }
    } catch (error) {
      //  setAmplifyUser('Login');
      console.log('Error fetching JWT token:', error);
    }
    return tokens;
  };
  */

  useEffect(() => {
    (async () => {
      const userCheckGroups = await FetchGroups();
      /*
        isLoading not required because video src to be used is one or the other depending
        upon groups.isUsertierone. Starting render is locked-screen, async FetchGroups gets
        finished, locked-screen gets changed to valid playable-video-scressn
      */
      //  setIsloading(false);
      setGroups(userCheckGroups);
      const reqObjVC = {
        key: 'Z4iLcO',
        SALT: '112233',
        name: 'video/vid1.mov',
      };
      await sha512(`${reqObjVC.key}|${reqObjVC.SALT}|${reqObjVC.name}`).then((hcode) => setHash1(hcode));
    })();
  }, []);

  /*
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  */

  function videoElement() {
    if (hash1 === false) {
      return 'Loading..';
    }
    return (
      <div>
        <iframe title="unique title" width="700" height="700" src={`https://d2tc0jno74e7ty.cloudfront.net/videos/vid1.mp4?Authorization=Bearer ${groups.tokens.jwtAccessToken}&hash=${hash1}`} />
        <div style={{ padding: '177.78% 0 0 0', position: 'relative' }}>
          <iframe
            src="https://player.vimeo.com/video/937661442?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
            title="vid1"
          />
        </div>
        <script src="https://player.vimeo.com/api/player.js" />;
      </div>
    );
  }

  function videoElemenNotAllowed() {
    return (
      <div className="vcTwoTwoVideoElementNotAllowed">
        <div style={{ textAlign: 'center' }}>
          <h3> SignUp to check the freely available talks. There is paid plan for premium membership.</h3>
        </div>
        <div>
          <FontAwesomeIcon icon={faLock} className="lockImage" />
        </div>
        { groups.isUserexist && (!groups.isUsertierone)
          && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}> <PayuPostForm /> </div>
              <div style={{ flex: 1 }} />
              <div style={{ flex: 1, marginLeft: '10px' }}>
                <FontAwesomeIcon icon={faRefresh} size="2x" fontSize={27} color="purple" onClick={() => navigate(0)} />
              </div>
            </div>
          )}
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="videoCardContainer">
        <div className="vcOne">
          <div>
            <h3>Talk #id </h3>
          </div>
          <div>
            <h1>Talk Title: Interactive Marquee </h1>
          </div>
        </div>
        <div className="vcTwo">
          <div className="vcTwoOne">
            <div className="vcTwoOneOne" />
            <div className="vcTwoOneTwo">
              <div className="vcTwoOneTwoItemsHead">
                <h5>In this episode</h5>
                <h5>23:08</h5>
              </div>
              <div className="vcTwoOneTwoItems">
                <h5>Introduction</h5>
                <h5>23:08</h5>
              </div>
              <div className="vcTwoOneTwoItems">
                <h5>Setting Up</h5>
                <h5>23:08</h5>
              </div>
              <div className="vcTwoOneTwoItems">
                <h5>Animating</h5>
                <h5>23:08</h5>
              </div>
              <div className="vcTwoOneTwoItems">
                <h5>Wrapping the Animation</h5>
                <h5>23:08</h5>
              </div>
              <div className="vcTwoOneTwoItems">
                <h5>Number of Copies</h5>
                <h5>23:08</h5>
              </div>
            </div>
            <div className="vcTwoOneThree" />
          </div>
          <div className="vcTwoTwo">
            {/* {isAllowed ? videoElement() : videoElemenNotAllowed()} */}
            {groups.isUsertierone ? videoElement() : videoElemenNotAllowed()}
            {/* { videoElement } */}
          </div>
        </div>
        <div className="vcThree">
          <div className="vcThreeOne">
            <h3>This episode is freely available thanks to the support of our subscribers</h3>
          </div>
          <div className="vcThreeTwo">
            <h10>Subscribers get exclusive access to new and all previous subscriber-only episodes, video downloads, and 30% discount for team members. Become a Subscriber</h10>
          </div>
        </div>
        <div className="vcFour">
          <div className="vcFourOne">
            <h2>We start building a marquee component using SwiftUIs timeline view.</h2>
            <div className="vcFourOneOne">
              <div className="vcFourOneOneOne">
                <h5>00:06</h5>
              </div>
              <div className="vcFourOneOneTwo">
                <h5>Today well start building a little container view that should feel familiar to people who did HTML in the early days: the marquee. Well build this as a container of horizontally laid out views that slide across the frame. The moment the content scrolls out of view, it wraps back to the beginning so that the animation can play continuously.</h5>
              </div>
            </div>
            <div className="vcFourOneTwo">
              <div className="vcFourOneTwoOne">
                <h5>00:37</h5>
              </div>
              <div className="vcFourOneTwoTwo">
                <h5>Wed also like to make the marquee interactive so that we can scroll through the content. After releasing the drag, the marquee should resume the automatic scrolling in a fluid motion by taking the drag gestures velocity into account.</h5>
              </div>
            </div>
          </div>
          <div className="vcFourTwo" />
          <div className="vcFourThree">
            <div className="vcFourThreeItemHead">
              <h3>Resources</h3>
            </div>
            <div className="vcFourThreeItem">
              <FontAwesomeIcon icon={faCode} className="vcFourThreeItemIcon" />
              <div className="vcFourThreeItemOne">
                <h4>Sample Code</h4>
                <h5 className="vcFourThreeItemOneText">Written in Swift 5.9</h5>
              </div>
            </div>
            <div className="vcFourThreeItem">
              <FontAwesomeIcon icon={faPlay} className="vcFourThreeItemIcon" />
              <div className="vcFourThreeItemOne">
                <h4>Episode Video</h4>
                <h5 className="vcFourThreeItemOneText">Become a subscriber to download episode videos.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SocialHandles />
    </div>
  );
};

export default VideoCard;
