import React from 'react';
import SidebarItem from './SBItem.js';
import items from './sidebar.json';
//  https://www.youtube.com/watch?v=sOhLV-lfgjs&t=393s
//  https://www.youtube.com/watch?v=GStKM1PQkmI

function Sidebar(props) {
  const { page, setPage } = props;

  return (
    <div className="sidebar">
      { items.map((item, index) => <SidebarItem key={index} item={item} page={page} setPage={setPage} />) }
    </div>
  );
}

export default Sidebar;
