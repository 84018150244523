/*  eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import './EPPricing.css';
import CourseCurriculum from '../../../../../../../../components/BackendServices/CourseCurriculum';

function EPPricing(props) {
  const { page, setPage, setS1Done, setS2Done, setS3Done, setS4Done, curriculum, setCurriculum, isUploadingViaApi, setIsUploadingViaApi } = props;
  console.log(page, setPage, setS1Done, setS2Done, setS3Done, setS4Done, curriculum, setCurriculum);
  const initialPrice = '1000';
  const initialCurrency = 'INR';
  const [priceWritten, setPriceWritten] = useState(initialPrice);
  const [currencyWritten, setCurrencyWritten] = useState(initialCurrency);

  const handlePriceChange = (e) => {
    console.log('priceChange:', e.target.value);
    setPriceWritten(e.target.value);
  };
  const handleCurrencyChange = (e) => {
    console.log('currencyChange:', e.target.value);
    setCurrencyWritten(e.target.value);
  };
  const handleCancel = async () => {
    console.log('handleCancel');
    if (curriculum.isCoursePriceSaved) {
      setIsUploadingViaApi(true);
      const updatedCurriculum = curriculum;
      updatedCurriculum.isCoursePriceSaved = false;
      const resp = await CourseCurriculum({ curriculum: updatedCurriculum, action: 'RESET_COURSE_PRICE' }); // only rename
      if (resp.status !== 200) {
        console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
      }
      updatedCurriculum.courseID = resp.data.courseID;
      updatedCurriculum.coursePriceID = resp.data.coursePriceID;
      setIsUploadingViaApi(false);
      await setCurriculum(updatedCurriculum);
      setS3Done(false);
    }
  };

  const handleSave = async () => {
    console.log('handleSave');
    if (!curriculum.isCoursePriceSaved) {
      setIsUploadingViaApi(true);
      const updatedCurriculum = curriculum;
      updatedCurriculum.coursePrice = priceWritten;
      updatedCurriculum.courseCurrency = currencyWritten;
      updatedCurriculum.isCoursePriceSaved = true;
      const resp = await CourseCurriculum({ curriculum: updatedCurriculum, action: 'SET_COURSE_PRICE' }); // only rename
      if (resp.status !== 200) {
        console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
      }
      updatedCurriculum.courseID = resp.data.courseID;
      updatedCurriculum.coursePriceID = resp.data.coursePriceID;
      setIsUploadingViaApi(false);
      await setCurriculum(updatedCurriculum);
      setS3Done(true);
    }
  };
  function goAddCurriculum() {
    console.log('goAddCurriculum');
    setPage('edTech');
  }
  function goPublish() {
    console.log('goPublish');
    setPage('publish');
  }

  function PriceAdded() {
    if (curriculum.isCoursePriceSaved) {
      return (
        <div className="EPPR_A3_2_2">
          {curriculum.coursePrice}
        </div>
      );
    }

    return (
      <input
        name="priceAdded"
        type="text"
        id="priceAdded"
        className="EPPR_A3_2"
        autoComplete="off"
        autoCorrect="off"
        required
        defaultValue={curriculum.coursePrice}
        onChange={(e) => handlePriceChange(e)}
      />
    );
  }

  function CurrencyAdded() {
    if (curriculum.isCoursePriceSaved) {
      return (
        <div className="EPPR_A3_2_2">
          {curriculum.courseCurrency}
        </div>
      );
    }

    return (
      <input
        name="currencyAdded"
        type="text"
        id="currencyAdded"
        className="EPPR_A3_2"
        autoComplete="off"
        autoCorrect="off"
        required
        defaultValue={curriculum.courseCurrency}
        onChange={(e) => handleCurrencyChange(e)}
      />
    );
  }

  return (
    <div className="EPPR">

      <div className="EPPR_A26">

        <div className="EPPR_A20" onClick={goAddCurriculum}>
          <div className="EPPR_A21">
            Add Curriculum
          </div>
          <div className="EPPR_A22">
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </div>
        </div>

        <div className="EPPR_A23" onClick={goPublish}>
          <div className="EPPR_A24">
            Publish
          </div>
          <div className="EPPR_A25">
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </div>
        </div>

      </div>

      <div className="EPPR_A1">
        Add a price on your course
      </div>

      <div className="EPPR_A2">
        Subscription based or buy option. Do you want it to be editable later?
      </div>
      {!((curriculum.isInstituteNameSaved) && (curriculum.isCourseNameSaved))
        && (
          <div className="unavailable_pricing">
            Institute Name and Course Name are to be saved first. These can be renamed later.
          </div>
        )}

      <div className="EPPR_A3">
        <div className="EPPR_A3_1">
          Course Price
        </div>
        {PriceAdded()}
      </div>
      <div className="EPPR_A3">
        <div className="EPPR_A3_1">
          Currency
        </div>
        {CurrencyAdded()}
      </div>
      {!(curriculum.isInstituteNameSaved && curriculum.isCourseNameSaved)
        && (
        <div className="EPPR_A6">
          <div className="EPPR_A4">
            <button type="button" className="EPPR_A4_5_unavailable">
              <span>Unavailable</span>
            </button>
            <button type="button" className="EPPR_A4_5_unavailable">
              <span>Unavailable</span>
            </button>
          </div>
        </div>
        )}
      {(curriculum.isInstituteNameSaved && curriculum.isCourseNameSaved)
        && (
        <div className="EPPR_A6">
          { isUploadingViaApi
            && (
            <div className="EPPR_A4">
              <button type="button" className="EPPR_A4_5">
                <span>Processing...</span>
              </button>
              <button type="button" className="EPPR_A4_5">
                <span>Processing...</span>
              </button>
            </div>
            )}
          { (!isUploadingViaApi && !curriculum.isCoursePriceSaved)
            && (
            <div className="EPPR_A4">
              <button type="button" className="EPPR_A4_1" onClick={() => handleCancel()}>
                <span>Cancel</span>
              </button>
              <button type="button" className="EPPR_A4_2" onClick={() => handleSave()}>
                <span>Save</span>
              </button>
            </div>
            )}
          { (!isUploadingViaApi && curriculum.isCoursePriceSaved)
            && (
            <div className="EPPR_A4">
              <button type="button" className="EPPR_A4_3" onClick={() => handleCancel()}>
                <span>Edit</span>
              </button>
              <button type="button" className="EPPR_A4_4">
                <span>PriceAdded</span>
              </button>
            </div>
            )}
        </div>
        )}
    </div>
  );
}

export default EPPricing;
