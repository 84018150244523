import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import PayuPostForm from '../../../../PayU/PayuPostForm';
//  import { PaidCourses } from '../../../../../container';
//  import FetchJwtToken from '../../../../BackendServices/FetchJwtToken';
import './PayForTier1.css';
import Navbar from '../../../../Navbar/Navbar';
import FetchGroups from '../../../../BackendServices/FetchGroups';

const PayForTier1 = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  //  let userCheckGroups = {};
  const [groups, setGroups] = useState({});

  useEffect(() => {
    (async () => {
      const userCheckGroups = await FetchGroups(true); //  updated tokens and groups in local caches
      console.debug('setting isLoading=false');
      setIsloading(false);
      setGroups(userCheckGroups);
    })();
  }, []);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  if (groups.isUsertierone === true) {
    console.debug('navigating to videoCard');
    navigate('/videoCard');
  } else {
    console.debug('not navigating to videoCard');
  }

  /*
    User not loged in: (groups.tokens.username === '')
    User logged in but has not yet paid: (groups.tokens.username !== '') && (groups.isUsertierone === false)
    User logged in and has also paid: (groups.tokens.username !== '') && (groups.isUsertierone === true)
  */
  let toPay = false;
  let msg = '';
  if (groups.tokens.username === '') {
    msg = 'Login to the site please';
  } else if (!groups.isUsertierone) {
    toPay = true;
    msg = 'You are required to buy Tier1 membership';
  } else {
    msg = 'User is loged in and has bought Tier1 membership'; // not required actually
  }
  console.debug('toPay:', toPay, '  msg: ', msg);

  return (
    <div>
      <Navbar />
      <div className="payForTier1__zero">
        <div className="payForTier1__one">
          <h4>{msg}</h4>
          { toPay && <PayuPostForm />}
          <div onClick={() => window.location.reload(true)} style={{ marginLeft: '5px' }}>
            <FontAwesomeIcon icon={faRefresh} size="2x" fontSize={27} color="purple" />
          </div>
        </div>
        <div className="payForTier1__two">
          <h4>Watch this space for Special Offers.</h4>
        </div>
      </div>
      { /* <PaidCourses /> */ }
    </div>
  );
};

export default PayForTier1;
