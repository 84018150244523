/*  eslint-disable no-unused-vars */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './EPDoneEnrollTable.css';

function EPDoneEnrollTable(props) {
  const { isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes, enrolledCoursesDoneArray } = props;
  console.log('EPDoneEnrollTable', props);

  function handleActions() {
    console.log('handle actions');
  }

  return (
    <div className="EPDETBL">
      {
        enrolledCoursesDoneArray.map((courseItem) => {
          const { IsCourseDone, SavedDate, EnrolledCourse } = courseItem;
          const { courseAttributes, doneStatus, userCursor } = EnrolledCourse;
          const { courseCurrency, coursePrice, courseName, instituteName } = courseAttributes;
          const { lesson, section } = userCursor;
          return (
            <tr style={{ display: 'flex' }}>
              <td>{courseName}</td>
              <td>{coursePrice}{courseCurrency}</td>
              <td>{SavedDate}</td>
              <td>{IsCourseDone ? 'Completed' : 'InProgress'}</td>
              <td>{section}.{lesson}</td>
              <td>{instituteName}</td>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td className="handleClick" onClick={handleActions}><FontAwesomeIcon icon={faBars} /></td>
            </tr>
          );
        })
      }
    </div>
  );
}

export default EPDoneEnrollTable;
/*  eslint-enable no-unused-vars */
