/*  eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import EPCertificatesEnrollTable from './EPCertificatesEnrollTable';
import './EPCertificatesEnroll.css';
import CourseCurriculum from '../../../../../../../../../components/BackendServices/CourseCurriculum';

/*
  useEffect: api call to fetch courses and display
  coursename and institute might be only relevant props here
 */

function EPCertificatesEnroll(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes } = props;
  const [certificatesArray, setCertificatesArray] = useState([]);

  useEffect(() => {
    (async () => {
      setIsUploadingViaApi(true);
      const resp = await CourseCurriculum({ action: 'ENROLLMENTS_GET_CERTIFICATES' });
      setCertificatesArray(resp.data.backendResponse.Items);
      setIsUploadingViaApi(false);
      console.log('resp: ', resp.data.backendResponse);
      console.log('count: ', resp.data.backendResponse.Count);
      console.log('Items: ', resp.data.backendResponse.Items);
      //  console.log('Items0: ', resp.data.backendResponse.Items[0].ID);
    })();
  }, []);

  return (
    <div className="EPPCO">
      Certificates awarded for successfully finished courses.
      <div className="EPPCO_1">
        <table className="EPPCO_A1">
          <thead>
            <tr>
              {/*
              <th className="saved_date_header">Saved Date</th>
              <th className="last_action_header">Last Action</th>
              <th className="type_header">Type</th>
              <th className="id_header">ID</th>
              */}
              <th className="type_header">Name</th>
              <th className="type_header">Price</th>
              <th className="type_header">Institute</th>
              <th className="more_header">More</th>
            </tr>
          </thead>
          <tbody>
            {isUploadingViaApi && <div>Loading..</div>}
            {!isUploadingViaApi && (certificatesArray.length === 0) && <div>Empty List</div>}
            {!isUploadingViaApi && (certificatesArray.length !== 0) && <EPCertificatesEnrollTable certificatesArray={certificatesArray} />}
            <div style={{ height: '100px' }} /> { /* filler to keep Dropdown visible */ }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EPCertificatesEnroll;
/*  eslint-enable no-unused-vars */
