/*  eslint-disable no-unused-vars */
import React from 'react';
import './MarketingToolsList.css';
import MarketingTool1List from './MarketingTool1List';
import MarketingTool2List from './MarketingTool2List';

function MarketingToolsList({ curriculum, setCurriculum, clCardItem, setClCardItem, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  return (
    <div className="MTLST">
      <div className="MTLST_Header">
        Marketing Tools
      </div>
      <div className="MTLST_Body">
        <div className="MTLST_Body_A1">
          <MarketingTool1List />
        </div>
        <div className="MTLST_Body_A1">
          <MarketingTool2List />
        </div>
      </div>
    </div>
  );
}

export default MarketingToolsList;
/*  eslint-enable no-unused-vars */
