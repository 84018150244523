/*  eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './EdTechPage.css';
import AddSections from './AddSections';
import CreateLesson from './CreateLesson/CreateLesson';
import EPCertificates from './EdTechPages/components/EPCertificates';
import EPComments from './EdTechPages/components/EPComments';
import EPCoupons from './EdTechPages/components/EPCoupons';
import EPDesignTemplates from './EdTechPages/components/EPDesignTemplates';
import EPHome from './EdTechPages/components/EPHome';
import EPInformation from './EdTechPages/components/EPInformation';
import EPOrderBumps from './EdTechPages/components/EPOrderBumps';
import EPPricing from './EdTechPages/components/EPPricing';
import EPSalesPages from './EdTechPages/components/EPSalesPages';
import EPAbout from './EdTechPages/components/EPAbout';
import EPFaq from './EdTechPages/components/EPFaq';
import EPContactFacebook from './EdTechPages/components/EPContactFacebook';
import EPContactTwitter from './EdTechPages/components/EPContactTwitter';
import EPContactInstagram from './EdTechPages/components/EPContactInstagram';
import EPStudents from './EdTechPages/components/EPStudents';
import EPReports from './EdTechPages/components/EPReports';
import CoursePublishState from './CurriculumStates/CoursePublishState';
import EPPublish from './EdTechPages/components/EPPublish';
import EPPublishedCourses from './EdTechPages/components/EPPublishedCourses';
import EPInstitute from './EdTechPages/components/EPInstitute';
import EPSupport from './EdTechPages/components/EPSupport';
import EPReportBug from './EdTechPages/components/EPReportBug';
import EPInProgress from './EdTechPages/components/Enrollment/EPInProgress';
import EPDoneEnroll from './EdTechPages/components/Enrollment/EPDoneEnroll';
import EPCertificatesEnroll from './EdTechPages/components/Enrollment/EPCertificatesEnroll';
import EPClassroomEnroll from './EdTechPages/components/Enrollment/Classroom/EPClassroomEnroll';
import EPClassroomCurriculum from './EdTechPages/components/Enrollment/Classroom/EPClassroomCurriculum';
import EPClassroomInstructor from './EdTechPages/components/Enrollment/Classroom/EPClassroomInstructor';
import EPGenAIEdtech from './EdTechPages/components/EPGenAIEdtech';

const Comps = {
  certificates: EPCertificates,
  comments: EPComments,
  coupons: EPCoupons,
  designTemplates: EPDesignTemplates,
  home: EPHome,
  information: EPInformation,
  orderBumps: EPOrderBumps,
  pricing: EPPricing,
  salesPages: EPSalesPages,
  about: EPAbout,
  contactFacebook: EPContactFacebook,
  contactTwitter: EPContactTwitter,
  contactInstagram: EPContactInstagram,
  faq: EPFaq,
  students: EPStudents,
  reports: EPReports,
  createLesson: CreateLesson,
  publish: EPPublish,
  edTech: AddSections,
  publishedCourses: EPPublishedCourses,
  institute: EPInstitute,
  support: EPSupport,
  reportbug: EPReportBug,
  inprogressEnroll: EPInProgress,
  doneEnroll: EPDoneEnroll,
  certificatesEnroll: EPCertificatesEnroll,
  classroomEnroll: EPClassroomEnroll,
  classroomInstructor: EPClassroomInstructor,
  classroomCurriculum: EPClassroomCurriculum,
  genaiLearning: EPGenAIEdtech,
};

function EdTechPage(props) {
  const { page, setPage, curriculum, setCurriculum, initialCurriculum, selectedCatalogueIndex, setSelectedCatalogueIndex, isUploadingViaApi, setIsUploadingViaApi, enrolledAttributes, setEnrolledAttributes, enrolledCurriculum, setEnrolledCurriculum, enrolledCoursesDoneArray, setEnrolledCoursesDoneArray, enrolledCoursesProgressingArray, setEnrolledCoursesProgressingArray } = props;
  const [s1Done, setS1Done] = useState(false);
  const [s2Done, setS2Done] = useState(false);
  const [s3Done, setS3Done] = useState(false);
  const [s4Done, setS4Done] = useState(false);
  const [lessonParent, setLessonParent] = useState({});

  if (
    (page === 'edTech')
    || (page === 'information')
    || (page === 'pricing')
    || (page === 'publish')
    || (page === 'institute')
    || (page === 'createLesson')
    || (page === 'publishedCourses')) {
    return (
      <div>
        <div>
          {React.createElement(Comps[page], {
            page,
            setPage,
            setS1Done,
            setS2Done,
            setS3Done,
            setS4Done,
            curriculum,
            setCurriculum,
            initialCurriculum, // used in EPPublishedCoursesTable2.js
            selectedCatalogueIndex, // used in EPPublishedCoursesTable2.js
            setSelectedCatalogueIndex, // used in EPPublishedCoursesTable2.js
            //  lessonParent set in AddLessons.js (handleLessonCreate()) and used in CreateLesson.js.
            lessonParent,
            setLessonParent,
            isUploadingViaApi,
            setIsUploadingViaApi,
          })}
        </div>
        <div className="flowBar">
          <CoursePublishState s1Done={s1Done} s2Done={s2Done} s3Done={s3Done} s4Done={s4Done} />
        </div>
      </div>
    );
  }

  if ((page === 'inprogressEnroll')
    || (page === 'doneEnroll')
    || (page === 'certificatesEnroll')
    || (page === 'classroomEnroll')
    || (page === 'classroomInstructor')
    || (page === 'classroomCurriculum')) {
    return (
      <div>
        <div>
          {React.createElement(Comps[page], {
            page,
            setPage,
            isUploadingViaApi,
            setIsUploadingViaApi,
            enrolledCurriculum,
            setEnrolledCurriculum,
            enrolledAttributes,
            setEnrolledAttributes,
            enrolledCoursesDoneArray,
            setEnrolledCoursesDoneArray,
            enrolledCoursesProgressingArray,
            setEnrolledCoursesProgressingArray,
          })}
        </div>
      </div>
    );
  }

  if ((page in Comps) && (typeof Comps[page] !== 'undefined')) {
    return React.createElement(Comps[page], {});
  }

  return React.createElement(
    () => <div>The component {page} has not been created yet.</div>,
    {},
  );
}

export default EdTechPage;
/*  eslint-enable no-unused-vars */
