/*  eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import VideoListItem from './VideoListItem';
import './VideoList.css';

function VideoList({ curriculum, setCurriculum, clCardItem, setClCardItem, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  console.log('VideoList', lessonParent, curriculum);
  const sectionIndex = lessonParent.SectionIndex;
  const lessonIndex = lessonParent.LessonIndex;
  const data = curriculum.sectionList[sectionIndex].lessonList[lessonIndex].assets.video;

  if (data.length === 0) {
    return (
      <div className="VLMOD_1">
        <h1>Asset List: Videos</h1>
        <div>
          No items
        </div>
      </div>
    );
  }

  return (
    <div className="VLMOD_1">
      <h1>Asset List: Videos</h1>
      <ul>
        { data.map((item) => <VideoListItem name={item.videoName} id={item.videoID} />) }
      </ul>
    </div>
  );
}

export default VideoList;
/*  eslint-enable no-unused-vars */
