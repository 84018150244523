import React from 'react';
import YetToBeImplemented from './YetToBeImplemented/YetToBeImplemented';

function MarketingTool2Modal() {
  return (
    <div>
      <YetToBeImplemented />
    </div>
  );
}

export default MarketingTool2Modal;
