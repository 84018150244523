/*  eslint-disable no-unused-vars */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { images } from '../../../../../../../../../constants';
import './EPCertificatesEnrollTable.css';

function EPCertificatesEnrollTable(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes, certificatesArray } = props;
  /*
    useEffect: COurseCurriculum api to fetch user certificates
  */
  function handleActions() {
    console.log('handle actions');
  }

  return (
    <div className="EPPCTBL">
      {
        certificatesArray.map((courseItem) => {
          const { thumbnail, name, author, creation, sales, enrollments, status, actions } = courseItem;
          console.log(thumbnail, actions);
          return (
            <tr style={{ display: 'flex' }}>
              <td className="handleClick"> <img src={images[thumbnail]} alt="header_img" /> </td>
              <td>{name}</td>
              <td>{author}</td>
              <td>{creation}</td>
              <td>{sales}</td>
              <td>{enrollments}</td>
              <td>{status}</td>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td className="handleClick" onClick={handleActions}><FontAwesomeIcon icon={faBars} /></td>
            </tr>
          );
        })
      }
    </div>
  );
}

export default EPCertificatesEnrollTable;
/*  eslint-enable no-unused-vars */
