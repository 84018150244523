import React, { useState } from 'react';
import './CEdit.css';
import Modal from '../Modal';
import CEditModal from './CEditModal';

function CEdit() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="CED" onClick={() => setIsOpen(true)}>
        <div className="CED_A2">
          Edit
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <CEditModal />
      </Modal>
    </div>
  );
}

export default CEdit;
