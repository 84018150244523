import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  //  createRoutesFromElements,
  RouterProvider,
  //  Route,
} from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import Talks from './components/pages/talks/Talks';
import TalkOne from './components/pages/talks/talk_one/TalkOne';

import './index.css';
import App from './App';
import TalkTwo from './components/pages/talks/talk_two/TalkTwo';
import Login from './components/Login/Login';
import Login2 from './components/Login/Login2';
import MoveUser from './components/pages/move_user/MoveUser';
import { Surl, Furl, AboutUs, EdTech, EdTechPitchDeck1, WorkshopsHeader, AwsHeader, Newsletter, ProductsList, Careers, ReachUsForm, FindUs, CreateLesson } from './container';
import ProductsList2 from './container/Header/ProductsHeader/Prods/Older/ProductsList2';
import PayuPostForm from './components/PayU/PayuPostForm';
import PayuPostForm1 from './components/PayU/PayuPostForm1';
import PayForTier1 from './components/pages/talks/talk_two/PayForTier1/PayForTier1';
import { VideoCard } from './components';
import PrivacyPolicy from './container/PrivacyPolicy/PrivacyPolicy';
import RefundAndCancellationPolicy from './container/PrivacyPolicy/RefundAndCancellationPolicy';
import TermsAndConditions from './container/PrivacyPolicy/TermsAndConditions';
import PayUMoneyForm from './components/PayU/PayUMoneyForm';

const router = createBrowserRouter([
  { path: '/', element: (<App />) },
  { path: 'aboutUs', element: (<AboutUs />) },
  { path: 'talks', element: (<Talks />) },
  { path: 'videoCard', element: (<VideoCard />) },
  { path: 'talkOne', element: (<TalkOne accessToken />) },
  { path: 'talkTwo', element: (<TalkTwo />) },
  { path: 'payForTier1', element: (<PayForTier1 />) },
  { path: 'login', element: (<Login />) },
  { path: 'login2', element: (<Login2 />) },
  { path: 'moveuser', element: (<MoveUser />) },
  { path: 'surl', element: (<Surl />) },
  { path: 'furl', element: (<Furl />) },
  { path: 'payuPostForm', element: (<PayuPostForm />) },
  { path: 'payuPostForm1', element: (<PayuPostForm1 />) },
  { path: 'workshops', element: (<WorkshopsHeader />) },
  { path: 'awsHeader', element: (<AwsHeader />) },
  { path: 'newsletter', element: (<Newsletter />) },
  { path: 'productsList', element: (<ProductsList />) },
  { path: 'productsList2', element: (<ProductsList2 />) },
  { path: 'careers', element: (<Careers />) },
  { path: 'reachUsForm', element: (<ReachUsForm />) },
  { path: 'privacyPolicy', element: (<PrivacyPolicy />) },
  { path: 'cancellationPolicy', element: (<RefundAndCancellationPolicy />) },
  { path: 'termsAndConditions', element: (<TermsAndConditions />) },
  { path: 'customerSupport', element: (<FindUs />) },
  { path: 'edTech', element: (<EdTech />) },
  { path: 'createLesson', element: (<CreateLesson />) },
  { path: 'edTechPitchDeck', element: (<EdTechPitchDeck1 />) },
  { path: 'payumoneyform', element: (<PayUMoneyForm />) },
]);

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
