import React, { forwardRef } from 'react';
//  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//  import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './DropdownButton.css';

const DropdownButton = forwardRef((props, ref) => {
  const { children, toggle, open } = props;

  return (
    <div
      onClick={toggle}
      className={`dropdown-btn ${open ? 'button-open' : null}`}
      ref={ref}
    >
      {children}
    </div>
  );
});

export default DropdownButton;
