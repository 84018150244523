/*  eslint-disable no-unused-vars */
import React from 'react';

function EPClassroomInstructor(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes } = props;
  return (
    <div>EPClassroomInstructor</div>
  );
}

export default EPClassroomInstructor;
/*  eslint-enable no-unused-vars */
