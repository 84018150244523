import React from 'react';
import './TextAndImageListItem.css';

function TextAndImageListItem({ name, id }) {
  console.log('VideoListItem', name, id);
  return (
    <div className="RTLI_1">
      <div className="RTLI_2">
        RichTextListItem
      </div>
      <div className="RTLI_3">
        {name}
      </div>
      <div className="RTLI_3">
        {id}
      </div>
    </div>
  );
}

export default TextAndImageListItem;
