import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TextAndImages.css';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modals/Modal';
import PDFViewerModal from './Modals/PDFViewer/PDFViewerModal';

function PDFViewer({ curriculum, setCurriculum, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="TI" onClick={() => setIsOpen(true)}>
        <div className="TI_A1">
          <FontAwesomeIcon icon={faNewspaper} />
        </div>
        <div className="TI_A2">
          PDF Viewer
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <PDFViewerModal curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
      </Modal>
    </div>
  );
}

export default PDFViewer;
