import React from 'react';
import TextAndImages from './TextAndImages';
import './AddContentGrid.css';
import VideoContent from './VideoContent';
import PDFViewer from './PDFViewer';
import AudioContent from './AudioContent';
import BannerImage from './BannerImage';
import Resource from './Resource';
import CodeExample from './CodeExample';

function AddContentGrid({ curriculum, setCurriculum, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  console.log('AddContentGrid curriculum: ', curriculum);
  return (
    <div className="ACG">
      <div className="ACG_Header">
        Content
      </div>
      <div className="ACG_Body">
        <div className="ACG_Body_A1">
          <TextAndImages curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <VideoContent curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <PDFViewer curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <AudioContent curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <BannerImage curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <Resource curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <CodeExample curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
      </div>
    </div>
  );
}

export default AddContentGrid;
