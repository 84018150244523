/*  eslint-disable padded-blocks, prefer-destructuring, no-unused-vars */
import axios from 'axios';
import FetchGroups from './FetchGroups';

//  eslint-disable-next-line no-unused-vars
const GetInstitute = async ({ action }) => {
  console.log('GetInstitute', action);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { action }, //  query params
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/';
  let apiResponse = '';
  apiResponse = await axios.get(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.get() response: ', apiResponse);
  return apiResponse;
};

//  eslint-disable-next-line no-unused-vars
const GetAllCourseCurriculum = async ({ action }) => {
  console.log('GetAllCourseCurriculum', action);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { action },
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/coursecurriculum';
  let apiResponse = '';
  apiResponse = await axios.get(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.get() response: ', apiResponse);
  return apiResponse;
};

//  eslint-disable-next-line no-unused-vars
const GetEnteredClassroom = async ({ action }) => {
  console.log('GetCourseCurriculum', action);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { action, userTokens: JSON.stringify({ useremail: toks.tokens.useremail, username: toks.tokens.username }) }, //  query params
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/coursecurriculum';
  let apiResponse = '';
  apiResponse = await axios.get(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.get() response: ', apiResponse);
  return apiResponse;
};

//  eslint-disable-next-line no-unused-vars
const GetCourseCurriculum = async ({ courseID, action }) => {
  console.log('GetCourseCurriculum', courseID, action);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { courseID, action, userTokens: JSON.stringify({ useremail: toks.tokens.useremail, username: toks.tokens.username }) }, //  query params
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/coursecurriculum';
  let apiResponse = '';
  apiResponse = await axios.get(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.get() response: ', apiResponse);
  return apiResponse;
};

//  eslint-disable-next-line no-unused-vars
const GetCourse = async ({ curriculum, action }) => {
  console.log('GetCourse: ', curriculum);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { action, curriculum: JSON.stringify(curriculum) }, //  query params
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/course';
  let apiResponse = '';
  apiResponse = await axios.get(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.get() response: ', apiResponse);
  return apiResponse;
};

const CourseUpdate = async ({ curriculum, sectionIndex, lessonIndex, lessonParent, action }) => {
  console.log('CourseUpdate: ', curriculum, sectionIndex, lessonIndex, lessonParent, action);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
  };

  const data = { curriculum, sectionIndex, lessonIndex, lessonParent, action, username: toks.tokens.username }; // JSON.stringify(toks.tokens.username) adds double quotes as part of username which is wrong, use without stringify.

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/course/';
  let apiResponse = '';
  apiResponse = await axios.put(baseURL, data, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('SetCourse response: ', apiResponse);
  return apiResponse;
};

const WithdrawEnrollment = async ({ courseID, action }) => {
  console.log('WithdrawEnrollment', action, courseID);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { courseID, action, userTokens: JSON.stringify({ useremail: toks.tokens.useremail, username: toks.tokens.username }) }, //  query params
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/enrollment';
  let apiResponse = '';
  apiResponse = await axios.delete(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.post() response: ', apiResponse);
  return apiResponse;
};

const EnrollCourse = async ({ enrolledCourse, action }) => {
  console.log('EnrollCourse', action, enrolledCourse);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { userTokens: JSON.stringify({ useremail: toks.tokens.useremail, username: toks.tokens.username }) }, //  query params
  };

  const data = { enrolledCourse, action };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/enrollment';
  let apiResponse = '';
  apiResponse = await axios.post(baseURL, data, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.post() response: ', apiResponse);
  return apiResponse;
};

//  eslint-disable-next-line no-unused-vars
const GetCurriculum = async ({ courseID, action }) => {
  console.log('GetCurriculum: ', courseID);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { action, courseID }, //  query params
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/course';
  let apiResponse = '';
  apiResponse = await axios.get(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.get() response: ', apiResponse);
  return apiResponse;
};

const EnrolledCourseUpdate = async ({ enrolledCourse, action }) => {
  console.log('GetCourseEnrollemnt', enrolledCourse, action);
  const toks = await FetchGroups(true);
  const username = toks.tokens.username;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    params: { action, username },
  };

  const data = { enrolledCourse };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/enrollment/user';
  let apiResponse = '';
  apiResponse = await axios.put(baseURL, data, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.get() response: ', apiResponse);
  return apiResponse;
};

const GetCourseEnrollemnt = async ({ courseID = '', action }) => {
  console.log('GetCourseEnrollemnt', courseID, action);
  const toks = await FetchGroups(true);
  const useremail = toks.tokens.useremail;
  const username = toks.tokens.username;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    params: { action, username, courseID },
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/enrollment/user';
  let apiResponse = '';
  apiResponse = await axios.get(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.get() response: ', apiResponse);
  return apiResponse;
};

const GetEnrollemnts = async ({ action }) => {
  console.log('GetEnrollemnts', action);
  const toks = await FetchGroups(true);
  const useremail = toks.tokens.useremail;
  const username = toks.tokens.username;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    params: { action, useremail, username },
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/enrollment/user';
  let apiResponse = '';
  apiResponse = await axios.get(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.get() response: ', apiResponse);
  return apiResponse;
};

const PostLesson = async ({ lessonParent }) => {
  console.log('PostLesson: ', lessonParent);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { 'abc': 'key' }, //  query params
  };

  const data = { lessonParent };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/course/section/lesson';
  let apiResponse = '';
  apiResponse = await axios.post(baseURL, data, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.post() response: ', apiResponse);
  return apiResponse;
};

const DeleteLesson = async ({ lessonIndex }) => {
  console.log('DeleteLesson: ', lessonIndex);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { lessonIndex }, //  query params
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/course/section/lesson';
  let apiResponse = '';
  apiResponse = await axios.delete(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.post() response: ', apiResponse);
  return apiResponse;
};

const PostVideoAsset = async ({ assetFilename, lessonParent, action }) => {
  console.log('PostVideoAsset: ', assetFilename, lessonParent);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { 'abc': 'xyz' },
  };

  const data = { assetFilename, lessonParent, action };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/course/section/lesson/content';
  let apiResponse = '';
  apiResponse = await axios.post(baseURL, data, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.post() response: ', apiResponse);
  return apiResponse;
};

/*
  ******************************
  ******************************
*/

const CourseCurriculum = async (props) => {
  console.log('CourseCurriculum:', props);
  const { curriculum, lessonParent, sectionIndex, lessonIndex, action, assetFilename, enrolledCourse, courseID } = props;
  let apiResp1 = '';

  if (action === 'GET_INSTITUTE') {
    apiResp1 = await GetInstitute({ action });

  } else if (action === 'GET_ALL_COURSE_CURRICULUMS') {
    apiResp1 = await GetAllCourseCurriculum({ action });

  } else if (action === 'GET_LAST_EDITED_COURSE') {
    apiResp1 = await GetCourseCurriculum({ action });

  } else if (action === 'ENTER_CLASSROOM') { // should be POST
    apiResp1 = await GetCourseCurriculum({ courseID, action });

  } else if (action === 'GET_ENTERED_CLASSROOM') { // should be POST
    apiResp1 = await GetEnteredClassroom({ action });

  } else if (action === 'LEAVE_CLASSROOM') { // should be DELETE
    apiResp1 = await GetCourseCurriculum({ courseID, action });

  } else if (action === 'GET_CURRICULUM') {
    apiResp1 = await GetCurriculum({ courseID, action });
    console.log('GetCourseByID response:', apiResp1);
  } else if (action === 'GET_SECTION') {
    apiResp1 = await GetCourse({ curriculum, action });
    console.log('GetCourse response:', apiResp1);

  } else if ((action === 'POST_VIDEO_ASSET')
    || (action === 'POST_RICHTEXT_ASSET')
    || (action === 'POST_PDF_ASSET')
    || (action === 'POST_AUDIO_ASSET')) {
    console.log('object (video, audio, richtext, pdf) upload from s3 and dynamodb');
    apiResp1 = await PostVideoAsset({ assetFilename, lessonParent, action });
  } else if (action === 'DELETE_ASSET') {
    console.log('object (video, audio, richtext, pdf) delete from s3 and dynamodb');

  } else if ((action === 'SET_INSTITUTE_NAME')
          || (action === 'RESET_INSTITUTE_NAME')
          || (action === 'SET_COURSE_NAME')
          || (action === 'RESET_COURSE_NAME')
          || (action === 'SET_COURSE_PRICE')
          || (action === 'RESET_COURSE_PRICE')
          || (action === 'SET_COURSE_PUBLISH')
          || (action === 'RESET_COURSE_PUBLISH')
          || (action === 'SET_SECTION_NAME')
          || (action === 'RESET_SECTION_NAME')
          || (action === 'DELETE_SECTION') // section is removed along with asets. warn the user first
          || (action === 'SET_SECTION_PUBLISH')
          || (action === 'RESET_SECTION_PUBLISH')
          || (action === 'SET_LESSON_NAME') // only rename
          || (action === 'RESET_LESSON_NAME')
          || (action === 'SET_LESSON_PUBLISH') // use it somewhere
          || (action === 'RESET_LESSON_PUBLISH') // use it somewhere
          || (action === 'SET_CURRICULUM')) {
    apiResp1 = await CourseUpdate({ action, curriculum, sectionIndex, lessonIndex, lessonParent });
    console.log('api response:', apiResp1);

  } else if (action === 'ENROLLMENT') {
    apiResp1 = await EnrollCourse({ action, enrolledCourse });
  } else if (action === 'WITHDRAW_ENROLLMENT') {
    apiResp1 = await WithdrawEnrollment({ action, courseID });
  } else if (action === 'ENROLLMENT_GET_ATTRIBUTES') {
    apiResp1 = await GetCourseEnrollemnt({ courseID, action });
  } else if (action === 'ENROLLED_COURSE_UPDATE') {
    apiResp1 = await EnrolledCourseUpdate({ enrolledCourse, action });
  } else if ((action === 'ENROLLMENT_GET_COURSES')
    || (action === 'ENROLLMENTS_GET_CERTIFICATES')) {
    apiResp1 = await GetEnrollemnts({ action });

  } else if (action === 'JWT_ACCESS_TOKEN_GET') {
    const toks = await FetchGroups(true);
    apiResp1 = { status: 200, data: toks };

  } else {
    console.log('Unknown action: ', action, props);
  }
  return apiResp1;
};

export default CourseCurriculum;
/*  eslint-enable padded-blocks, prefer-destructuring, no-unused-vars */

/* Delete following later

const PostCourse = async ({ curriculum, action }) => {
  console.log('PostCourse: ', curriculum);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { action }, //  query params
  };

  const data = { curriculum };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/course';
  let apiResponse = '';
  apiResponse = await axios.post(baseURL, data, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.post() response: ', apiResponse);
  return apiResponse;
};

const PutCourse = async ({ curriculum, action }) => {
  console.log('PutCourse: ', curriculum);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { action }, //  query params
  };

  const data = { curriculum };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/course';
  let apiResponse = '';
  apiResponse = await axios.put(baseURL, data, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.post() response: ', apiResponse);
  return apiResponse;
};

const DeleteCourse = async ({ curriculum, action }) => {
  console.log('DeleteCourse: ', curriculum);
  const toks = await FetchGroups(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: toks.tokens.jwtIdToken,
    },
    //  eslint-disable-next-line quote-props
    params: { action, curriculum: JSON.stringify(curriculum) }, //  query params
  };

  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/institute/course';
  let apiResponse = '';
  apiResponse = await axios.delete(baseURL, config)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  console.log('axios.post() response: ', apiResponse);
  return apiResponse;
};
*/
