import React from 'react';
import './VideoListItem.css';

function VideoListItem({ name, id }) {
  console.log('VideoListItem', name);
  return (
    <div className="VDLI_1">
      <div className="VDLI_2">
        VideoListItem
      </div>
      <div className="VDLI_3">
        {name}
      </div>
      <div className="VDLI_3">
        {id}
      </div>
    </div>
  );
}

export default VideoListItem;
