import bg from '../assets/bg.png';
import logo100px from '../assets/Logo_100px_noFill_noStroke.png';
import visionpro1 from '../assets/visionpro1.png';
import visionpro2 from '../assets/visionpro2.png';
import visionpro3 from '../assets/visionpro3.png';
import awsMobileBackend from '../assets/awsMobileBackend.png';
import xcode from '../assets/xcode.png';
import genAI from '../assets/genAI.png';
import swiftUI from '../assets/swiftUI.png';
import awsServerless from '../assets/awsServerless.png';
import workshop from '../assets/workshop.png';
import contactUs1 from '../assets/contactUs1.png';
import AO from '../assets/AO.png';
import A0 from '../assets/A0.png';
import A1 from '../assets/A1.png';
import A2 from '../assets/A2.png';
import A3 from '../assets/A3.png';
import A4 from '../assets/A4.png';
import A5 from '../assets/A5.png';
import A6 from '../assets/A6.png';
import A7 from '../assets/A7.png';
import A8 from '../assets/A8.png';
import A9 from '../assets/A9.png';
import CurvSortOliveText from '../assets/CurvSortOliveTxt.001.png';
import CurvSortNavyText from '../assets/CurvSortNavyTxt.001.png';
import Logo1 from '../assets/Logo.001.png';
import Boxes from '../assets/Boxes.001.png';
import Products from '../assets/Products.001.png';
import Products2 from '../assets/Products.png';
import AArt1 from '../assets/AArt1.png';
import AArt2 from '../assets/AArt2.png';
import AArt3 from '../assets/AArt3.png';
import AArt4 from '../assets/AArt4.png';
import AArt5 from '../assets/AArt5.png';
import AArt6 from '../assets/AArt6.png';
import Banner from '../assets/banner.png';
import CookingClass from '../assets/cookingclass.png';
import ChristmasTree from '../assets/christmastree.png';
import Origami from '../assets/origami.png';
import EdTechPitchDeck from '../assets/CurvSort_EdTech.pdf';

export default {
  bg,
  logo100px,
  visionpro1,
  visionpro2,
  visionpro3,
  awsMobileBackend,
  xcode,
  genAI,
  swiftUI,
  awsServerless,
  workshop,
  contactUs1,
  AO,
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,
  CurvSortOliveText,
  CurvSortNavyText,
  Logo1,
  Boxes,
  Products,
  Products2,
  AArt1,
  AArt2,
  AArt3,
  AArt4,
  AArt5,
  AArt6,
  Banner,
  CookingClass,
  ChristmasTree,
  Origami,
  EdTechPitchDeck,
};
