import React, { useState } from 'react';
import './CPreview.css';
import Modal from '../Modal';
import CPreviewModal from './CPreviewModal';

function CPreview() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="CPR" onClick={() => setIsOpen(true)}>
        <div className="CPR_A2">
          Preview
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <CPreviewModal />
      </Modal>
    </div>
  );
}

export default CPreview;
