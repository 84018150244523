import FetchGroups from './FetchGroups';

const FetchGroups2 = async (setAmplifyUser, setGroups, setIsloading, fresh = false) => {
  console.debug('FetchGroups2');
  const grps = await FetchGroups(fresh); //  fresh fetch is only reqd after payment. SignIn, SignUp should still be okay with cached response of FetchGroups().
  if (grps.tokens.jwtAccessToken === '') {
    setAmplifyUser('Login'); // causes component update
  } else {
    setAmplifyUser(grps.tokens.useremail.split('@')[0]);
    //  setUser(userCheckGroups.tokens.useremail.split('@')[0]);
  }
  setGroups(grps); // not set immediately and access to groups.tokens.jwtAccessToken can crash saying not defined
  setIsloading(false); // only when this is done, rerender will happen, and main return will be executed. That time groups.tokens.jwtAccessToken will be accessible.
  //    console.log('groups:', groups, ', set to grps:', grps);
};

export default FetchGroups2;
