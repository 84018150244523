import React from 'react';
import './PDFViewerListItem.css';

function PDFViewerListItem({ name }) {
  console.log('PDFViewerListItem', name);
  return (
    <div className="RTLI_1">
      <div className="RTLI_2">
        PDFViewerListItem
      </div>
      <div className="RTLI_3">
        {name}
      </div>
    </div>
  );
}

export default PDFViewerListItem;
