import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
//  import 'react-pdf/dist/esm/Page/TextLayer.css';
//  import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './PDFViewer.css';
import S3Upload from '../../../../../../../../../../../../components/BackendServices/S3Upload';
import CourseCurriculum from '../../../../../../../../../../../../components/BackendServices/CourseCurriculum';
//  https://www.youtube.com/watch?v=fbrrcOLKuTM
//  https://github.com/HamzaAnwar1998/Upload-View-Pdf-In-Reactjs

function PDFViewer({ curriculum, setCurriculum, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  console.log(curriculum, setCurriculum);
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState();
  /* eslint-disable-next-line no-unused-vars */
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfData, setPdfData] = useState(null);
  //  eslint-disable-next-line no-unused-vars
  const [uploadProgress, setUploadProgress] = useState(0);
  const [resetFileInput, setResetFileInput] = useState(Math.random().toString(36));
  console.log(selectedFile);

  const onFileLoad = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setPdfData(e.target.result);
    };

    reader.readAsDataURL(file);
    setSelectedFile(file);
  };

  /* eslint-disable-next-line no-shadow */
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  async function uploadPdfFile(e) {
    console.log('upload pdf file/data', selectedFile, e);
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('api_key', 'abcd1234');
    setIsUploadingViaApi(true);
    const assetFilename = selectedFile.name;
    const contentType = 'application/pdf';
    const response = await S3Upload(`pdf/${assetFilename}`, formData, contentType, setUploadProgress);
    console.log('S3Upload response:', response);
    setIsUploadingViaApi(false);

    const sectionIndex = lessonParent.SectionIndex;
    const lessonIndex = lessonParent.LessonIndex;

    // update curriculum with Pdf filename
    const updatedLessonList = curriculum.sectionList[sectionIndex].lessonList;
    updatedLessonList[lessonIndex].assets.pdf.push({
      pdfName: assetFilename,
      pdfID: '',
    });
    const updatedSectionList = curriculum.sectionList;
    updatedSectionList.lessonList = updatedLessonList;
    const updatedCurriculum = { ...curriculum, ...{ sectionList: updatedSectionList } };
    console.log('updatedCurriculum: ', updatedCurriculum);
    const response1 = await CourseCurriculum({ curriculum: updatedCurriculum, assetFilename, lessonParent, action: 'POST_PDF_ASSET' });
    if (response1.status !== 200) {
      console.log('error while calling api, handle error.');
    }
    console.log('CourseCurriculum POST_PDF_ASSET: ', response1);

    // update curriculum with assetID returned from api response
    const pdfID = response1.data.assetID.slice('ASSET_ID#'.length);
    const updatedLessonList2 = updatedCurriculum.sectionList[sectionIndex].lessonList;
    const len = updatedCurriculum.sectionList[sectionIndex].lessonList[lessonIndex].assets.pdf.length;
    updatedLessonList2[lessonIndex].assets.pdf[len - 1].pdfID = pdfID;
    const updatedSectionList2 = curriculum.sectionList;
    updatedSectionList2.lessonList = updatedLessonList2;
    const updatedCurriculum2 = { ...curriculum, ...{ sectionList: updatedSectionList2 } };
    console.log('updatedCurriculum2: ', updatedCurriculum2);
    await setCurriculum(updatedCurriculum2);

    setSelectedFile(null);
    setPdfData(null);
    setNumPages(null);
    setResetFileInput(Math.random().toString(36)); // https://stackoverflow.com/questions/42192346/how-to-reset-reactjs-file-input
  }

  if (isUploadingViaApi === true) {
    return (
      <div>
        Uploading..
      </div>
    );
  }

  return (
    <div className="PDFV">
      <div className="PDFV_A2">
        <input type="file" accept=".pdf" key={resetFileInput || ''} onChange={onFileLoad} />

        {pdfData && (
          <button type="button" onClick={(e) => { uploadPdfFile(e); }} className="PDFV_A3"> Upload </button>
        )}
      </div>

      {pdfData && (
        <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
          {/* <Page pageNumber={pageNumber} /> */}
          {/* eslint-disable-next-line prefer-spread */}
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            /* eslint-disable-next-line arrow-body-style */
            .map((page) => {
              console.log('page:', page);
              return (
                <Page
                  pageNumber={page}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              );
            })}
        </Document>
      )}

      {pdfData && (
        <p>
          Total pages: {numPages}
        </p>
      )}

      {/*
      {pdfData && (
        <p>
          Page {pageNumber} of {numPages}
        </p>
      )}
     */}

    </div>
  );
}

export default PDFViewer;
