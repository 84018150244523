/*  eslint-disable no-unused-vars */
import React from 'react';
//  import { useNavigate } from 'react-router-dom';
import './YetToBeImplemented.css';
import TryForm from '../../../../../../../../../../../Findus/ReachUsForm';

function YetToBeImplemented({ curriculum, setCurriculum, clCardItem, setClCardItem, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  //  const navigate = useNavigate();

  return (
    <div className="YTBI">
      <div className="YTBI_A1">
        Available after your integration with platform. Reach us using this form.
      </div>
      <div className="YTBI_A2">
        <TryForm />
        {/*
        <button type="button" onClick={() => navigate('/reachUsForm')}>
          BuildWithUs
        </button>
        */}
      </div>
    </div>
  );
}

export default YetToBeImplemented;
/*  eslint-enable no-unused-vars */
