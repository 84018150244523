import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CatelogueItem.css';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modals/Modal';
import MarketingTool1Modal from './Modals/MarketingTool1Modal';

function MarketingTool1() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="CIT NotYetAvailable" onClick={() => setIsOpen(true)}>
        <div className="CIT_A1">
          <FontAwesomeIcon icon={faCartPlus} />
        </div>
        <div className="CIT_A2">
          Upsell
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <MarketingTool1Modal />
      </Modal>
    </div>
  );
}

export default MarketingTool1;
