import React, { forwardRef } from 'react';
import './DropdownContent.css';

const DropdownContent = forwardRef((props, ref) => {
  const { children, open, top, right } = props;
  return (
    <div
      className={`dropdown-content ${open ? 'content-open' : null}`}
      style={{ top: top ? `${top}px` : '100%', right: right ? `${right}px` : '0%' }}
      ref={ref}
    >
      {children}
    </div>
  );
});

export default DropdownContent;
