/*  eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CatelogueItem.css';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modals/Modal';
import CodeExampleModal from './Modals/CodeExampleModal';

function CodeExample({ curriculum, setCurriculum, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="CIT NotYetAvailable" onClick={() => setIsOpen(true)}>
        <div className="CIT_A1">
          <FontAwesomeIcon icon={faCode} />
        </div>
        <div className="CIT_A2">
          Code Example
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <CodeExampleModal />
      </Modal>
    </div>
  );
}

export default CodeExample;
/*  eslint-enable no-unused-vars */
