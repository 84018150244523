/*  eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PayUPaymentPage from './PayUPaymentPage';
import { images } from '../../constants';
import './PayUMoneyForm.css';
import FetchJwtToken from '../BackendServices/FetchJwtToken';
// https://www.youtube.com/watch?v=aVRnx1YYpDc&t=201s

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}
async function sha512(form, txnID) {
  //  const { fname, lname, email, amount, productInfo } = form;
  const key = '5BIUuo'; // 'Z4iLcO';
  // const SALT = 'ISm5cmRT6XebDkDvLmpki5P2BZBgEA6F';
  const SALT = 'cKIgHhk7MbRSc62Ch6ceed6ZVxSVF8jg';

  const reqObj = {
    txnid: txnID,
    productInfo: form.productinfo,
    amount: form.amount,
    email: form.email,
    firstname: form.fname,
    lastname: form.lname,
    phone: form.phone,
    udf1: form.udf1,
    udf2: form.udf2,
    udf3: form.udf3,
    udf4: form.udf4,
    udf5: form.udf5,
  };
  const str = `${key}|${reqObj.txnid}|${reqObj.amount}|${reqObj.productInfo}|${reqObj.firstname}|${reqObj.email}|${reqObj.udf1}|${reqObj.udf2}|${reqObj.udf3}|${reqObj.udf4}|${reqObj.udf5}||||||${SALT}`;
  console.debug(str);
  // sha512(`${key}|${reqObj.txnid}|${reqObj.amount}|${reqObj.productInfo}|${reqObj.firstname}|${reqObj.email}|${reqObj.udf1}|${reqObj.udf2}|${reqObj.udf3}|${reqObj.udf4}|${reqObj.udf5}||||||${SALT}`).then((hcode) => setHash1(hcode));

  const utf8 = new TextEncoder().encode(str);
  await timeout(1000);
  return crypto.subtle.digest('SHA-512', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return { hashHex, txnID };
    // return hashHex;
  });
}

function PayUMoneyForm() {
  const [form, setForm] = useState({ fname: '', lname: '', phone: '', amount: 0, productinfo: '', udf1: '', udf2: '', udf3: '', udf4: '', udf5: '' });
  const [toggle, setToggle] = useState(1);
  const [hash, setHash] = useState(null);
  const [txnID, setTransactionID] = useState(null);

  const handleChange = (e) => {
    if (e.target.name === 'amount') {
      setForm({ ...form, [e.target.name]: parseFloat(e.target.value) });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    (async () => {
      console.debug('useEffect');
      //  fetchJwtToken();
      const tokens = await FetchJwtToken();
      if (tokens.jwtAccessToken === '') {
        // setAmplifyUser('Login');
      } else {
        const updatedForm = {
          ...form,
          udf1: 'JPNagar, Bangalore',
          udf2: 'ShippingMethod',
          udf3: tokens.username,
          udf4: tokens.userpoolid,
          udf5: tokens.clientid,
        };
        console.log(updatedForm);
        setForm(updatedForm);
      }
    })();
  }, []);

  function generateTransactionId() {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = 'T';
    const txnID1 = `TXN${merchantPrefix}${timestamp}${randomNum}`;
    return setTransactionID(txnID1);
  }

  const getHash = () => {
    sha512(form, txnID)
      .then((res) => {
        console.log('PPPP1', form);
        console.log('PPPP2', txnID);
        console.log('PPPP3', res.hashHex);
        setHash(res.hashHex);
        setTransactionID(txnID);
        // setHash(res.data.hash);
        // setTransactionID(res.data.txnID);
        // window.location.href = res.data;
        // setToggle(2);
      })
      .catch((error) => {
        console.log('getHash)_ encountered error.', error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    getHash();
    setToggle(2);
    console.log('KKKKK', hash);
  };

  useEffect(() => {
    generateTransactionId();
  }, []);

  return (
    <>
      { (toggle === 1)
      && (
      <div className="PUF_0">
        <div className="PUF_1">
          <div className="PUF_2">
            <img width={350} src={images.AArt1} alt="" className="PUF_3" />
            <h1 className="PUF_4"> Payment Gateway Imtegration</h1>
          </div>
          <form onSubmit={handleSubmit} className="PUF_5">
            <div className="mb-3">
              <label className="form-label"> First Name </label>
              <input value={form?.fname} required type="text" name="fname" className="form-control" onChange={handleChange} />
            </div>
            <div className="mb-3">
              <label className="form-label"> Last Name </label>
              <input value={form?.lname} required type="text" name="lname" className="form-control" onChange={handleChange} />
            </div>
            <div className="mb-3">
              <label className="form-label"> Email </label>
              <input value={form?.email} required type="text" name="email" className="form-control" onChange={handleChange} />
            </div>
            <div className="mb-3">
              <label className="form-label"> Phone Number </label>
              <input value={form?.phone} required type="text" name="phone" className="form-control" onChange={handleChange} />
            </div>
            <div className="mb-3">
              <label className="form-label"> Amount </label>
              <input value={form?.amount} required type="text" name="amount" className="form-control" onChange={handleChange} />
            </div>
            <div className="mb-3">
              <label className="form-label"> Product Info </label>
              <input value={form?.productInfo} required type="text" name="productinfo" className="form-control" onChange={handleChange} />
            </div>
            <div className="mb-3 form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" />
              <label className="form-check-label"> Check me out </label>
            </div>
            <button type="submit" className="mb-submit">Check Details</button>
          </form>
        </div>
      </div>
      )}
      { (toggle === 2)
      && (
        <PayUPaymentPage setToggle={setToggle} form={form} hash={hash} txnID={txnID} />
      )}
    </>
  );
}

export default PayUMoneyForm;
//  eslint-enable jsx-a11y/label-has-associated-control
