import React from 'react';
import Quiz from './Quiz';
import './EducationalTools.css';

function EducationalTools() {
  return (
    <div className="ET">
      <div className="ET_Header">
        EducationalTools
      </div>
      <div className="ET_Body">
        <div className="ET_Body_A1">
          <Quiz />
        </div>
      </div>
    </div>
  );
}

export default EducationalTools;
