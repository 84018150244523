import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListDots, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { images } from '../../../../../../../../constants';
import './ACStarter.css';

function ACStarter(props) {
  const { clCardItem, setClCardItem } = props;
  console.log(clCardItem, setClCardItem);

  function handleClick() {
    if (clCardItem === 'Preview') {
      setClCardItem('Catalogue');
    } else {
      setClCardItem('Preview');
    }
  }
  return (
    <div className="ST">
      <div className="ST_A1">
        <img src={images.Origami} alt="header_img" />
      </div>
      <div className="ST_A2">
        You have got knowledge, its time to share it.
      </div>
      <div className="ST_A3" onClick={() => { handleClick(); }}>
        { (clCardItem === 'Preview')
        && (
        <div className="ST_A31">
          <div className="ST_A31">
            <FontAwesomeIcon icon={faPlusCircle} />
          </div>
          <div className="ST_A32">
            Add Content
          </div>
        </div>
        )}
        { (clCardItem === 'Catalogue')
        && (
        <div className="ST_A31">
          <div className="ST_A31">
            <FontAwesomeIcon icon={faListDots} />
          </div>
          <div className="ST_A32">
            List Content
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

export default ACStarter;
