import React, { useEffect, useState, useRef } from 'react';
import './Dropdown.css';
import DropdownButton from '../DropdownButton/DropdownButton';
import DropdownContent from '../DropdownContent/DropdownContent';
//  https://www.youtube.com/watch?v=q6HevBxsPUM
//  eslint-disable-next-line arrow-body-style
const Dropdown = ({ buttonText, content }) => {
  const [open, setOpen] = useState(false);
  const [dropdownTop, setDropdownTop] = useState(0);
  const [dropdownRight, setDropdownRight] = useState(0);
  console.log('Dropdown: ', open);

  const dropdownRef = useRef();
  const buttonRef = useRef();
  const contentRef = useRef();

  const toggleDropdown = () => {
    if (!open) {
      const spaceRemaining = window.innerHeight - buttonRef.current.getBoundingClientRect().bottom;
      const contentHeight = contentRef.current.clientHeight;

      const topPosition = spaceRemaining > contentHeight
        ? null
        : -(contentHeight - spaceRemaining); // move up by height clipped by window
      setDropdownTop(topPosition);
      setDropdownRight(0); // content and button are right aligned
    }

    setOpen((openn) => !openn);
  };

  useEffect(() => {
    const handler = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} className="dropdown">
      <DropdownButton ref={buttonRef} toggle={toggleDropdown} open={open}>
        {buttonText}
      </DropdownButton>
      {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
      {
        <DropdownContent top={dropdownTop} right={dropdownRight} ref={contentRef} open={open}>
          {content}
        </DropdownContent>
      }
    </div>
  );
};

export default Dropdown;
