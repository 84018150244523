/*  eslint-disable no-unused-vars */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa2 } from '@fortawesome/free-solid-svg-icons';

function CPState2({ s2Done }) {
  return (
    <div className="CPS1" style={{ backgroundColor: s2Done ? 'green' : null }}>
      <div className="CPS1_A1">
        <FontAwesomeIcon icon={fa2} />
      </div>
      <div className="CPS1_A2">
        Add curriculum
      </div>
    </div>
  );
}

export default CPState2;
/*  eslint-enable no-unused-vars */
