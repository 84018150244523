/*  eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import EPPublishedCoursesTable2 from './EPPublishedCoursesTable2';
import './EPPublishedCourses.css';
import CourseCurriculum from '../../../../../../../../components/BackendServices/CourseCurriculum';

/*
  useEffect: api call to fetch courses and display
  coursename and institute might be only relevant props here
 */

function EPPublishedCourses(props) {
  const { page, setPage, setS1Done, setS2Done, setS3Done, setS4Done, curriculum, setCurriculum, initialCurriculum, selectedCatalogueIndex, setSelectedCatalogueIndex, isUploadingViaApi, setIsUploadingViaApi } = props;
  const [curriculumArray, setCurriculumArray] = useState([]);
  console.log('EPPublishedCourses', curriculum);

  useEffect(() => {
    (async () => {
      setIsUploadingViaApi(true);
      const resp = await CourseCurriculum({ action: 'GET_ALL_COURSE_CURRICULUMS' });
      setCurriculumArray(resp.data.Items);
      setIsUploadingViaApi(false);
      console.log('resp: ', resp.data);
      console.log('count: ', resp.data.Count);
      console.log('Items: ', resp.data.Items);
      //  console.log('Items0: ', resp.data.backendResponse.Items[0].ID);
    })();
  }, []);

  return (
    <div className="EPPCO">
      Published Courses and Drafted ones
      <div className="EPPCO_1">
        <table className="EPPCO_A1">
          <thead>
            <tr>
              {/*
              <th className="last_action_header">Last Action</th>
              <th className="type_header">Type</th>
              <th className="id_header">ID</th>
              */}
              <th className="saved_date_header">Saved Date</th>
              <th className="saved_date_header">IsPublished</th>
              <th className="type_header">Name</th>
              <th className="type_header">Price</th>
              <th className="type_header">Institute</th>
              <th className="more_header">More</th>
            </tr>
          </thead>
          <tbody>
            {isUploadingViaApi && <div>Loading..</div>}
            {!isUploadingViaApi && (curriculumArray.length === 0) && <div>Empty List</div>}
            {!isUploadingViaApi && (curriculumArray.length !== 0) && <EPPublishedCoursesTable2 curriculum={curriculum} setCurriculum={setCurriculum} initialCurriculum={initialCurriculum} curriculumArray={curriculumArray} selectedCatalogueIndex={selectedCatalogueIndex} setSelectedCatalogueIndex={setSelectedCatalogueIndex} />}
            <div style={{ height: '100px' }} /> { /* filler to keep Dropdown visible */ }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EPPublishedCourses;
/*  eslint-enable no-unused-vars */
