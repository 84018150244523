import React from 'react';
import { images } from '../../constants';
import './PayUPaymentPage.css';
// https://www.youtube.com/watch?v=aVRnx1YYpDc&t=201s

function PayUPaymentPage(props) {
  const { setToggle, form, hash, txnID } = props;
  console.log(form);
  const surl = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/payment/success';
  const furl = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/payment/failure';
  const key = '5BIUuo'; // 'Z4iLcO';
  //  const SALT = 'ISm5cmRT6XebDkDvLmpki5P2BZBgEA6F';
  const SALT = 'cKIgHhk7MbRSc62Ch6ceed6ZVxSVF8jg';

  /*
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('PPPP', e.target);
  };
  */

  return (
    <div className="PPAGE_0">
      <div className="PPAGE_1">
        <div className="PPAGE_2">
          <img width={350} src={images.AArt1} alt="" className="PPAGE_3" />
          <h1 className="PPAGE_4"> Payment Gateway Integration</h1>
        </div>
        <div className="PPAGE_5">
          <div>
            <h4 className="PPAGE_6">Details</h4>
            <div className="PPAGE_7">
              <span className="PPAGE_8">First Name: {form.fname} </span>
              <span className="PPAGE_8">Last Name: {form.lname} </span>
              <span className="PPAGE_8">Phone Number: {form.phone} </span>
              <span className="PPAGE_8">Payment Amount: {form.amount} </span>
              <span className="PPAGE_8">Product Info: {form.productinfo} </span>
              <span className="PPAGE_8">txnID: {txnID} </span>
              <span className="PPAGE_8">hash: {hash} </span>
              <span className="PPAGE_8">key: {key} </span>
              <span className="PPAGE_8">surl: {surl} </span>
              <span className="PPAGE_8">udf1: {form.udf1} </span>
              <span className="PPAGE_8">udf2: {form.udf2} </span>
              <span className="PPAGE_8">udf3: {form.udf3} </span>
              <span className="PPAGE_8">udf4: {form.udf4} </span>
              <span className="PPAGE_8">udf5: {form.udf5} </span>
              <span className="PPAGE_8">str: {`${key}|${txnID}|${form.amount}|${form.productinfo}|${form.fname}|${form.email}|${form.udf1}|${form.udf2}|${form.udf3}|${form.udf4}|${form.udf5}||||||${SALT}`} </span>
            </div>
          </div>
          {/* <form target="_blank" action="https://test.payu.in/_payment" method="post" id="PayUForm"> */}
          <form target="_blank" action="https://secure.payu.in/_payment" method="post" id="PayUForm">
            <input type="hidden" name="key" value={key} />
            <input type="hidden" name="txnid" value={txnID} />
            <input type="hidden" name="productinfo" value={form.productinfo} /> {/* Test Product */}
            <input type="hidden" name="amount" value={form.amount} />
            <input type="hidden" name="email" value={form.email} />
            <input type="hidden" name="firstname" value={form.fname} />
            <input type="hidden" name="lastname" value={form.lname} />
            <input type="hidden" name="surl" value={surl} />
            <input type="hidden" name="furl" value={furl} />
            <input type="hidden" name="phone" value={form.phone} />
            <input type="hidden" name="udf1" value={form.udf1} />
            <input type="hidden" name="udf2" value={form.udf2} />
            <input type="hidden" name="udf3" value={form.udf3} />
            <input type="hidden" name="udf4" value={form.udf4} />
            <input type="hidden" name="udf5" value={form.udf5} />
            {/* <input type="submit" value="Pay(submit)" /> */}
            <input type="hidden" name="hash" value={hash} />
            <div className="PPAGE_10">
              <button type="submit" onClick={() => setToggle(1)} style={{ cursor: 'pointer' }}>Back</button>
              <button type="submit" style={{ cursor: 'pointer' }}>Pay Now</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PayUPaymentPage;
