import React from 'react';
import './TalkTwo.css';

const TalkTwo = () => (
  <div>
    <div>
      <video
        preload="none"
        tabIndex="-1"
        className="talkTwo__zero"
        width="1040"
        height="480"
        type="video/mp4"
        style={{ marginTop: '5rem', marginLeft: '5rem' }}
        controls
        controlsList="nodownload"
      >
        <source src="Videos/vid2.mp4" />
        <track src="" kind="captions" />
      </video>
    </div>
    <div>
      <video
        preload="none"
        tabIndex="-1"
        className="talkTwo__zero"
        width="1040"
        height="480"
        type="video/mp4"
        style={{ marginTop: '5rem', marginLeft: '5rem' }}
        controls
        controlsList="nodownload"
      >
        <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" />
        <track src="" kind="captions" />
      </video>
    </div>
  </div>
);

export default TalkTwo;
