import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import { SubHeading } from '../../../components';
import { images } from '../../../constants';
import './ProductsHeader.css';

const ProductsHeader = () => {
  const navigate = useNavigate();
  return (
    <div id="products">
      <div className="app__headerVOS app__wrapper section__padding">
        <div className="app__wrapper_info">
          <SubHeading title="Products, Solutions and Applications" />
          <div className="productsLogo">
            <img src={images.Boxes} alt="header_img" />
            <h1 className="app__header-h1">Products</h1>
          </div>
          {/* <p className="p__opensans" style={{ margin: '2rem 0' }}> Timely delivery and with quality that has intermediate milestones to measure progress. Compliant with well architected and well designed lenses for the end goals.</p> */}
          <p className="p__opensans" style={{ margin: '2rem 0' }}> Leveraging AWS and Apple technology to build products, solutions, apps that are useful and practical.</p>
          <div className="app__header_buttons">
            <div className="exploreTrack" onClick={() => { navigate('/productsList'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}>
              <div>Catalogue</div>
              <FontAwesomeIcon className="angleRightLogo" icon={faAngleDoubleRight} />
            </div>
            <FontAwesomeIcon onClick={() => { navigate('/'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} className="angleUpLogo" icon={faAngleDoubleUp} />
          </div>
        </div>
        <div className="app__wrapper_img">
          <img src={images.Products2} alt="header_img" style={{ scale: '0.8' }} />
        </div>
      </div>
    </div>
  );
};

export default ProductsHeader;
