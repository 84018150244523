/*  eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import './AddSections.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faAngleDoubleLeft, faAngleDoubleRight, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import AddLessons from './AddLessons';
import Dropdown from './components/Dropdown/Dropdown';
import DropdownItem from './components/DropdownItem/DropdownItem';
import CourseCurriculum from '../../../../../../components/BackendServices/CourseCurriculum';
//  https://www.youtube.com/watch?v=XtS14dXwvwE&t=1232s

function AddSections(props) {
  const { page, setPage, setS1Done, setS2Done, setS3Done, setS4Done, curriculum, setCurriculum,
    lessonParent, setLessonParent, isUploadingViaApi, setIsUploadingViaApi } = props;
  const [sectionList, setSectionList] = useState(curriculum.sectionList);

  console.log(curriculum);
  console.log(sectionList);

  const handleSectionAdd = async (index) => {
    console.log('handleSectionAdd', index, curriculum);
    const list = [...sectionList,
      {
        sectionName: 'New Section Name',
        sectionID: '',
        isSectionNameSaved: false,
        isSectionPublished: false,
        lessonList: [{
          lessonName: 'New Lesson Name',
          lessonID: '',
          isLessonNameSaved: false,
          isLessonPublished: false,
          assets: {
            video: [], // videoName, videoID
            richText: [], // richTextName, richTextID
            pdf: [], // pdfName, pdfID
            audio: [], // audioName, audioID
          },
        }],
      }];
    const updatedValue = { sectionList: list };
    const updatedCurriculumJson = { ...curriculum, ...updatedValue };
    console.log('updatedCurriculumJson', updatedCurriculumJson);
    await setCurriculum(updatedCurriculumJson);
    //  Note: this will re-render AddLessons and should be followed
    //  by setCurriculum and not before.
    //  AddLessons uses curriculum and at last sectionIndex which would have not
    //  populated before setCurriculumApi and hence will cause out-of-range error.
    //  Better policy: keep state updates (and hence re-rendering of different components)
    //  after await of setCurriculum is done.
    setSectionList(list);
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleSectionRemove = async (index) => {
    console.log('handleSectionRemove', index, sectionList, curriculum);

    const list = [...sectionList];
    console.log('list: ', list);
    list.splice(index, 1);
    const updatedValue = { sectionList: list };
    const updatedCurriculumJson = { ...curriculum, ...updatedValue };
    console.log('updatedCurriculumJson:', updatedCurriculumJson);

    setIsUploadingViaApi(true);
    await CourseCurriculum({ curriculum: updatedCurriculumJson, sectionIndex: index, action: 'DELETE_SECTION' });
    setIsUploadingViaApi(false);

    await setCurriculum(updatedCurriculumJson);
    setSectionList(list);

    console.log('updatedCurriculumJson:', updatedCurriculumJson);
  };

  const handleSectionSave = async (index) => {
    console.log('handleSectionSave:', index, curriculum, sectionList);
    const list = [...sectionList];
    console.log('list:', list);
    list[index].isSectionNameSaved = true;
    console.log('sectionList:', index, list);
    const updatedValue = { sectionList: list };
    const updatedCurriculumJson = { ...curriculum, ...updatedValue };

    setIsUploadingViaApi(true);
    const resp = await CourseCurriculum({ curriculum: updatedCurriculumJson, sectionIndex: index, action: 'SET_SECTION_NAME' });
    if (resp.status !== 200) {
      console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
    }
    list[index].sectionID = resp.data.sectionID;
    const updatedValue2 = { sectionList: list };
    const updatedCurriculumJson2 = { ...curriculum, ...updatedValue2 };
    updatedCurriculumJson2.courseID = resp.data.courseID;
    setIsUploadingViaApi(false);

    await setCurriculum(updatedCurriculumJson2); // this should come as early as possible before updating other state/s
    setSectionList(list);

    setS2Done(true);
  };

  async function addLessonsToSection(lessonsJson, sectionIndex) {
    console.log('addLessonsToSection:', lessonsJson, sectionIndex);
    const list = [...sectionList];
    list[sectionIndex].lessonList = lessonsJson;
    console.log('sectionList:', sectionIndex, list);

    const updatedValue = { sectionList: list };
    const updatedCurriculumJson = { ...curriculum, ...updatedValue };
    await setCurriculum(updatedCurriculumJson);
    setSectionList(list);
    console.log('curriculum:', curriculum);

    setS2Done(true);
  }

  const handleSectionChange = (updatedName, index) => {
    console.log('handleSectionChange', updatedName, index, sectionList);
    const list = [...sectionList];
    list[index].sectionName = updatedName;
    list[index].isSectionNameSaved = false;
    setSectionList(list);
    console.log('list: ', list);
  };

  function goAddInformation() {
    console.log('goAddInformation');
    setPage('information');
  }
  function goAddPrice() {
    console.log('goAddPrice');
    setPage('pricing');
  }
  function sectionNameClick(index) {
    console.log('SectionName clicked', index);
  }
  function handleEditSectionName(index) {
    console.log('handleEditSectionName', index);
  }
  function handleDeleteSectionName(index) {
    console.log('handleDeleteSectionName', sectionList);
    handleSectionRemove(index);
  }
  async function handleRenameSectionName(index) {
    console.log('handleRenameSectionName', index);
    // handleSectionChange(curriculum.sectionList[index].sectionName, index);
    const list = [...sectionList];
    list[index].sectionName = curriculum.sectionList[index].sectionName;
    list[index].isSectionNameSaved = false;
    const updatedValue = { sectionList: list };
    const updatedCurriculumJson = { ...curriculum, ...updatedValue };

    setIsUploadingViaApi(true);
    const resp = await CourseCurriculum({ curriculum: updatedCurriculumJson, sectionIndex: index, action: 'RESET_SECTION_NAME' });
    if (resp.status !== 200) {
      console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
    }
    list[index].sectionID = resp.data.sectionID;
    const updatedValue2 = { sectionList: list };
    const updatedCurriculumJson2 = { ...curriculum, ...updatedValue2 };
    updatedCurriculumJson2.courseID = resp.data.courseID;

    setIsUploadingViaApi(false);
    await setCurriculum(updatedCurriculumJson2);
    setSectionList(list);
    console.log(curriculum);
  }

  async function handleUnPublishSectionName(index) {
    console.log('handlePublishSectionName', index);
    /* publish all lessons in this section and mark the section published */
    const list = [...sectionList];
    list[index].sectionName = curriculum.sectionList[index].sectionName;
    list[index].isSectionPublished = false;
    const updatedValue = { sectionList: list };
    const updatedCurriculumJson = { ...curriculum, ...updatedValue };
    setIsUploadingViaApi(true);
    const resp = await CourseCurriculum({ curriculum: updatedCurriculumJson, sectionIndex: index, action: 'RESET_SECTION_PUBLISH' });
    if (resp.status !== 200) {
      console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
    }
    list[index].sectionID = resp.data.sectionID;
    list[index].sectionPublishedID = resp.data.sectionID;
    const updatedValue2 = { sectionList: list };
    const updatedCurriculumJson2 = { ...curriculum, ...updatedValue2 };
    updatedCurriculumJson2.courseID = resp.data.courseID;

    setIsUploadingViaApi(false);
    await setCurriculum(updatedCurriculumJson2);
    setSectionList(list);
    console.log(curriculum);
  }

  async function handlePublishSectionName(index) {
    console.log('handlePublishSectionName', index);
    /* publish all lessons in this section and mark the section published */
    const list = [...sectionList];
    list[index].sectionName = curriculum.sectionList[index].sectionName;
    list[index].isSectionPublished = true;
    const updatedValue = { sectionList: list };
    const updatedCurriculumJson = { ...curriculum, ...updatedValue };
    setIsUploadingViaApi(true);
    await CourseCurriculum({ curriculum: updatedCurriculumJson, sectionIndex: index, action: 'SET_SECTION_PUBLISH' });
    setIsUploadingViaApi(false);

    await setCurriculum(updatedCurriculumJson);
    setSectionList(list);
    console.log(curriculum);
  }
  const items = [
    /* { name: 'Edit', handler: handleEditSectionName }, */
    { name: 'Delete', handler: handleDeleteSectionName },
    { name: 'Rename', handler: handleRenameSectionName },
    { name: 'Publish', handler: handlePublishSectionName },
    { name: 'UnPublish', handler: handleUnPublishSectionName },
  ];

  return (
    <div className="AS_C1">
      <div className="AS_A1">
        <div className="AS_A4">
          Start creating your course
        </div>
        <div className="AS_A3">
          CurvSort courses are a flexible way to deliver and sell your knowledge. Optimized for creative control and a powerful student learning experience, CurvSort courses enable you to easily organize your content, employ advanced features like course compliance, and offer flexible pricing options to maximize your sales. Learn more
        </div>
      </div>
      <div className="form-field">

        <div className="ASECT_A1">

          <div className="ASECT_A2" onClick={goAddInformation}>
            <div className="ASECT_A3">
              Add Information
            </div>
            <div className="ASECT_A4">
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </div>
          </div>

          <div className="ASECT_A5" onClick={goAddPrice}>
            <div className="ASECT_A6">
              Add Price
            </div>
            <div className="ASECT_A7">
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </div>
          </div>

        </div>

        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="sectionName" style={{ fontSize: '30px', color: 'white' }}> {curriculum.courseName} </label>
        {!((curriculum.isInstituteNameSaved) && (curriculum.isCourseNameSaved))
        && (
          <div className="unavailable_section">
            Institute Name and Course Name are to be saved first. These can be renamed later.
          </div>
        )}

        {sectionList.map((singleSection, index) => (
          <div key={index} className="sections">
            <div className="division-container-container">
              <div className="division-container">
                <div className="first-division">
                  {!sectionList[index].isSectionNameSaved
                  && <input name="sectionName" type="text" id="sectionName" className="text" autoComplete="off" autoCorrect="off" required value={singleSection.sectionName} onChange={(e) => handleSectionChange(e.target.value, index)} /> }
                  {sectionList[index].isSectionNameSaved
                  && <div className="first-division_1"> {`Section ${index + 1}: `} {sectionList[index].sectionName} </div> }
                </div>
                { !((curriculum.isInstituteNameSaved) && (curriculum.isCourseNameSaved))
                  && (
                  <div className="save-remove-btn">
                    <button type="button" className="unavailable_section">
                      <span>Unavailable</span>
                    </button>
                  </div>
                  )}
                {(curriculum.isInstituteNameSaved && curriculum.isCourseNameSaved)
                && (
                <div className="second-division">
                  {(sectionList.length > 0)
                  && (
                  <div className="save-remove-btn">
                    { ((!isUploadingViaApi) && ((!sectionList[index].isSectionNameSaved) && (sectionList[index].lessonList.length >= 1)))
                      && (
                      <div className="save-remove-btn">
                        <button type="button" className="save-btn" onClick={() => handleSectionSave(index)}>
                          <span>Save</span>
                        </button>
                      </div>
                      )}
                    { ((!isUploadingViaApi) && (!sectionList[index].isSectionNameSaved && (sectionList[index].lessonList.length < 1)))
                      && (
                      <div className="save-remove-btn">
                        <button type="button" className="remove-btn" onClick={() => handleSectionRemove(index)}>
                          <span>Cancel</span>
                        </button>
                        <button type="button" className="save-btn" onClick={() => handleSectionSave(index)}>
                          <span>Save</span>
                        </button>
                      </div>
                      )}
                    { ((!isUploadingViaApi) && sectionList[index].isSectionNameSaved)
                        && (
                        <button type="button" className="save-btn">
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <Dropdown
                            buttonText={<FontAwesomeIcon icon={faEllipsisVertical} />}
                            content={(
                              <>
                                {items.map((item, id) => (
                                  <DropdownItem key={id} onClick={() => item.handler(index)}>
                                    {`${item.name}`}
                                  </DropdownItem>
                                ))}
                              </>
                            )}
                          />
                        </button>
                        )}
                    { (isUploadingViaApi) // && sectionList[index].isSectionNameSaved)
                        && (
                        <button type="button" className="processing-btn">
                          ...
                        </button>
                        )}
                  </div>
                  )}
                </div>
                )}
              </div>
              <div>
                {(sectionList[index].lessonList.length >= 0)
                && (
                <AddLessons
                  page={page}
                  setPage={setPage}
                  curriculum={curriculum}
                  setCurriculum={setCurriculum}
                  sectionIndex={index}
                  addLessonsToSection={
                    (lessonsObj) => addLessonsToSection(lessonsObj, index)
                  }
                  lessonParent={lessonParent}
                  setLessonParent={setLessonParent}
                  isUploadingViaApi={isUploadingViaApi}
                  setIsUploadingViaApi={setIsUploadingViaApi}
                />
                )}
              </div>
            </div>
            <div className="add-entry">
              {(curriculum.isInstituteNameSaved && curriculum.isCourseNameSaved && curriculum.sectionList[curriculum.sectionList.length - 1].isSectionNameSaved && (sectionList.length - 1 === index) && (sectionList.length < 10))
                && (
                  <button type="button" className="add-btn" onClick={() => handleSectionAdd(index)}>
                    <FontAwesomeIcon icon={faPlusSquare} />
                    <span>Add New Section</span>
                  </button>
                )}
            </div>
          </div>
        ))}
        {!(curriculum.isInstituteNameSaved && curriculum.isCourseNameSaved && curriculum.sectionList[curriculum.sectionList.length - 1].isSectionNameSaved)
          && (
            <div style={{ }}>
              <div className="add-entry2" />
              <div className="unavailable2">
                <button type="button" className="unavailable3">
                  <FontAwesomeIcon icon={faPlusSquare} />
                  <span>Unavailable</span>
                </button>
              </div>
            </div>
          )}
        {(curriculum.isInstituteNameSaved && curriculum.isCourseNameSaved && (sectionList.length === 0))
          && (
            <div style={{ }}>
              <div className="add-entry2" />
              <div className="add-entry">
                <button type="button" className="add-btn" onClick={() => handleSectionAdd(0)}>
                  <FontAwesomeIcon icon={faPlusSquare} />
                  <span>Add New Section</span>
                </button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default AddSections;
/*  eslint-enable no-unused-vars */
