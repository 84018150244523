import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import S3Upload from '../../../../../../../../../../../../components/BackendServices/S3Upload';
import './TextAndImagesModal.css';
import 'react-quill/dist/quill.snow.css';
import CourseCurriculum from '../../../../../../../../../../../../components/BackendServices/CourseCurriculum';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false], font: [], size: [] }],
    [{ font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
  ],
};

function TextAndImagesModal({ curriculum, setCurriculum, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  const [value, setValue] = useState('');
  //  eslint-disable-next-line no-unused-vars
  const [uploadProgress, setUploadProgress] = useState(0);
  console.log(value, setValue);

  function setValue1(val) {
    console.log(val, val.length);
    const quillMagicEmptyString = '<p><br></p>';
    if (val === quillMagicEmptyString) {
      setValue('');
    } else {
      setValue(val);
    }
  }

  async function handleUpload(e) {
    console.log('handleUpload', e, value);

    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = today.getHours();
    const min = today.getMinutes();
    const sec = today.getSeconds();
    const assetFilename = `${month}_${date}_${year}_${hour}_${min}_${sec}.rtf`;

    const formData = new FormData();
    formData.append('file', value);
    // formData.append('file', assetFilename);
    // formData.append('api_key', 'abcd1234');
    setIsUploadingViaApi(true);
    // const response11 = await S3Upload(`richText/${assetFilename}`, formData, setUploadProgress);
    const contentType = 'text/richtext';
    // const response11 = await S3Upload(`richText/${assetFilename}`, value, contentType, setUploadProgress);
    const response11 = await S3Upload(value, `richText/${assetFilename}`, formData, contentType, setUploadProgress);
    console.log('S3Upload response:', response11);
    if (response11.status !== 200) {
      console.log('error in S3Upload()');
    }
    // const category = 'RICHTEXT';
    //  const response1 = await SaveAsset(filename, lessonParent, category);
    //  console.log('SaveAsset response (PDF):', response1);
    setIsUploadingViaApi(false);

    const sectionIndex = lessonParent.SectionIndex;
    const lessonIndex = lessonParent.LessonIndex;

    // update curriculum with RichText filename
    const updatedLessonList = curriculum.sectionList[sectionIndex].lessonList;
    updatedLessonList[lessonIndex].assets.richText.push({
      richTextName: assetFilename,
      richTextID: '',
    });
    const updatedSectionList = curriculum.sectionList;
    updatedSectionList.lessonList = updatedLessonList;
    const updatedCurriculum = { ...curriculum, ...{ sectionList: updatedSectionList } };
    console.log('updatedCurriculum: ', updatedCurriculum);
    const response1 = await CourseCurriculum({ curriculum: updatedCurriculum, assetFilename, lessonParent, action: 'POST_RICHTEXT_ASSET' });
    if (response1.status !== 200) {
      console.log('error while calling api, handle error.');
    }
    console.log('CourseCurriculum POST_RICHTEXT_ASSET: ', response1);

    // update curriculum with assetID returned from api response
    const richTextID = response1.data.assetID.slice('ASSET_ID#'.length);
    const updatedLessonList2 = updatedCurriculum.sectionList[sectionIndex].lessonList;
    const len = updatedCurriculum.sectionList[sectionIndex].lessonList[lessonIndex].assets.richText.length;
    console.log('len: ', len);
    updatedLessonList2[lessonIndex].assets.richText[len - 1].richTextID = richTextID;
    const updatedSectionList2 = curriculum.sectionList;
    updatedSectionList2.lessonList = updatedLessonList2;
    const updatedCurriculum2 = { ...curriculum, ...{ sectionList: updatedSectionList2 } };
    console.log('updatedCurriculum2: ', updatedCurriculum2);
    await setCurriculum(updatedCurriculum2);

    setValue('');
  }

  if (isUploadingViaApi === true) {
    return (
      <div>
        Uploading..
      </div>
    );
  }

  return (
    <div className="TI_container">
      <div className="TI_container">
        <div className="TI_upload_container">
          <div className="TI_upload_filler" />
          { (value.length > 0) && <div className="TI_upload" onClick={(e) => handleUpload(e)}> Upload </div> }
        </div>
        <div className="TI_row">
          <div className="TI_editor">
            <ReactQuill
              theme="snow"
              value={value}
              onChange={(e) => setValue1(e)}
              className="TI_editor-input"
              modules={modules}
            />
          </div>
          {/* Issue: <p> tag is not rendered as line break or a newline and words come on same line. This is rendered fine as html otherwise. */}
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: value }} className="TI_preview" />
        </div>
      </div>
    </div>
  );
}

export default TextAndImagesModal;
