/*  eslint-disable no-unused-vars */
import React from 'react';
import './EPClassroomEnroll.css';
import EPClassroomLesson from './EPClassroomLesson';

function EPClassroomEnroll(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes } = props;
  console.log('EPClassroomEnroll enrolledCurriculum', enrolledCurriculum);

  // eslint-disable-next-line prefer-destructuring
  if ((enrolledCurriculum === undefined) || (enrolledAttributes === undefined)) {
    return (
      <div className="EPCLASS10">
        <div className="EPCLASS11">
          A classroom is not yet entered.
        </div>
        <div className="EPCLASS12" onClick={() => setPage('inprogressEnroll')}>
          Enter A Classroom
        </div>
      </div>
    );
  }

  return (
    <div className="EPCLASS">
      <div className="EPCLASS_4">
        <div className="EPCLASS_1">
          Course: {enrolledCurriculum.courseName}
        </div>
        <div className="EPCLASS_2" onClick={() => setPage('inprogressEnroll')}>
          Enter Another Classroom
        </div>
      </div>
      <div className="EPCLASS_3">
        <EPClassroomLesson page={page} setPage={setPage} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} enrolledCurriculum={enrolledCurriculum} setEnrolledCurriculum={setEnrolledCurriculum} enrolledAttributes={enrolledAttributes} setEnrolledAttributes={setEnrolledAttributes} />
      </div>
    </div>
  );
}

export default EPClassroomEnroll;
/*  eslint-enable no-unused-vars */
