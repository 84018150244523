/*  eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import './CreateLesson.css';
import CLCard from './CLCard';
import Panel from './AddContent/ContentCatalogue/Panel';
import CEdit from './AddContent/ContentCatalogue/CatalogueItems/Modals/Curriculum/CEdit';
import CPreview from './AddContent/ContentCatalogue/CatalogueItems/Modals/Curriculum/CPreview';

function CreateLesson(props) {
  const { page, setPage, setS1Done, setS2Done, setS3Done, setS4Done,
    curriculum, setCurriculum, lessonParent, setLessonParent, isUploadingViaApi, setIsUploadingViaApi } = props;
  const [clCardItem, setClCardItem] = useState('Catalogue');

  function goAddChapter() {
    console.log('goAddChapter');
    setPage('edTech');
  }

  return (
    <div className="CL_C1">
      <div className="CL_D1">
        <div className="CL_D2" onClick={goAddChapter}>
          <div className="CL_D3">
            Add Section
          </div>
          <div className="CL_D4">
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </div>
        </div>
      </div>
      <div className="CL_B1">
        <div className="CL_B2">
          <div className="CL_B2">
            <div className="CL_B2_1">
              Section {lessonParent.SectionIndex + 1}
            </div>
            <div className="CL_B2_2">
              {lessonParent.SectionName}
            </div>
          </div>
        </div>
        <div className="CL_B33">
          <div className="CL_B3">
            <CEdit />
          </div>
          <div className="CL_B4">
            <CPreview />
          </div>
        </div>
      </div>
      <div className="CL_A1">
        <div className="CL_A2">
          <CLCard clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} />
        </div>
        {(clCardItem !== '') && (
        <div className="CL_A3">
          <Panel curriculum={curriculum} setCurriculum={setCurriculum} panel={clCardItem} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        )}
      </div>
    </div>
  );
}

export default CreateLesson;
/*  eslint-enable no-unused-vars */
