import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import YetToBeImplemented from '../YetToBeImplemented/YetToBeImplemented';
import Modal from '../Modal';
import './CPublicPreview.css';
import './CEdit.css';

function CPublicPreview() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="CPPRE" onClick={() => setIsOpen(true)}>
        <div className="CPPRE_A3">
          <FontAwesomeIcon icon={faCircleCheck} />
        </div>
        <div className="CPPRE_A2">
          Public Preview
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div> <YetToBeImplemented /> </div>
      </Modal>
    </div>
  );
}

export default CPublicPreview;
