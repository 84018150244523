/*  eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import './EPLessonRichText.css';
import AssetsApi from '../../../../../../../../../../../components/BackendServices/AssetsApi';
import 'react-quill/dist/quill.snow.css';

function EPLessonRichText(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes, userTokens } = props;
  console.log('EPLessonRichText', props);
  const [rtValue, setRTValue] = useState('');

  useEffect(() => {
    (async () => {
      const sectionIndex = enrolledAttributes.EnrolledCourse.userCursor.section;
      const lessonIndex = enrolledAttributes.EnrolledCourse.userCursor.lesson;
      const richTextArray = enrolledCurriculum.sectionList[sectionIndex - 1].lessonList[lessonIndex - 1].assets.richText;
      const rtKeyName = richTextArray[0].richTextName;
      console.log('EPLessonRichText ', `richText/${rtKeyName}`);
      const resp = await AssetsApi({ action: 'GET_RICH_TEXT', key: `richText/${rtKeyName}` });
      if (resp.status !== 200) {
        console.log('GET_RICH_TEXT returned error. Cutsom handling.', resp);
      }
      console.log('resp:', resp);
      setRTValue(resp.data);
    })();
  }, []);

  return (
    <div className="LESSON_VDO">
      <div className="LESSON_VDO_1">
        RichText Header
      </div>
      <div className="LESSON_VDO_2">
        {/* <div dangerouslySetInnerHTML={{ __html: rtValue }} className="TI_preview" /> */}
        <ReactQuill
          readOnly
          theme="bubble"
          value={rtValue}
          className="TI_editor-input"
        />
      </div>
      <div className="LESSON_VDO_3">
        RichText Footer
      </div>
    </div>
  );
}

export default EPLessonRichText;
/*  eslint-enable no-unused-vars */
