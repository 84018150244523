/*  eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './EPLessonVideo.css';
import CourseCurriculum from '../../../../../../../../../../../components/BackendServices/CourseCurriculum';

function EPLessonVideo(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes, userTokens } = props;
  console.log('RRRR', props);
  const [hash1, setHash1] = useState('');
  const [urlAccessToken, setUrlAccessToken] = useState();
  const sectionIndex = enrolledAttributes.EnrolledCourse.userCursor.section;
  const lessonIndex = enrolledAttributes.EnrolledCourse.userCursor.lesson;
  const videoArray = enrolledCurriculum.sectionList[sectionIndex - 1].lessonList[lessonIndex - 1].assets.video;
  const videoKeyName = videoArray[0].videoName;
  // const videoKeyName = 'vid1.mp4';
  // const accessToken = userTokens.jwtAccessToken;
  console.log('EPLessonVideo ', `videos/${videoKeyName}`);
  const urlBase = 'https://d2tc0jno74e7ty.cloudfront.net/videos/';
  // console.log(urlAccessToken); // this will have hash and Authorization undefined, they are populated later

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  async function sha512(string) {
    const utf8 = new TextEncoder().encode(string);
    await timeout(1000);
    return crypto.subtle.digest('SHA-512', utf8).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
      return hashHex;
    });
  }

  useEffect(() => {
    (async () => {
      const reqObjVC = {
        key: 'Z4iLcO',
        SALT: '112233',
        name: 'video/vid1.mov',
      };
      const resp = await CourseCurriculum({ action: 'JWT_ACCESS_TOKEN_GET' });
      if (resp.status !== 200) {
        console.log('JWT_ACCESS_TOKEN_GET failed. Custom error handling', resp.status);
      }
      // setUserTokens(resp.data.tokens);
      const accessToken = resp.data.tokens.jwtAccessToken;
      console.log('access token:', resp.data.tokens.jwtAccessToken);
      console.log('id token:', resp.data.tokens.jwtIdToken);
      await sha512(`${reqObjVC.key}|${reqObjVC.SALT}|${reqObjVC.name}`)
        .then((hcode) => {
          setHash1(hcode);
          setUrlAccessToken(`${urlBase}${videoKeyName}?Authorization=Bearer ${accessToken}&hash=${hcode}`);
          console.log('urlAccessToken', `${urlBase}${videoKeyName}?Authorization=Bearer ${accessToken}&hash=${hcode}`);
        });
    })();
  }, []);

  return (
    <div className="LESSON_VDO">
      <div className="LESSON_VDO_1">
        VideoAsset Header
      </div>
      <div className="LESSON_VDO_2">
        {/*
        <iframe
          title="unique title"
          width="700"
          height="700"
          src={urlAccessToken}
        />
        */}
        <video // Failed to load resource: Plug-in handled load (probably require smaller chunks at hosting side or transcoding - not sure, experiment and check)
          preload="none"
          tabIndex="-1"
          className="talkTwo__zero"
          width="1040"
          height="480"
          type="video/mp4"
          style={{ marginTop: '5rem', marginLeft: '5rem' }}
          controls
          controlsList="nodownload"
        >
          <source src={urlAccessToken} />
          <track src="" kind="captions" />
        </video>
      </div>
      <div className="LESSON_VDO_3">
        VideoAsset Footer
      </div>
    </div>
  );
}

export default EPLessonVideo;
/*  eslint-enable no-unused-vars */
