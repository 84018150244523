//  import axios from 'axios';
import React, { useState, useEffect } from 'react';
//  import { Auth } from 'aws-amplify';
//  import axios from 'axios';
import FetchJwtToken from '../BackendServices/FetchJwtToken';
import './PayuPostForm.css';

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}
async function sha512(string) {
  const utf8 = new TextEncoder().encode(string);
  await timeout(1000);
  return crypto.subtle.digest('SHA-512', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  });
}

const key = 'Z4iLcO';
//  const surl = 'http://www.localhost:3000/surl';
//  const furl = 'http://www.localhost:3000/furl';
//  const surl = 'https://apiplayground-response.herokuapp.com/';
//  const furl = 'https://apiplayground-response.herokuapp.com/';
//  const surl = 'https://jt0ayr7po5.execute-api.ap-south-1.amazonaws.com/prod/customer/23456';
//  const furl = 'https://jt0ayr7po5.execute-api.ap-south-1.amazonaws.com/prod/customer/23456';
const surl = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/payment/success';
const furl = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/payment/failure';
const SALT = 'ISm5cmRT6XebDkDvLmpki5P2BZBgEA6F';
const ttxnid = `${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}`;

const PayuPostForm1 = () => {
  const [hash1, setHash1] = useState(false);

  const [/* jwtAccessToken */, setJwtAccessToken] = useState('');
  const [username, setUsername] = useState('');
  const [userPoolID, setUserPoolID] = useState('');
  const [clientid, setClientid] = useState('');

  /*
  const fetchUpdatedAccessToken = async (tokens) => {
    console.log('fetchRefreshedAccessToken');
    const baseURL = `https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/user/${tokens.userpoolid}/${tokens.username}/refreshedAccessToken`;
    const response = await axios.get(baseURL, {
      headers: {
        //  "Content-Type": "text/json",
        Authorization: `Bearer ${tokens.jwtAccessToken}`,
      },
      params: {
        clientId: tokens.clientid,
        refreshToken: tokens.refToken,
      },
    })
    //  eslint-disable-next-line prefer-arrow-callback
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    console.log('axios.get() refreshedToken response: ', response);

    if ((response !== undefined) && (response.data !== undefined)) {
      return response.data.AccessToken;
    }
    return '';
  };

  const fetchJwtToken = async () => {
    let tokens = {
      jwtAccessToken: '',
      jwtIdToken: '',
      username: '',
      userpoolid: '',
      clientid: '',
      refToken: '',
    };
    try {
      console.log('fetchJwtToken1');
      const session = await Auth.currentSession();
      const accesstoken = session.getAccessToken().getJwtToken();
      const idtoken = session.getIdToken().getJwtToken();
      const userInfo = await Auth.currentUserInfo();
      const authUser = await Auth.currentAuthenticatedUser();
      setJwtAccessToken(accesstoken);
      setUsername(userInfo.username);
      setUserPoolID(authUser.pool.userPoolId);
      setClientid(authUser.pool.clientId);
      //  const serverUrl = 'http://192.168.0.139:3300/jwttokens';
      tokens = {
        jwtAccessToken: accesstoken,
        jwtIdToken: idtoken,
        username: userInfo.username,
        userPoolID: authUser.pool.userPoolId,
        clientid: authUser.pool.clientId,
        freshAccessToken: '',
      };
      //
      //const serverUrl = 'http://localhost:3300/jwttokens';
      //const res1 = await axios.post(serverUrl, jwtTokens);
      //console.log('Response from calling axios.post to server url ', serverUrl, ' : ', res1);
      //
      //
      //  If user pays then backend adds him to UserGroup1. This membership is not reflected
      //  i existing jwtAccessToken until user signsout and relogin. To avoid that need
      //  fetch the updated access token and if they are different, use new one going forward.
      //
      const freshAccessToken = await fetchUpdatedAccessToken(tokens);
      tokens.freshAccessToken = freshAccessToken;
      if (tokens.freshAccessToken !== tokens.jwtAccessToken) {
        console.log('freshAccessToken is different. Taking this and moving forward.');
        tokens.jwtAccessToken = tokens.freshAccessToken;
        setJwtAccessToken(freshAccessToken);
      }
      return tokens;
    } catch (error) {
      //  setAmplifyUser('Login');
      console.log('Error fetching JWT token:', error);
    }
    return {};
  };
  */

  useEffect(() => {
    (async () => {
      console.debug('useEffect');
      //  fetchJwtToken();
      const tokens = await FetchJwtToken();
      if (tokens.jwtAccessToken === '') {
        // setAmplifyUser('Login');
      } else {
        setJwtAccessToken(tokens.jwtAccessToken);
        setUsername(tokens.username);
        setUserPoolID(tokens.userpoolid);
        setClientid(tokens.clientid);
      }
    })();
  }, []);

  /*
  const formRef = useRef(null);
  useEffect(() => {
    formRef.current.submit();
  }, []);
  */

  const reqObj = {
    txnid: ttxnid,
    productInfo: 'iPhone',
    amount: 1,
    email: 'blume0000@yahoo.com',
    firstname: 'Raj',
    lastname: 'Ba',
    phone: '9538348074',
    udf1: 'JPNagar, Bangalore',
    udf2: 'ShippingMethod',
    udf3: username,
    udf4: userPoolID,
    udf5: clientid,
  };
  const str = `${key}|${reqObj.txnid}|${reqObj.amount}|${reqObj.productInfo}|${reqObj.firstname}|${reqObj.email}|${reqObj.udf1}|${reqObj.udf2}|${reqObj.udf3}|${reqObj.udf4}|${reqObj.udf5}||||||${SALT}`;
  console.debug(str);
  sha512(`${key}|${reqObj.txnid}|${reqObj.amount}|${reqObj.productInfo}|${reqObj.firstname}|${reqObj.email}|${reqObj.udf1}|${reqObj.udf2}|${reqObj.udf3}|${reqObj.udf4}|${reqObj.udf5}||||||${SALT}`).then((hcode) => setHash1(hcode));

  /*
  const handleSubmit = (e) => {
    alert('Value were submitted');
    e.preventDefault();
    console.log(e);
  };
  */

  if (hash1 === false) {
    return 'Loading..';
  }
  return (
    <div className="formContainer">
      {/* <form target="_blank" onSubmit={handleSubmit} action="https://test.payu.in/_payment" method="post"> */}
      <form target="_blank" action="https://test.payu.in/_payment" method="post">
        <input type="hidden" name="key" value={key} />
        <input type="hidden" name="txnid" value={reqObj.txnid} />
        <input type="hidden" name="productinfo" value={reqObj.productInfo} />
        <input type="hidden" name="amount" value={reqObj.amount} />
        <input type="hidden" name="email" value={reqObj.email} />
        <input type="hidden" name="firstname" value={reqObj.firstname} />
        <input type="hidden" name="lastname" value={reqObj.lastname} />
        <input type="hidden" name="surl" value={surl} />
        <input type="hidden" name="furl" value={furl} />
        <input type="hidden" name="phone" value={reqObj.phone} />
        <input type="hidden" name="udf1" value={reqObj.udf1} />
        <input type="hidden" name="udf2" value={reqObj.udf2} />
        <input type="hidden" name="udf3" value={reqObj.udf3} />
        <input type="hidden" name="udf4" value={reqObj.udf4} />
        <input type="hidden" name="udf5" value={reqObj.udf5} />
        <input type="submit" value="Pay(submit)" />
        <input type="hidden" name="hash" value={hash1} />
      </form>
    </div>
  );
};

export default PayuPostForm1;
