import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
//  import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './AboutUs.css';

//  eslint-disable-next-line arrow-body-style
const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div id="about">
      {/* <div className="app__fillerForNavbar" /> */}
      <div className="app__headerAUS app__aboutus flex__center section__padding">
        <div className="app__aboutus-overlay flex__center">
          {/* <img src={images.G} alt="G_overlay" /> */}
        </div>

        <div className="app__aboutus-content flex__center">
          <div className="app__aboutus-content_about display:flex">
            <h1 className="headtext__cormorant">About</h1>
            {/* <img src={images.spoon} alt="about_spoon" className="spoon__img" /> */}
            {/* <p className="p__opensans">I Build solutions and provide consultancy, Publish books, videos and articles. This is done in the Domains of [1] serverless architectures on AWS, [2] iOS apps using Swift, SwiftUI, AWS [3] VisionOS apps, Augmented Reality (AR) and Virtual Reality(VR). </p> */}
            <p className="p__opensans">I Build solutions, provide consultancy, and publish content using videos and articles. AWS and Apple technology are leveraged. </p>
            <div className="app__header_buttons">
              <FontAwesomeIcon onClick={() => { navigate('/'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} className="angleUpLogo" icon={faAngleDoubleUp} />
              {/*
              <div className="exploreTrack" onClick={() => navigate('/talks')}>
                <div>Explore</div>
                <FontAwesomeIcon className="angleRightLogo" icon={faAngleDoubleRight} />
              </div>
              */}
              {/* <button type="button" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="custom__button">Back to Top</button> */}
              {/* <button type="button" className="custom__button">Know More</button> */}
            </div>
          </div>

          <div className="app__aboutus-content_knife flex__center">
            {/* <img src={images.knife} alt="about_knife" /> */}
            <div className="app__aboutus-vertical_line" />
          </div>

          <div className="app__aboutus-content_history">
            <h1 className="headtext__cormorant">Background</h1>
            {/* <img src={images.spoon} alt="about_spoon" className="spoon__img" /> */}
            <p className="p__opensans">I (Founder) Have worked in real and world class industry for over 20 years. Built and delivered products and services at scale - Synopsys, Yahoo, Netapp, Zynga, VMWare, Groupon. Earned Computer Science and Engineering degree, M Tech from IIT Delhi. </p>
            <div className="app__header_buttons">
              {/*
              <div className="exploreTrack" onClick={() => navigate('/talks')}>
                <div>Explore</div>
                <FontAwesomeIcon className="angleRightLogo" icon={faAngleDoubleRight} />
              </div>
              */}
              <FontAwesomeIcon onClick={() => { navigate('/'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} className="angleUpLogo" icon={faAngleDoubleUp} />
              {/* <button type="button" className="custom__button">Know More</button> */}
              {/* <button type="button" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="custom__button">Back to Top</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
