/*  eslint-disable no-unused-vars */
import React from 'react';

function EPClassroomCurriculum(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes } = props;
  return (
    <div>EPClassroomCurriculum</div>
  );
}

export default EPClassroomCurriculum;
/*  eslint-enable no-unused-vars */
