import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar/Navbar';
import { images } from '../../../constants';
import './Talks.css';
import SocialHandles from '../../SocialHandles/SocialHandles';
import FetchGroups from '../../BackendServices/FetchGroups';

const Talks = () => {
  const navigate = useNavigate();
  //  eslint-disable-next-line no-unused-vars
  const [groups, setGroups] = useState({});
  //  let userCheckGroups = {};

  /*
  const fetchJwtToken = async () => {
    let tokens = {
      jwtAccessToken: '',
      jwtIdToken: '',
      username: '',
      userpoolid: '',
      clientid: '',
    };
    try {
      const session = await Auth.currentSession();
      const accesstoken = session.getAccessToken().getJwtToken();
      const idtoken = session.getIdToken().getJwtToken();
      const userInfo = await Auth.currentUserInfo();
      const authUser = await Auth.currentAuthenticatedUser();
      tokens = {
        jwtAccessToken: accesstoken,
        jwtIdToken: idtoken,
        username: userInfo.username,
        userpoolid: authUser.pool.userPoolId,
        clientid: authUser.pool.clientId,
      };
      console.log('astate: ', astate);
    } catch (error) {
      //  setAmplifyUser('Login');
      console.log('Error fetching JWT token:', error);
    }
    return tokens;
  };
  */

  /*
  const tierRegular = 'USERTIER#0';
  const tierPremium = 'USERTIER#1';

  const fetchedUser = {
    Address: '',
    Gender: '',
    Country: '',
    UserPoolID: 'USERPOOLID#ap-south-1_nwLPCJQt7',
    Tier: tierPremium,
    SK: 'USERPOOLID#ap-south-1_nwLPCJQt7',
    PK: 'USERNAME#cf0ec6b1-745e-4564-a94b-4e344ef8bee0',
    Username: 'USERNAME#cf0ec6b1-745e-4564-a94b-4e344ef8bee0',
    Age: '',
    Name: 'NAME#Raj Ba',
  };
  //  console.log(fetchedUser);

  useEffect(() => {
    (async () => {
      const tokens = await FetchJwtToken();
      astate = tokens;
      console.log('tokens', astate);
      const response = await axios.get(
        `${baseURL}/${tokens.userpoolid}/${tokens.username}`,
        { headers: { Authorization: tokens.jwtAccessToken } },
      //  eslint-disable-next-line prefer-arrow-callback
      ).catch(function (error) {
        console.log('axios to fetchKwtToken failed');
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('status code not 2xx');
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          console.log('B2');
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log('no response received');
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
      console.log(response);
      //  const response = await axios.get(`${baseURL}`, { headers: { Authorization: tokens.jwtAccessToken } });
      //  console.log('response', response);
      //  setPost(response.data);
      if (fetchedUser.Tier === tierPremium) {
        console.log(tierPremium);
      } else {
        console.log(tierRegular);
      }
      //
      //axios.get(`${baseURL}`, { headers: { Authorization: tokens.jwtAccessToken } }).then((response) => {
      //  // axios.get(`${baseURL}${userpoolid}/${username}`, { headers: { Authorization: jwtAccessToken } }).then((response) => {
      //  //  setPost(response);
      //  setPost(response.data);
      //});
      //
    })();
  }, []);
  */

  useEffect(() => {
    (async () => {
      const userCheckGroups = await FetchGroups();
      /*
        No need to block rendering of whole Talks component.
        After Talks get rendered, user sees cards. Clicking upon this
        caed should take user either to VideoCard or to PayPage and that is when result of
        FetchGroups is needed.
      */
      //  setIsloading(false);
      setGroups(userCheckGroups);
    })();
  }, []);

  /*
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  */

  return (
    <div>
      <Navbar />
      <div className="talks__zero">
        {/* console.log('PPuserCheckGroups:', userCheckGroups)} */} {/* empty */}
        {console.debug('PPgroups:', groups)} {/* proper */}
        <div
          className="talks__first"
          onClick={
          () => ((groups.isUsertierone)
            ? navigate('/videoCard', { state: { accessToken: groups.tokens.jwtAccessToken } })
            : navigate('/payForTier1'))
          }
        >
          <div className="img__container__first">
            { (!groups.isUsertierone)
              && (
              <div className="overlay__talks__first">
                <FontAwesomeIcon icon={faLock} />
              </div>
              )}
            <div className="overlay__rightbottom">20 min</div>
            <img src={images.CurvSortNavyText} />
          </div>
          <div>
            <h2>AA</h2>
          </div>
        </div>
        <div className="talks__second" onClick={() => navigate('/talkTwo')}>
          <div>
            <div className="img__container">
              { (!groups.isUsertierone)
                && (
                <div className="overlay__topleft">
                  <FontAwesomeIcon icon={faLock} />
                </div>
                )}
              <div className="overlay__rightbottom">20 min</div>
              <img src={images.CurvSortNavyText} />
            </div>
            <div>
              <h2>BB</h2>
            </div>
          </div>
          <div>
            <div className="img__container">
              <div className="overlay__rightbottom">20 min</div>
              <img src={images.CurvSortNavyText} />
            </div>
            <div>
              <h2>CC</h2>
            </div>
          </div>
        </div>
        <div className="talks__third">
          <div>
            <div className="img__container">
              <div className="overlay__rightbottom">20 min</div>
              <img src={images.CurvSortNavyText} />
            </div>
            <div>
              <h2>DD</h2>
            </div>
          </div>
          <div>
            <div className="img__container">
              <div className="overlay__rightbottom">20 min</div>
              <img src={images.CurvSortNavyText} />
            </div>
            <div>
              <h2>EE</h2>
            </div>
          </div>
        </div>
      </div>
      <SocialHandles />
    </div>
  );
};

export default Talks;
