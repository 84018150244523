import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp, faAngleDoubleRight, faBrain } from '@fortawesome/free-solid-svg-icons';

import { SubHeading } from '../../../components';
import { images } from '../../../constants';
import './GenAIHeader.css';
import '../Header.css';

//  eslint-disable-next-line arrow-body-style
const GenAI = () => {
  const navigate = useNavigate();
  return (
    // gap: '4rem' is extra here
    <div id="genai">
      {/* <div className="app__fillerForNavbar" /> */}
      <div className="app__headerSU app__wrapper section__padding" style={{ gap: '4rem' }}>
        <div className="app__wrapper_img">
          <img src={images.genAI} alt="header_img" />
        </div>

        <div className="app__wrapper_info">
          <SubHeading title="Generative AI, Foundation Models And Machine Learning" />
          <div className="swiftLogo">
            <FontAwesomeIcon className="swiftUILogoIcon" icon={faBrain} />
            <h1 className="app__header-h1">GenAI, ML</h1>
          </div>
          <p className="p__opensans" style={{ margin: '2rem 0' }}>GenAI technology mimics human brain for creation and innovation. It can create new content and ideas, including conversations, stories, images, videos and music. Machine Learning allows analysis and prediction of patterns for improved results.</p>
          <div className="app__header_buttons">
            {/* <div className="exploreTrack" onClick={() => navigate('/talks')}> */}
            <div className="exploreTrack2 tooltip__HR">
              <span className="tooltiptext__HR"> Available Dec2024 </span>
              {/* <div>Explore</div> */}
              <div className="exploreTrack">
                <div>Posts</div>
                <FontAwesomeIcon className="angleRightLogo" icon={faAngleDoubleRight} />
              </div>
            </div>
            <FontAwesomeIcon onClick={() => { navigate('/'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} className="angleUpLogo" icon={faAngleDoubleUp} />
            {/* <button type="button" onClick={() => navigate('/talks')} className="custom__button">Explore Track</button> */}
            {/* <button type="button" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="custom__button">Back to Top</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenAI;
