import React from 'react';
import './EPGenAIEdtech.css';

function EPGenAIEdtech() {
  return (
    <div className="genAI_1">
      <div className="genAI_2">
        <div className="genAI_3">
          Learning Intelligence
        </div>
        <div className="genAI_4">
          Generative AI, Machine Learning, Augmented reality
        </div>
        <div className="genAI_4">
          [Learning Intelligence is a separate module from EdTech platform. This require additional efforts, time and planning. It is shown here for those willing to pre-order and expedite.]
        </div>
      </div>
      <ul>
        <li> Adaptive and Personalized Learning </li>
        <li> Automating and assisting administrative tasks </li>
        <li> Content Generation </li>
        <li> Realising actual field and equipment experience </li>
        <li> Doubt clarifications</li>
        <li> Building intuition models</li>
        <li> Targeted learning path with measured metrics</li>
        <li> Establishing roots from uknown/weak zone into known/strong zone</li>
        <li> Revision and short time ramp up </li>
        <li> Improving accessibility across varied dimensions</li>
        <li> Rewarding the sharing with community </li>
        <li> Knowledge transfer in workforce</li>
      </ul>
      <div className="genAI_5" />
    </div>
  );
}

export default EPGenAIEdtech;
