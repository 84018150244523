import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './SocialHandles2.css';

const SocialHandles2 = () => (
  // <div className="social-container2__item3">
  <div style={{ margin: '3rem 0' }}>
    {/* eslint-disable jsx-a11y/control-has-associated-label */}
    <a target="_blank" rel="noreferrer" href="https://www.youtube.com/@curvsort" className="youtube social"> <FontAwesomeIcon icon={faYoutube} size="2x" /> </a>
    <a target="_blank" rel="noreferrer" href="https://www.twitter.com/CurvSort" className="twitter social"> <FontAwesomeIcon icon={faXTwitter} size="2x" /> </a>
    {/* eslint-enable jsx-a11y/control-has-associated-label */}
  </div>
);

const FindUs = () => {
  const navigate = useNavigate();
  return (
    <div id="contactus">
      <div className="app__headerFU app__wrapper section__padding" style={{ gap: '4rem' }}>
        {/* <div className="app__bg app__wrapper section__padding" id="contact"> */}
        <div className="app__wrapper_img">
          <img src={images.contactUs1} alt="finus_img" />
        </div>

        <div className="app__wrapper_info">
          <SubHeading title="Book an appointment or Drop an Email" />
          <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Contact Me</h1>
          <div className="app__wrapper-content">
            <p className="p__cormorant" style={{ color: '#DCCA87' }}>Office Hours</p>
            <p className="p__opensans">Mon - Fri: 10:00 am - 06:00 pm</p>
            <p className="p__opensans">Sat: 10:00 am - 12:00 pm</p>
            <p className="p__cormorant" style={{ color: '#DCCA87' }}>Office Address</p>
            <p className="p__opensans">CurvSort Pvt Ltd</p>
            <p className="p__opensans">203, Tower 3, Tata Promont, Banashankari III Stage, Bangalore South, Bangalore- 560085, Karnataka</p>
            <SocialHandles2 />
            {/*
            <div className="social-container2">
              <h4 className="p__cormorant social-container2__item1" style={{ color: '#DCCA87' }}>Follow us</h4>
              <div className="social-container2__item2" />
              <SocialHandles2 />
            </div>
            */}
          </div>

          <div className="buildWithUs_Container">
            <div className="buildWithUs">
              <div className="buildWithUs1"> Investor, Client, Builder can click BuildWithUs to reach our team.</div>
              <div className="buildWithUs2">
                <div className="exploreTrack" onClick={() => navigate('/reachUsForm')}>
                  <div> BuildWithUs </div>
                  {/* <FontAwesomeIcon className="envelopeLogo" icon={faEnvelope} /> */}
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </div>
              </div>
            </div>
            <div className="buildWithUs3">
              <FontAwesomeIcon onClick={() => { navigate('/'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} className="angleUpLogo" icon={faAngleDoubleUp} />
              {/* <button type="button" className="custom__button">Email Us</button> */}
              {/* <button type="button" className="custom__button" style={{ marginTop: '2rem' }} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>Back to Top</button> */}
            </div>
          </div>

          <div className="policyContainer">
            <div className="policyTrack" onClick={() => navigate('/privacyPolicy')}> Privacy Policy </div>
            <div className="policyTrack" onClick={() => navigate('/customerSupport')}> Customer Support </div>
            <div className="policyTrack" onClick={() => navigate('/cancellationPolicy')}> Refund and Cancellation Policy </div>
            <div className="policyTrack" onClick={() => navigate('/termsAndConditions')}> Terms and Conditions </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default FindUs;
