import React from 'react';
import './LessonSettings.css';
import CStudentComments from './AddContent/ContentCatalogue/CatalogueItems/Modals/Curriculum/CStudentComments';
import CPublicPreview from './AddContent/ContentCatalogue/CatalogueItems/Modals/Curriculum/CPublicPreview';
import CDeleteLesson from './AddContent/ContentCatalogue/CatalogueItems/Modals/Curriculum/CDeleteLesson';

function LessonSettings(props) {
  const { clCardItem, setClCardItem } = props;
  console.log(clCardItem, setClCardItem);

  return (
    <div className="LS">
      <div className="A1_LS">
        Lesson Settings
      </div>
      <div className="A4_LS">
        <div className="A2_LS">
          <div className="A21_LS NotYetAvailable"> {/* TODO: clicking here brings up modal, but PublicPreview stays in fron. Check zIndex or Portal. */}
            <CStudentComments />
          </div>
          <div className="A22_LS NotYetAvailable">
            <CPublicPreview />
          </div>
        </div>
        <div className="A3_LS">
          <CDeleteLesson />
        </div>
      </div>
    </div>
  );
}

export default LessonSettings;
