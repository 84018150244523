import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modals/Modal';
import TextAndImagesModal from './Modals/TextAndImages/TextAndImagesModal';
import './TextAndImages.css';

function TextAndImages({ curriculum, setCurriculum, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="TI" onClick={() => setIsOpen(true)}>
        <div className="TI_A1">
          <FontAwesomeIcon icon={faAlignJustify} />
        </div>
        <div className="TI_A2">
          Text & Images
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <TextAndImagesModal curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
      </Modal>
    </div>
  );
}

export default TextAndImages;
