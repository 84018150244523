import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TextAndImagesList.css';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modals/Modal';
import QuizModal from './Modals/QuizModal';

function QuizList() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="CITLST NotYetAvailable" onClick={() => setIsOpen(true)}>
        <div className="CITLST_A1">
          <FontAwesomeIcon icon={faListCheck} />
        </div>
        <div className="CITLST_A2">
          Quiz
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <QuizModal />
      </Modal>
    </div>
  );
}

export default QuizList;
