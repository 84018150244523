/*  eslint-disable no-unused-vars */
import React from 'react';
import TextAndImageListItem from './TextAndImageListItem';

function TextAndImagesListModal({ curriculum, setCurriculum, clCardItem, setClCardItem, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  console.log('TextAndImagesListModal', lessonParent, curriculum);
  const sectionIndex = lessonParent.SectionIndex;
  const lessonIndex = lessonParent.LessonIndex;
  const data = curriculum.sectionList[sectionIndex].lessonList[lessonIndex].assets.richText;

  if (data.length === 0) {
    return (
      <div className="VLMOD_1">
        <h1>Asset List: RichText</h1>
        <div>
          No items
        </div>
      </div>
    );
  }

  return (
    <div className="VLMOD_1">
      <h1>Asset List: RichText</h1>
      <ul>
        { data.map((item) => <TextAndImageListItem name={item.richTextName} id={item.richTextID} />) }
      </ul>
    </div>
  );
}

export default TextAndImagesListModal;
/*  eslint-enable no-unused-vars */
