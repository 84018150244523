import React from 'react';
//  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//  import { faMinus } from '@fortawesome/free-solid-svg-icons';

//  import { images } from '../../constants';

const SubHeading = ({ title }) => (
  <div style={{ marginBottom: '1rem' }}>
    <p className="p__cormorant">{title}</p>
    {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
    {/* <FontAwesomeIcon fontSize="10px" color="var(--color-golden)" icon={faMinus} /> */}
  </div>
);

export default SubHeading;
