import React from 'react';
import './Panel.css';
import Catalogue from './Catalogue';
import Preview from './Preview';

function Panel(props) {
  const { curriculum, setCurriculum, panel, clCardItem, setClCardItem, lessonParent, isUploadingViaApi, setIsUploadingViaApi } = props;

  if (panel === 'Catalogue') {
    return (
      <Catalogue curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
    );
  }

  return (
    <Preview curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
  );
}

export default Panel;
