/*  eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import CourseCurriculum from '../../../../../../../../components/BackendServices/CourseCurriculum';
import './EPInformation.css';
import AudioContentModal from '../../CreateLesson/AddContent/ContentCatalogue/CatalogueItems/Modals/AudioContentModal';

function EPInformation(props) {
  const { page, setPage, setS1Done, setS2Done, setS3Done, setS4Done, curriculum, setCurriculum,
    lessonParent, setLessonParent, isUploadingViaApi, setIsUploadingViaApi } = props;
  const initialCourseName = 'New Course Name';
  const [nameWritten, setNameWritten] = useState(initialCourseName); // 'save' clicked without changing default course name

  const handleCourseNameChange = (e) => {
    console.log(e.target.value);
    setNameWritten(e.target.value);
  };
  const handleCancel = async () => {
    console.log('handleCancel');
    if (curriculum.isCourseNameSaved) {
      const updatedCurriculum = curriculum;
      //  curriculum.courseName is unchanged
      updatedCurriculum.isCourseNameSaved = false;
      setIsUploadingViaApi(true);
      const resp = await CourseCurriculum({ curriculum: updatedCurriculum, action: 'RESET_COURSE_NAME' }); // only rename happens, not complete delete of object
      if (resp.status !== 200) {
        console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
      }
      updatedCurriculum.courseID = resp.data.courseID;
      setIsUploadingViaApi(false);
      await setCurriculum(updatedCurriculum);
      setNameWritten(curriculum.courseName);
      setS1Done(false);
      console.log('curriculum:', updatedCurriculum);
    }
  };
  const handleSave = async () => {
    console.log('handleSave', curriculum);
    if (!curriculum.isCourseNameSaved) {
      const updatedCurriculum = curriculum;
      updatedCurriculum.courseName = nameWritten;
      updatedCurriculum.isCourseNameSaved = true;
      setIsUploadingViaApi(true);
      const resp = await CourseCurriculum({ curriculum: updatedCurriculum, action: 'SET_COURSE_NAME' }); // this becomes course being edited in backend/lambda/ddb
      if (resp.status !== 200) {
        console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
      }
      updatedCurriculum.courseID = resp.data.courseID;

      setIsUploadingViaApi(false);
      setCurriculum(updatedCurriculum);
      setS1Done(true);
      console.log('curriculum:', updatedCurriculum);
    }
  };
  const handleNew = async () => {
    console.log('handleNew');
    await setCurriculum({}, false, true);
  };
  const handleCatalogue = async () => {
    console.log('handleCatalogue');
    setPage('publishedCourses');
  };

  function goAddCurriculum() {
    console.log('goAddCurriculum');
    setPage('edTech');
  }

  function CourseName() {
    if (curriculum.isCourseNameSaved) {
      return (
        <div className="EPI_A3_2_2">
          {curriculum.courseName}
        </div>
      );
    }
    return (
      <input
        name="lesson"
        type="text"
        id="lesson"
        className="EPI_A3_2"
        autoComplete="off"
        autoCorrect="off"
        required
        defaultValue={curriculum.courseName}
        onChange={(e) => handleCourseNameChange(e)}
      />
    );
  }

  return (
    <div className="EPI">
      <div style={{ margin: '5px', padding: '5px', cursor: 'pointer', backgroundColor: 'rgba(235, 106, 205, 0.5)', border: '1px solid rgb(235, 106, 205)' }} onClick={() => setPage('genaiLearning')}>
        Learning Intelligence (genAI, ML, AR)
      </div>

      <div className="EPI_A23" onClick={goAddCurriculum}>
        <div className="EPI_A24">
          Add Curriculum
        </div>
        <div className="EPI_A25">
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </div>
      </div>

      <div className="EPI_A1">
        Name your course
      </div>

      <div className="EPI_A5">
        What would you like to name the course? This can be changed later if you require.
      </div>
      <div className="EPI_A6">
        Note: Only one admin can update the course curriculum. If you wish to have an admin team operating on curriculum updates, contact us.
      </div>
      <div className="EPI_A3">
        <div className="EPI_A3_1">
          Course Title
        </div>
        {CourseName()}
      </div>
      <div className="EPI_A3">
        <div className="EPI_A3_2_4">
          Institute
        </div>
        <div className="EPI_A3_2_3">
          {curriculum.instituteName}
        </div>
      </div>
      <div className="EPI_A5_1">
        { !curriculum.isInstituteNameSaved
          && (
          <div className="EPI_A4_11">
            <div className="EPI_A4_10">
              Institute Name is to be saved first. That can be renamed later if required.
            </div>
            <div className="EPI_A11">
              <button type="button" className="EPI_A4_6">
                <span>Unavailable</span>
              </button>
              <button type="button" className="EPI_A4_6">
                <span>Unavailable</span>
              </button>
            </div>
          </div>
          )}
        { ((curriculum.isInstituteNameSaved) && isUploadingViaApi)
          && (
          <div className="EPI_A4">
            <button type="button" className="EPI_A4_5">
              <span>Processing..</span>
            </button>
            <button type="button" className="EPI_A4_5">
              <span>Processing...</span>
            </button>
          </div>
          )}
        { ((curriculum.isInstituteNameSaved) && !isUploadingViaApi && !curriculum.isCourseNameSaved)
          && (
          <div className="EPI_A4">
            <button type="button" className="EPI_A4_1" onClick={() => handleCatalogue()}>
              <span>Catalogue</span>
            </button>
            <button type="button" className="EPI_A4_2" onClick={() => handleSave()}>
              <span>Save</span>
            </button>
            <button type="button" className="EPI_A4_12" onClick={() => handleNew()}>
              <span>New</span>
            </button>
          </div>
          )}
        { ((curriculum.isInstituteNameSaved) && !isUploadingViaApi && curriculum.isCourseNameSaved)
          && (
          <div className="EPI_A4">
            <button type="button" className="EPI_A4_4" onClick={() => handleCancel()}>
              <span>Rename</span>
            </button>
            <button type="button" className="EPI_A4_3">
              <span>Saved</span>
            </button>
            <button type="button" className="EPI_A4_12" onClick={() => handleNew()}>
              <span>New</span>
            </button>
          </div>
          )}
      </div>
    </div>
  );
}

export default EPInformation;
/*  eslint-enable no-unused-vars */
