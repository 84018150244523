/*  eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './EPInstitute.css';
import CourseCurriculum from '../../../../../../../../components/BackendServices/CourseCurriculum';

function EPInstitute(props) {
  const { page, setPage, setS1Done, setS2Done, setS3Done, setS4Done, curriculum, setCurriculum, initialCurriculum, isUploadingViaApi, setIsUploadingViaApi } = props;
  const initialInstituteName = 'New Institute Name';
  const [nameWritten, setNameWritten] = useState(initialInstituteName);

  /*
  useEffect(() => {
    (async () => {
      setIsUploadingViaApi(true);
      const resp = await CourseCurriculum({ action: 'GET_INSTITUTE' });
      console.log('EPInstitute CourseCurriculum GET_INSTITUTE', resp);
      console.log('resp: ', resp.data.backendResponse);
      console.log('count: ', resp.data.backendResponse.Count);
      if (resp.status !== 200) {
        console.log('EPInstitute CourseCurriculum GET_INSTITUTE failed with status code:', resp.status);
      }
      if (resp.data.backendResponse.Items.length === 0) {
        // Institute is not yet created, this is first time.
        const updatedCurriculum = initialCurriculum; // clear any dangling data in local object memory
        updatedCurriculum.instituteName = initialInstituteName;
        updatedCurriculum.isInstituteNameSaved = false;
        updatedCurriculum.instituteID = '';
        await setCurriculum(updatedCurriculum);
        console.log('updatedCurriculum', updatedCurriculum);
      } else {
        const updatedCurriculum = curriculum;
        updatedCurriculum.instituteName = resp.data.backendResponse.Items[0].Name.slice('INSTITUTE_NAME#'.length);
        updatedCurriculum.isInstituteNameSaved = resp.data.backendResponse.Items[0].IsSavedByPublisher;
        updatedCurriculum.instituteID = resp.data.backendResponse.Items[0].ID.slice('INSTITUTE_ID#'.length);
        console.log('Items: ', resp.data.backendResponse.Items[0]);
        await setCurriculum(updatedCurriculum);
        console.log('updatedCurriculum', updatedCurriculum);
      }
      setIsUploadingViaApi(false);
    })();
  }, []);
  */

  const handleInstituteNameChange = (e) => {
    console.log(e.target.value);
    setNameWritten(e.target.value);
  };

  const handleCancel = async () => {
    console.log('handleCancel');
    if (curriculum.isInstituteNameSaved) {
      const updatedCurriculum = curriculum;
      //  instituteName is unchanged and not reset back to 'New Institute Name';
      updatedCurriculum.isInstituteNameSaved = false;
      setIsUploadingViaApi(true);
      const resp = await CourseCurriculum({ curriculum: updatedCurriculum, action: 'RESET_INSTITUTE_NAME' }); // only rename happens, not complete delete of object
      if (resp.status !== 200) {
        console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
      }
      updatedCurriculum.instituteID = resp.data.instituteID;

      setIsUploadingViaApi(false);
      await setCurriculum(updatedCurriculum);
      setNameWritten(curriculum.instituteName);
    }
  };

  const handleSave = async () => {
    console.log('handleSave');
    if (!curriculum.isInstituteNameSaved) {
      setIsUploadingViaApi(true);
      const updatedCurriculum = curriculum;
      updatedCurriculum.instituteName = nameWritten;
      updatedCurriculum.isInstituteNameSaved = true;
      const resp = await CourseCurriculum({ curriculum: updatedCurriculum, action: 'SET_INSTITUTE_NAME' }); // only rename happens, not complete delete of object
      if (resp.status !== 200) {
        console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
      }
      updatedCurriculum.instituteID = resp.data.instituteID;
      setIsUploadingViaApi(false);
      await setCurriculum(updatedCurriculum);
    }
  };

  function InstituteName() {
    if (curriculum.isInstituteNameSaved) {
      return (
        <div className="EPPR_A3_2_2">
          {curriculum.instituteName}
        </div>
      );
    }

    return (
      <input
        name="instituteNameAdded"
        type="text"
        id="instituteNameAdded"
        className="EPINST_A3_2"
        autoComplete="off"
        autoCorrect="off"
        required
        defaultValue={curriculum.instituteName}
        onChange={(e) => handleInstituteNameChange(e)}
      />
    );
  }

  return (
    <div className="EPINST">

      <div className="EPINST_A1">
        Name your Institute
      </div>

      <div className="EPINST_A2">
        Choose a suitable name that relates to your offered courses. You can rename it later.
      </div>
      <div className="EPINST_A3">
        <div className="EPINST_A3_1">
          Institute Name
        </div>
        {InstituteName()}
      </div>
      { isUploadingViaApi
        && (
        <div className="EPPUB_A4">
          <button type="button" className="EPPUB_A4_5">
            <span>Processing..</span>
          </button>
          <button type="button" className="EPPUB_A4_5">
            <span>Processing...</span>
          </button>
        </div>
        )}
      { (!isUploadingViaApi && !curriculum.isInstituteNameSaved)
        && (
        <div className="EPINST_A4">
          <button type="button" className="EPINST_A4_1" onClick={() => handleCancel()}>
            <span>Cancel</span>
          </button>
          <button type="button" className="EPINST_A4_2" onClick={() => handleSave()}>
            <span>Save</span>
          </button>
        </div>
        )}
      { (!isUploadingViaApi && curriculum.isInstituteNameSaved)
        && (
        <div className="EPINST_A4">
          <button type="button" className="EPINST_A4_3" onClick={() => handleCancel()}>
            <span>Rename</span>
          </button>
          <button type="button" className="EPINST_A4_4">
            <span>Saved</span>
          </button>
        </div>
        )}
    </div>
  );
}

export default EPInstitute;
