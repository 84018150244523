import React from 'react';

const baseurl = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/newsletter/nname/send';
const subject = 'This is subject line';
const htmlBody = 'This is html body';

//  eslint-disable-next-line arrow-body-style
const Newsletter = () => {
  /*
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(event.target.subject.value);
    console.log(event.target.body.value);
  };
  */

  return (
    <div style={{ width: '500px', height: '5000px' }}>
      <form target="_blank" action={baseurl} method="post">
        <input type="text" name="subject" autoComplete="off" autoCorrect="off" placeholder={subject} style={{ width: '400px' }} />
        <input type="submit" value="Submit" />
        <br />
        <textarea rows="30" cols="60" name="htmlBody" placeholder={htmlBody} />
      </form>
    </div>
  );
};

export default Newsletter;
