/*  eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modals/Modal';
import VideoModal from './Modals/VideoModal/VideoModal';
import './TextAndImages.css';

function VideoContent({ curriculum, setCurriculum, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="TI" onClick={() => setIsOpen(true)}>
        <div className="TI_A1">
          <FontAwesomeIcon icon={faCirclePlay} />
        </div>
        <div className="TI_A2">
          Video
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <VideoModal lessonParent={lessonParent} curriculum={curriculum} setCurriculum={setCurriculum} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
      </Modal>
    </div>
  );
}

export default VideoContent;
/*  eslint-enable no-unused-vars */
