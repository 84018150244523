import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { images } from '../../../../constants';
import './Prod.css';

function Prod2(props) {
  const { groups } = props;
  const navigate = useNavigate();

  return (
    <div style={{ display: 'flex' }}>
      <div className="space_1" />
      <div style={{ flex: '5', display: 'flex', alignItems: 'center', height: '600px' }}>
        <div className="talks_first__PL">
          <div
            style={{ border: '2px solid blue' }}
            className="img__container__first__PL"
            onClick={
              () => ((groups.isUsertierone)
                ? navigate('/edTech', { state: { accessToken: groups.tokens.jwtAccessToken } })
                : navigate('/payForTier1'))
              }
          >
            { (!groups.isUsertierone)
            && (
            <div className="overlay__talks_first__PL">
              <FontAwesomeIcon icon={faLock} />
            </div>
            )}
            <div className="overlay__rightbottom__PL">$70</div>
            <img src={images.AArt2} />
          </div>
          <div>
            <div className="one__PL">
              <h2 className="textOne__PL"> EdTech platform</h2>
              <h4 className="textTwo__PL">Host your courses to wider audience and reach the world of learners. Distributed in SaaS setup across different users.</h4>
              {/* <h4 className="textTwo__PL" style={{ color: '#873600' }}>Host your paid content with us in SaaS setup. Content can be online courses, art and more.</h4> */}
              <br />
              <div className="one_one__PL">
                <div className="one_one_one__PL">
                  <h6 className="tagSA__PL"> Serverless Architectures </h6>
                  <h6 className="tagRWA__PL"> ReactJS WebApp </h6>
                  <h6 className="tagBUY__PL"> Solution is available to Buy for $70 + Tax.</h6>
                  <h6 className="tagBUY__PL"> Quickly ship your own brand online. </h6>
                </div>
                <div className="one_one_two__PL tagVD__PL">
                  <h6> VD122, August 2024</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space_1" />
    </div>
  );
}

export default Prod2;
