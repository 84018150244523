import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import images from '../../constants/images';
import './ReachUsForm.css';
import BuildWithUsBS from '../../components/BackendServices/BuildWithUsBS';

function TryForm() {
  const initialValues = { bemail: '', fname: '', lname: '', cname: '', phone: '', country: '', industry: '', message: '', category: '' };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regex.test(values.bemail)) {
      errors.bemail = 'This is not a valid email format!';
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    (async () => {
      console.debug(formErrors);
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        console.debug(formValues);
        await BuildWithUsBS(formValues);
      }
    })();
  //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  return (
    <div className="fContainer_A1">
      {Object.keys(formErrors).length === 0 && isSubmit ? (
        <div>
          <div className="ui message success" style={{ color: 'green' }}>Has been submitted successfully.</div>
          <br />
          <br />
        </div>
      ) : (
        //  <pre>{JSON.stringify(formValues, undefined, 2)}</pre>
        <div />
      )}

      <form onSubmit={handleSubmit} className="RUF_A2">
        <h1 className="RUF_A1">Request a review</h1>
        <div className="ui divider" />
        <div className="fContainer">
          <div className="RUF_field">
            {/* <label htmlFor="bemail" style={{ border: '2px solid blue', display: 'inline-block', width: '200px', textAlign: 'left' }}>Busines Email Address *</label> */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="fLabel" htmlFor="bemail">Busines Email Address *</label>
            <input
              type="text"
              name="bemail"
              placeholder="Business Email Address"
              value={formValues.bemail}
              onChange={handleChange}
              required
              maxLength="20"
              id="bemail"
            />
          </div>
          <p>{formErrors.bemail}</p>
          <div className="RUF_field">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="fLabel" htmlFor="fname"> First Name *</label>
            <input
              type="text"
              name="fname"
              placeholder="First Name"
              value={formValues.fname}
              onChange={handleChange}
              required
              maxLength="20"
              id="fname"
            />
          </div>
          <p>{formErrors.fname}</p>
          <div className="RUF_field">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="fLabel" htmlFor="lname"> Last Name *</label>
            <input
              type="text"
              name="lname"
              placeholder="Last Name"
              value={formValues.lname}
              onChange={handleChange}
              required
              maxLength="20"
              id="lname"
            />
          </div>
          <p>{formErrors.lname}</p>
          <div className="RUF_field">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="fLabel" htmlFor="cname"> Company Name *</label>
            <input
              type="text"
              name="cname"
              placeholder="Company Name"
              value={formValues.cname}
              onChange={handleChange}
              required
              maxLength="20"
              id="cname"
            />
          </div>
          <p>{formErrors.cname}</p>
          <div className="RUF_field">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="fLabel" htmlFor="phone"> Phone No * </label>
            <input
              type="text"
              name="phone"
              placeholder="Phone No"
              value={formValues.phone}
              onChange={handleChange}
              required
              maxLength="20"
              id="phone"
            />
          </div>
          <p>{formErrors.phone}</p>
          <div className="RUF_field">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="fLabel" htmlFor="country"> Country * </label>
            <input
              type="text"
              name="country"
              placeholder="Country"
              value={formValues.country}
              onChange={handleChange}
              required
              maxLength="20"
              id="country"
            />
          </div>
          <p>{formErrors.country}</p>
          <div className="RUF_field">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="fLabel" htmlFor="industry"> Industry * </label>
            <input
              type="text"
              name="industry"
              placeholder="Industry"
              value={formValues.industry}
              onChange={handleChange}
              required
              maxLength="20"
              id="industry"
            />
          </div>
          <p>{formErrors.industry}</p>
          <div className="RUF_A3">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="fLabel" htmlFor="industry"> Category * </label>
            <div className="fFieldRadioRowContainer">
              <div className="fFieldRadioRow">
                <input
                  type="radio"
                  name="category"
                  value="Client"
                  id="category"
                  className="fFieldRadio"
                  required
                />
                Client
              </div>
              <br />
              <div className="fFieldRadioRow">
                <input
                  type="radio"
                  name="category"
                  value="Investor"
                  id="category"
                  className="fFieldRadio"
                />
                Investor
              </div>
              <br />
              <div className="fFieldRadioRow">
                <input
                  type="radio"
                  name="category"
                  value="Other"
                  id="category"
                  className="fFieldRadio"
                />
                Other
              </div>
            </div>
          </div>
          <br />
          <p>{formErrors.category}</p>
          <div className="messageContainer">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="fLabel" htmlFor="message">Message</label>
            <textarea
              rows="7"
              cols="21"
              maxLength="250"
              name="message"
              placeholder="Message"
              value={formValues.message}
              onChange={handleChange}
            />
          </div>
          <p>{formErrors.message}</p>
          <button type="submit" className="fLabelSubmit">Submit</button>
          <div className="app__navbar-logo" onClick={() => navigate('/')}>
            <img src={images.logo100px} style={{ width: '30px', height: '30px' }} alt="app__logo" />
            <div>CurvSort</div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TryForm;
