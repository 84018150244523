import { Auth } from 'aws-amplify';
//  import { useState } from 'react';
import axios from 'axios';

/*
  Refresh flow, Cache miss flow :
    Invoke apis and fetch results from aws backend. Happens by InitiateAuth. Jwt access token
    is always updated in this case. Token is a self contained entity and hence the earlier
    and the newly baked tokens both will be valid until configured so.
    Cases:
    1. User pays on webapp and gets himself added into UserGroup1. Resources will be available to him.
    2. Signup is anyway going to have cache miss and will retrieve tokens from aws backend only.

  Cache hit flow :
    1. Component getting rendered again because of user traversing from one page to another.
    2. Existing user logs-in.
*/

const fetchUpdatedAccessToken = async (tokens) => {
  console.debug('fetchRefreshedAccessToken');
  const baseURL = `https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/user/${tokens.userpoolid}/${tokens.username}/refreshedAccessToken`;
  const response = await axios.get(baseURL, {
    headers: {
      //  "Content-Type": "text/json",
      // Authorization: `Bearer ${tokens.jwtAccessToken}`,
      Authorization: `${tokens.jwtIdToken}`,
    },
    params: {
      clientId: tokens.clientid,
      refreshToken: tokens.refToken,
    },
  })
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.data.message === 'The incoming token has expired') {
          console.log('%cDisplay alert box: The incoming token has expired. User should signout and signin again', 'background: green', 'color: white;', 'CAPSCODE');
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  console.debug('axios.get() refreshedToken response: ', response);

  if ((response !== undefined) && (response.data !== undefined)) {
    return response.data.AccessToken;
  }
  return '';
};

//  eslint-disable-next-line no-unused-vars
const FetchJwtToken2 = async () => {
  console.debug('FetchJwtToken2');
  let tokens = {
    jwtAccessToken: '',
    jwtIdToken: '',
    username: '',
    userpoolid: '',
    clientid: '',
    refToken: '',
    useremail: '',
  };
  try {
    const session1 = await Auth.currentSession();
    const session2 = await Auth.currentAuthenticatedUser(); //  T14145237221
    console.debug('session1', session1);
    console.debug('session2', session2);
    /*
    if (session2.isValid) {
      console.log('session2 isValid');
    } else {
      console.log('! session2 isValid');
    }
    if (session.isValid) {
      console.log('session isValid');
    } else {
      console.log('! session isValid');
    }
    if (session.isValid()) {
    */
    // if ((session2.session !== undefined) && (session2.session !== '')) {
    // console.debug('session2.session', session2.session); // session2.session could be undefined
    const accessToken = session1.getAccessToken().getJwtToken();
    const idToken = session1.getIdToken().getJwtToken();
    const refreshToken = session1.getRefreshToken().getToken();
    const userInfo = await Auth.currentUserInfo();
    const authUser = await Auth.currentAuthenticatedUser();
    const uemail = session1.getIdToken().payload.email;
    tokens = {
      jwtAccessToken: accessToken,
      jwtIdToken: idToken,
      username: userInfo.username,
      userpoolid: authUser.pool.userPoolId,
      clientid: authUser.pool.clientId,
      refToken: refreshToken,
      freshAccessToken: '',
      useremail: uemail,
    };
    console.debug('tokens:', tokens);
    /*
    //  If user pays then backend adds him to UserGroup1. This membership is not reflected
    //  i existing jwtAccessToken until user signsout and relogin. To avoid that need
    //  fetch the updated access token and if they are different, use new one going forward.
    */
    const freshAccessToken = await fetchUpdatedAccessToken(tokens);
    tokens.freshAccessToken = freshAccessToken;
    if (tokens.freshAccessToken !== tokens.jwtAccessToken) {
      console.debug('existingAccessToken: ', tokens.jwtAccessToken);
      console.debug('freshAccessToken: ', tokens.freshAccessToken);
      console.debug('freshAccessToken is different. Taking this and moving forward.');
      tokens.jwtAccessToken = tokens.freshAccessToken;
      //  setJwtAccessToken(freshAccessToken);
    }
  } catch (error) {
    //  setAmplifyUser('Login');
    console.debug('Error fetching JWT token:', error);
    if (error === 'No current user') {
      console.log('%cDisplay alert box: User should signout and signin again', 'background: green', 'color: white;', 'CAPSCODE');
    }
  }
  return tokens;
};

let tokens = {};
//  eslint-disable-next-line no-unused-vars
const FetchJwtToken = async (fresh = false) => {
  console.debug('FetchJwtToken', 'fresh:', fresh, ', ExistingTokens:', tokens);
  if ((fresh)
    || (!Object.hasOwnProperty.call(tokens, 'username'))
    || (tokens.username === '')) {
    const toks = await FetchJwtToken2();
    //  setTokens(toks); // conditional call for hook not allowed
    console.debug('calling rest api to fetch fresh tokens. Old:', tokens, ', New:', toks);
    tokens = toks;
  } else {
    console.debug('skipping to call rest api fresh');
  }
  return tokens;
};

export default FetchJwtToken;
