import React from 'react';
import YetToBeImplemented from './YetToBeImplemented/YetToBeImplemented';

function QuizModal() {
  return (
    <div>
      <YetToBeImplemented />
    </div>
  );
}

export default QuizModal;
