import images from './images';

const workshops = [
  {
    imgUrl: images.A1,
    title: 'Hands On',
    subtitle: '16 participants to 1 instructor ratio for ensuring enough hands-on time to address all the questions that might arise.',
  },
  {
    imgUrl: images.A2,
    title: 'Format',
    subtitle: 'Online via Zoom with 16 participants to 1 instructor ratio. 2 days duration.',
  },
  {
    imgUrl: images.A3,
    title: 'Exercises',
    subtitle: 'Best way to learn is to work with it yourself. You will work in pairs or small groups.',
  },
  {
    imgUrl: images.A4,
    title: 'Customization',
    subtitle: 'Each workshop is customized, so please get in touch for pricing details.',
  },
];

export default { workshops };
