import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import './SocialHandles.css';

const SocialHandles = () => (
  <div className="social-container">
    {/* eslint-disable jsx-a11y/control-has-associated-label */}
    <a target="_blank" rel="noreferrer" href="https://www.youtube.com/@curvsort" className="youtube social"> <FontAwesomeIcon icon={faYoutube} size="2x" /> </a>
    <a target="_blank" rel="noreferrer" href="https://www.twitter.com/CurvSort" className="twitter social"> <FontAwesomeIcon icon={faXTwitter} size="2x" /> </a>
    {/* eslint-enable jsx-a11y/control-has-associated-label */}
  </div>
);

export default SocialHandles;
