/*  eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './EPInProgressTable.css';
import Dropdown from '../../../components/Dropdown/Dropdown';
import DropdownItem from '../../../components/DropdownItem/DropdownItem';
import CourseCurriculum from '../../../../../../../../../components/BackendServices/CourseCurriculum';

function EPInProgressTable(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes, enrolledCoursesProgressingArray, setEnrolledCoursesProgressingArray } = props;
  function handleActions() {
    console.log('handle actions');
  }

  async function handleEnterClassRoom(courseItem, index) {
    console.log('handleEnterClassRoom', courseItem, index, courseItem.CourseID.slice('COURSE_ID#'.length));
    setIsUploadingViaApi(true); // during download, no upload should corrupt the data
    const resp = await CourseCurriculum({ courseID: courseItem.CourseID.slice('COURSE_ID#'.length), action: 'ENTER_CLASSROOM' });
    setIsUploadingViaApi(false);
    if (resp.status !== 200) {
      console.log('ENTER_CLASSROOM api failed. Take custom action.');
    }
    setEnrolledCurriculum(resp.data.Item.CourseCurriculum);
    const resp2 = await CourseCurriculum({ courseID: courseItem.CourseID.slice('COURSE_ID#'.length), action: 'ENROLLMENT_GET_ATTRIBUTES' });
    if (resp2.status !== 200) {
      console.log('ENROLLMENT_GET_ATTRIBUTES failed. Custom error handling', resp2.status);
    }
    const ll2 = resp2.data.backendResponse;
    console.log('ll2: ', ll2);
    setEnrolledAttributes(resp2.data.backendResponse.Items[0]);
    console.log('ENTER_CLASSROOM', resp);
    setPage('classroomEnroll');
  }
  async function handleLeaveClassRoom(courseItem, index) {
    console.log('handleLeaveClassRoom', courseItem, index);
    setIsUploadingViaApi(true);
    const resp = await CourseCurriculum({ courseID: courseItem.CourseID.slice('COURSE_ID#'.length), action: 'LEAVE_CLASSROOM' });
    setIsUploadingViaApi(false);
    setEnrolledCurriculum();
    console.log('LEAVE_CLASSROOM', resp);
  }
  async function handleWithdrawEnrollment(courseItem, index) {
    console.log('handleWithdrawEnrollment', courseItem, index);
    setIsUploadingViaApi(true);
    const resp = await CourseCurriculum({ courseID: courseItem.CourseID.slice('COURSE_ID#'.length), action: 'WITHDRAW_ENROLLMENT' });
    console.log('WITHDRAW_ENROLLMENT', resp);
    const resp3 = await CourseCurriculum({ action: 'ENROLLMENT_GET_COURSES' });
    console.log('ENROLLMENT_GET_COURSES', resp3);
    if (resp3.status !== 200) {
      console.log('ENROLLMENT_GET_COURSES failed. Custom error handling', resp3.status);
    }
    const ll = resp3.data.backendResponse.Items.filter((item) => !item.IsCourseDone);
    setEnrolledCoursesProgressingArray(ll);
    console.log('ENROLLMENT_GET_COURSES inProgress ', ll);
    setIsUploadingViaApi(false);
  }

  const items = [
    { name: 'EnterClassRoom', handler: handleEnterClassRoom },
    { name: 'LeaveClassRoom', handler: handleLeaveClassRoom },
    { name: 'WithdrawEnrollment', handler: handleWithdrawEnrollment },
  ];

  return (
    <div className="EPPCTBL">
      {
        enrolledCoursesProgressingArray.map((courseItem, index) => {
          const { IsCourseDone, SavedDate, EnrolledCourse } = courseItem;
          const { courseAttributes, doneStatus, userCursor } = EnrolledCourse;
          const { courseCurrency, coursePrice, courseName, instituteName } = courseAttributes;
          const { lesson, section } = userCursor;
          return (
            <tr style={{ display: 'flex' }}>
              <td>{courseName}</td>
              <td>{coursePrice}{courseCurrency}</td>
              <td>{SavedDate}</td>
              <td>{IsCourseDone ? 'Completed' : 'InProgress'}</td>
              <td>{section}.{lesson}</td>
              <td>{instituteName}</td>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              {/* <td className="handleClick" onClick={handleActions}><FontAwesomeIcon icon={faBars} /></td> */}
              <td className="more_val">
                <button type="button" className="save-btn">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <Dropdown
                    buttonText={<FontAwesomeIcon icon={faBars} />}
                    content={(
                      <>
                        {items.map((item, id) => (
                          <DropdownItem key={id} onClick={() => item.handler(courseItem, index)}>
                            {`${item.name}`}
                          </DropdownItem>
                        ))}
                      </>
                    )}
                  />
                </button>
              </td>
            </tr>
          );
        })
      }
    </div>
  );
}

export default EPInProgressTable;
/*  eslint-enable no-unused-vars */
