import { React, useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import images from '../../constants/images';
import LoginOverlay from '../Overlay/Overlay';
import './Navbar.css';
import Login2 from '../Login/Login2';
import FetchGroups2 from '../BackendServices/FetchGroups2';

const Navbar = () => {
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  //  eslint-disable-next-line no-unused-vars
  const [isLoading, setIsloading] = useState(true);
  //  eslint-disable-next-line no-unused-vars
  const [groups, setGroups] = useState({});
  const toggleLoginOverlay = () => {
    setIsLoginOpen(!isLoginOpen);
  };
  const [user, setUser] = useState('Login');
  const setAmplifyUser = (amplifyuser) => {
    console.debug('setAmplifyUser', amplifyuser);
    setUser(amplifyuser);
  };

  /*
  const fetchUpdatedAccessToken = async (tokens) => {
    console.log('fetchRefreshedAccessToken');
    const baseURL = `https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/user/${tokens.userpoolid}/${tokens.username}/refreshedAccessToken`;
    const response = await axios.get(baseURL, {
      headers: {
        //  "Content-Type": "text/json",
        Authorization: `Bearer ${tokens.jwtAccessToken}`,
      },
      params: {
        clientId: tokens.clientid,
        refreshToken: tokens.refToken,
      },
    })
    //  eslint-disable-next-line prefer-arrow-callback
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    console.log('axios.get() refreshedToken response: ', response);

    if ((response !== undefined) && (response.data !== undefined)) {
      return response.data.AccessToken;
    }
    return '';
  };

  const fetchJwtToken = async () => {
    let tokens = {
      jwtAccessToken: '',
      jwtIdToken: '',
      username: '',
      userpoolid: '',
      clientid: '',
      refToken: '',
    };
    try {
      const session1 = await Auth.currentSession();
      const session2 = await Auth.currentAuthenticatedUser(); //  T14145237221
      console.log('session1', session1);
      console.log('session2', session2);
      console.log('session2.session', session2.session);
      //
      //if (session2.isValid) {
      //  console.log('session2 isValid');
      //} else {
      //  console.log('! session2 isValid');
      //}
      //if (session.isValid) {
      //  console.log('session isValid');
      //} else {
      //  console.log('! session isValid');
      //}
      //if (session.isValid()) {
      //
      if (session2.session !== '') {
        console.log('setUser', session1.getIdToken().payload.email.split('@')[0]);
        setUser(session1.getIdToken().payload.email.split('@')[0]);
        const accessToken = session1.getAccessToken().getJwtToken();
        const jwtIDToken = session1.getIdToken().getJwtToken();
        const refreshToken = session1.getRefreshToken().getToken();
        const userInfo = await Auth.currentUserInfo();
        const authUser = await Auth.currentAuthenticatedUser();
        setJwtAccessToken(accessToken);
        console.log('Access jwtToken', jwtAccessToken);
        console.log('ID jwtToken', jwtIDToken);
        tokens = {
          //  eslint-disable-next-line object-shorthand
          jwtAccessToken: jwtAccessToken,
          jwtIdToken: jwtIDToken,
          username: userInfo.username,
          userpoolid: authUser.pool.userPoolId,
          clientid: authUser.pool.clientId,
          refToken: refreshToken,
          freshAccessToken: '',
        };
        //
        //  If user pays then backend adds him to UserGroup1. This membership is not reflected
        //  i existing jwtAccessToken until user signsout and relogin. To avoid that need
        //  fetch the updated access token and if they are different, use new one going forward.
        //
        const freshAccessToken = await fetchUpdatedAccessToken(tokens);
        tokens.freshAccessToken = freshAccessToken;
        if (tokens.freshAccessToken !== tokens.jwtAccessToken) {
          console.log('freshAccessToken is different. Taking this and moving forward.');
          tokens.jwtAccessToken = tokens.freshAccessToken;
          setJwtAccessToken(freshAccessToken);
        }
      }
    } catch (error) {
      setAmplifyUser('Login');
      console.log('Error fetching JWT token:', error);
    }
  };
 */
  /*
  useEffect(() => {
    (async () => {
      const userCheckGroups = await FetchGroups();
      // No need to block rendering of whole Navbar component, just blocking name on Navbar is sufficient.
      //  setIsloading(false);
      //  console.log(isLoading);
      if (userCheckGroups.tokens.jwtAccessToken === '') {
        setAmplifyUser('Login');
      } else {
        //
          This gets set after FetchGroups is finished and name is available. Exactly what is
          needed. And no need to block rendering of whole Navbar till async FetchGroups finishes.
          FIxing the width would be good as it wont change the mutual positioning of words.
        //
        setUser(userCheckGroups.tokens.useremail.split('@')[0]);
      }
    })();
  }, []);
  */

  useEffect(() => {
    (async () => {
      await FetchGroups2(setAmplifyUser, setGroups, setIsloading, true); // fresh=true is required because after logout the name should stay same as Login and not emailid. After logout, Navbar is still mounted and useEffect is called.
    })();
  }, []);

  /* Not required to block rendering of whole page, as groups is not accessed but only jwtAccessToken
  //  Required because after logout, on default page, Navbar is still called up. Fetch
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  */

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo" onClick={() => navigate('/')}>
        <img src={images.logo100px} alt="app__logo" />
        <div>CurvSort</div>
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans_filler" />
        <li className="p__opensans"><HashLink to="/#products">Products</HashLink></li>
        <li className="p__opensans"><HashLink to="/#aws">AWS</HashLink></li>
        <li className="p__opensans"><HashLink to="/#genai">GenAI,ML</HashLink></li>
        {/* <li className="p__opensans"><HashLink to="/#swift">Swift</HashLink></li> */}
        <li className="p__opensans"><HashLink to="/#swift">AppleTech</HashLink></li>
        {/* <li className="p__opensans"><HashLink to="/#swiftui">SwiftUI</HashLink></li> */}
        {/* <li className="p__opensans"><HashLink to="/#visionpro">VisionPro</HashLink></li> */}
        {/* <li className="p__opensans"><HashLink to="/#workshops">Workshops</HashLink></li> */}
        <li className="p__opensans"><HashLink to="/#about">About</HashLink></li>
        {/* <li className="p__opensans"><HashLink to="/#careers">Careers</HashLink></li> */}
        <li className="p__opensans_filler" />
      </ul>
      {/*
      <ul className="app__navbar-links">
        <li className="p__opensans_filler" />
        <li className="p__opensans"><a href="/#swift">Swift</a></li>
        <li className="p__opensans"><a href="/#swiftui">SwiftUI</a></li>
        <li className="p__opensans"><a href="/#visionpro">VisionPro</a></li>
        <li className="p__opensans"><a href="/#aws">AWS</a></li>
        <li className="p__opensans"><a href="/#workshops">Workshops</a></li>
        <li className="p__opensans"><a href="/#about">About</a></li>
        <li className="p__opensans_filler" />
      </ul>
      */}
      <div className="app__navbar-login">
        {/* putting width fixed to 150px does not change/wave the display position of strings on Navbar */}
        <div className="p__opensans" onClick={toggleLoginOverlay} style={{ width: '100px', textAlign: 'right' }}>
          {user.split('@')[0]}
        </div>
        <div className="app__navbar-login-vertbar" />
        <div className="p__opensans"><HashLink to="/#contactus" style={{ border: 'none' }}>BuildWithUs</HashLink></div>
        {/* <div className="p__opensans"><a href="/#contactus" style={{ border: 'none' }}>ContactUs</a></div> */}
        {/* <div className="app__navbar-login-vertbar" /> */}
        {/* <div className="p__opensans"><a href="#newsletter" style={{ border: 'none' }}>Subscribe</a></div> */}
      </div>
      <LoginOverlay isLoginOpen={isLoginOpen} onClose={toggleLoginOverlay}>
        <Login2 onClose={toggleLoginOverlay} setAmplifyUser={setAmplifyUser} />
      </LoginOverlay>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            {/* <MdOutlineMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} /> */}
            <FontAwesomeIcon icon={faTimes} size="2x" fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><HashLink to="/#products" onClick={() => setToggleMenu(false)}>Products</HashLink></li>
              <li><HashLink to="/#aws" onClick={() => setToggleMenu(false)}>AWS</HashLink></li>
              {/* <li><HashLink to="/#genai" onClick={() => setToggleMenu(false)}>GenAI</HashLink></li> */}
              <li><HashLink to="/#swift" onClick={() => setToggleMenu(false)}>Swift</HashLink></li>
              {/* <li><HashLink to="/#swiftui" onClick={() => setToggleMenu(false)}>SwiftUI</HashLink></li> */}
              {/* <li><HashLink to="/#visionpro" onClick={() => setToggleMenu(false)}>VisionPro</HashLink></li> */}
              {/* <li><HashLink to="/#workshops" onClick={() => setToggleMenu(false)}>Workshops</HashLink></li> */}
              <li><HashLink to="/#about" onClick={() => setToggleMenu(false)}>About</HashLink></li>
              {/* <li><HashLink to="/#careers" onClick={() => setToggleMenu(false)}>Careers</HashLink></li> */}
              <li><HashLink to="/#contactus" onClick={() => setToggleMenu(false)}>BuildWithUs</HashLink></li>
            </ul>
            {/*
            <ul className="app__navbar-smallscreen_links">
              <li><a href="/#swift" onClick={() => setToggleMenu(false)}>Swift</a></li>
              <li><a href="/#swiftui" onClick={() => setToggleMenu(false)}>SwiftUI</a></li>
              <li><a href="/#visionpro" onClick={() => setToggleMenu(false)}>VisionPro</a></li>
              <li><a href="/#aws" onClick={() => setToggleMenu(false)}>AWS</a></li>
              <li><a href="/#workshops" onClick={() => setToggleMenu(false)}>Workshops</a></li>
              <li><a href="/#about" onClick={() => setToggleMenu(false)}>About</a></li>
              <li><a href="/#contactus" onClick={() => setToggleMenu(false)}>ContactUs</a></li>
            </ul>
            */}

          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
