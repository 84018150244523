import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modals/Modal';
import TextAndImagesListModal from './Modals/TextAndImages/TextAndImagesListModal';
import './TextAndImagesList.css';

function TextAndImagesList({ curriculum, setCurriculum, clCardItem, setClCardItem, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="TILST" onClick={() => setIsOpen(true)}>
        <div className="TILST_A1">
          <FontAwesomeIcon icon={faAlignJustify} />
        </div>
        <div className="TILST_A2">
          Text & Images List
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <TextAndImagesListModal curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
      </Modal>
    </div>
  );
}

export default TextAndImagesList;
