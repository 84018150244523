import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './Catalogue.css';
import AddContentGrid from './CatalogueItems/AddContentGrid';
import EducationalTools from './CatalogueItems/EducationalTools';
import MarketingTools from './CatalogueItems/MarketingTools';

function Catalogue(props) {
  const { clCardItem, setClCardItem, curriculum, setCurriculum, lessonParent, isUploadingViaApi, setIsUploadingViaApi } = props;
  console.log('Catalogue: ', clCardItem, setClCardItem);

  return (
    <div className="CT">
      <div className="CT_Header">
        <div className="CT_Header_A1">
          Add Content
        </div>
        <div className="CT_Header_A2">
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="CT_Body">
        <div className="CT_A1">
          <AddContentGrid curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="CT_A2">
          <EducationalTools curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="CT_A3">
          <MarketingTools curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
      </div>
    </div>
  );
}

export default Catalogue;
