/*  eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import EPInProgressTable from './EPInProgressTable';
import './EPInProgress.css';
import CourseCurriculum from '../../../../../../../../../components/BackendServices/CourseCurriculum';

/*
  useEffect: api call to fetch courses and display
  coursename and institute might be only relevant props here
 */

function EPInProgress(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes, enrolledCoursesProgressingArray, setEnrolledCoursesProgressingArray } = props;
  console.log('EPInProgress', enrolledCoursesProgressingArray);

  // backend is to be consulted every time this page loads
  // because there could be to and fro between pages at a lower child
  // level instead of going via the parent EdTech.js
  useEffect(() => {
    (async () => {
      const resp3 = await CourseCurriculum({ action: 'ENROLLMENT_GET_COURSES' });
      console.log('ENROLLMENT_GET_COURSES', resp3);
      if (resp3.status !== 200) {
        console.log('ENROLLMENT_GET_COURSES failed. Custom error handling', resp3.status);
      }
      const ll = resp3.data.backendResponse.Items.filter((item) => !item.IsCourseDone);
      setEnrolledCoursesProgressingArray(ll);
      console.log('ENROLLMENT_GET_COURSES inProgress ', ll);
      console.log('ll: ', ll);
      console.log('resp: ', resp3.data.backendResponse);
      console.log('count: ', resp3.data.backendResponse.Count);
      console.log('Items: ', resp3.data.backendResponse.Items);
    })();
  }, []);

  return (
    <div className="EPPCO">
      Enrolled courses which are in progress
      <div className="EPPCO_1">
        <table className="EPPCO_A1">
          <thead>
            <tr>
              {/*
              <th className="saved_date_header">Saved Date</th>
              <th className="last_action_header">Last Action</th>
              <th className="type_header">Type</th>
              <th className="id_header">ID</th>
              */}
              <th className="type_header">Name</th>
              <th className="type_header">Price</th>
              <th className="type_header">OptedDate</th>
              <th className="type_header">ProgressStatus</th>
              <th className="type_header">Cursor</th>
              <th className="type_header">Institute</th>
              <th className="more_header">More</th>
            </tr>
          </thead>
          <tbody>
            {isUploadingViaApi && <div>Loading..</div>}
            {!isUploadingViaApi && (enrolledCoursesProgressingArray.length === 0) && <div>Empty List</div>}
            {!isUploadingViaApi && (enrolledCoursesProgressingArray.length > 0) && <EPInProgressTable page={page} setPage={setPage} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} enrolledCurriculum={enrolledCurriculum} setEnrolledCurriculum={setEnrolledCurriculum} enrolledAttributes={enrolledAttributes} setEnrolledAttributes={setEnrolledAttributes} enrolledCoursesProgressingArray={enrolledCoursesProgressingArray} setEnrolledCoursesProgressingArray={setEnrolledCoursesProgressingArray} />}
            <div style={{ height: '100px' }} /> { /* filler to keep Dropdown visible */ }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EPInProgress;
/*  eslint-enable no-unused-vars */
