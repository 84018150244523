import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import './CLCard.css';
import LessonSettings from './LessonSettings';
import AddContent from './AddContent/AddContent';
import CPublish from './AddContent/ContentCatalogue/CatalogueItems/Modals/Curriculum/CPublish';

function CLCard(props) {
  const { clCardItem, setClCardItem, lessonParent } = props;

  return (
    <div className="CL">
      <div className="A4_CL">
        <div className="A41_CL">
          <div className="A41_CL">
            <div className="A41_CL_1">
              Lesson {lessonParent.LessonIndex + 1}
            </div>
            <div className="A41_CL_2">
              {lessonParent.LessonName}
            </div>
          </div>
        </div>
        <div className="A42_CL">
          <div className="A421_CL">
            <CPublish />
          </div>
          <div className="A422_CL">
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </div>
        </div>
      </div>
      <div className="A5_CL">
        <AddContent clCardItem={clCardItem} setClCardItem={setClCardItem} />
      </div>
      <div className="A6_CL">
        <LessonSettings clCardItem={clCardItem} setClCardItem={setClCardItem} />
      </div>
    </div>
  );
}

export default CLCard;
