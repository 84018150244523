/*  eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import './AddLessons.css';
import CourseCurriculum from '../../../../../../components/BackendServices/CourseCurriculum';

function AddLessons({ page, setPage, curriculum, setCurriculum, sectionIndex, addLessonsToSection,
  lessonParent, setLessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  console.log('sectionIndex:', sectionIndex, 'curriculum', curriculum);
  const [lessonList, setLessonList] = useState(curriculum.sectionList[sectionIndex].lessonList);

  const handleLessonAdd = () => {
    const updatedList = [...lessonList,
      {
        lessonName: 'New Lesson Name',
        lessonID: '',
        isLessonNameSaved: false,
        isLessonPublished: false,
        assets: {
          video: [], // videoName, videoID
          richText: [], // richTextName, richTextID
          pdf: [], // pdfName, pdfID
          audio: [], // audioName, audioID
        },
      }];
    setLessonList(updatedList);
    addLessonsToSection(updatedList);
    console.log('updatedList:', updatedList);
  };

  const handleLessonDelete = async (index) => {
    const list = [...lessonList];
    list.splice(index, 1);
    setLessonList(list);
    addLessonsToSection(list);
    //  in 0 based array, delete item at location 'index', in-place
    setIsUploadingViaApi(true);
    const resp = await CourseCurriculum({ curriculum, lessonParent, sectionIndex, lessonIndex: index, action: 'DELETE_LESSON' }); // complete delete. lessonParent can be ignored at backend
    setIsUploadingViaApi(false);
  };

  const handleLessonSave = async (index) => {
    const list = [...lessonList];
    list[index].isLessonNameSaved = true;
    console.log('handleLessonSave', index, list);
    //  setLessonList(list);
    //  addLessonsToSection(list);
    const lessonPar = {
      CourseName: curriculum.courseName,
      SectionName: curriculum.sectionList[sectionIndex].sectionName,
      LessonName: list[index].lessonName,
      SectionIndex: sectionIndex, // integer index
      LessonIndex: index, // integer index (this is not uuid but array index (0,1,2 or 3 etc))
      InstituteID: curriculum.instituteID, // uuid
      CourseID: curriculum.courseID, // uuid
      SectionID: curriculum.sectionList[sectionIndex].sectionID, // uuid
      LessonID: curriculum.sectionList[sectionIndex].lessonList[index].lessonID, // uuid
    };
    console.log('lessonPar', lessonPar);
    setIsUploadingViaApi(true);
    const resp = await CourseCurriculum({ curriculum, lessonParent: lessonPar, sectionIndex, lessonIndex: index, action: 'SET_LESSON_NAME' });
    if (resp.status !== 200) {
      console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
    }
    list[index].lessonID = resp.data.lessonID;
    const updatedValue2 = { lessonList: list };
    const updatedCurriculumJson2 = { ...curriculum, ...updatedValue2 };
    updatedCurriculumJson2.courseID = resp.data.courseID;
    setLessonList(list);
    addLessonsToSection(list);

    setIsUploadingViaApi(false);
    setLessonParent(lessonPar);
    //  setPage('createLesson');
  };

  const handleLessonCreate = (index) => {
    setPage('createLesson');
    const list = [...lessonList];
    const lessonPar = {
      CourseName: curriculum.courseName,
      SectionName: curriculum.sectionList[sectionIndex].sectionName,
      LessonName: list[index].lessonName,
      SectionIndex: sectionIndex,
      LessonIndex: index,
      InstituteID: curriculum.instituteID,
      CourseID: curriculum.courseID,
      SectionID: curriculum.sectionList[sectionIndex].sectionID,
      LessonID: curriculum.sectionList[sectionIndex].lessonList[index].lessonID,
    };
    setLessonParent(lessonPar);
  };

  const handleLessonRename = async (index) => {
    console.log('handleLessonRename', index);
    const list = [...lessonList];
    //  list[index].lessonName = lessonList[index].lessonName;
    list[index].isLessonNameSaved = false;
    setLessonList(list);
    addLessonsToSection(list);
    const lessonPar = {
      CourseName: curriculum.courseName,
      SectionName: curriculum.sectionList[sectionIndex].sectionName,
      LessonName: list[index].lessonName,
      SectionIndex: sectionIndex,
      LessonIndex: index,
      InstituteID: curriculum.instituteID,
      CourseID: curriculum.courseID,
      SectionID: curriculum.sectionList[sectionIndex].sectionID,
      LessonID: curriculum.sectionList[sectionIndex].lessonList[index].lessonID,
    };
    console.log('lessonPar', lessonPar);
    setIsUploadingViaApi(true);
    const resp = await CourseCurriculum({ curriculum, lessonParent: lessonPar, sectionIndex, lessonIndex: index, action: 'RESET_LESSON_NAME' });
    if (resp.status !== 200) {
      console.log('check for timeout errors and show to user for any network issues');
      console.log('for 500, wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
      console.log('for 400 client error, chekc message and flash to user or to platform team');
      console.log('check these at axios level');
    }
    // might want to cross-check lessonID in api response with what exist in curriculum
    setIsUploadingViaApi(false);
    setLessonParent(lessonPar);
  };

  const handleLessonChange = (updatedName, index) => {
    console.log('handleLessonChange', index);
    const list = [...lessonList];
    list[index].lessonName = updatedName;
    console.log(list, list[index]);
    setLessonList(list);
  };
  const lessonNameClick = (e, index) => {
    console.log('handleLessonClick', index);
  };

  return (
    <div>
      <div className="form-field__L">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="lessonName"> Lessons </label>
        {(!curriculum.sectionList[sectionIndex].isSectionNameSaved)
        && (
          <div className="unavailable_lesson">
            To add lesson into section-{ sectionIndex + 1 }, first save the section name .
          </div>
        )}

        {lessonList.map((singleLesson, index) => (
          <div key={index} className="lessons">
            <div className="division-container__L">
              <div className="first-division__L">
                {!lessonList[index].isLessonNameSaved
                && <input name="lessonName" type="text" id="lessonName" className="text" autoComplete="off" autoCorrect="off" required value={singleLesson.lessonName} onChange={(e) => handleLessonChange(e.target.value, index)} />}
                {lessonList[index].isLessonNameSaved
                && <div className="first-division__L_1" onClick={(e) => lessonNameClick(e, index)}> {`Lesson ${index + 1}: `} {lessonList[index].lessonName} </div>}
              </div>
              {(!curriculum.sectionList[sectionIndex].isSectionNameSaved)
              && (
                <div className="second-division__L">
                  <div className="save-remove-btn__L">
                    <button type="button" className="remove-btn__L" onClick={() => handleLessonDelete(index)}>
                      <span>Cancel</span>
                    </button>
                    <button type="button" className="unavailable_btn">
                      <span>Unavailable</span>
                    </button>
                  </div>
                </div>
              )}
              { (curriculum.sectionList[sectionIndex].isSectionNameSaved)
                && (
                <div className="second-division__L">
                  {lessonList.length > 0
                  && (
                  <div className="save-remove-btn__L">
                    {isUploadingViaApi
                    && (
                      <div className="save-remove-btn__L">
                        <button type="button" className="processing__L">
                          <span>...</span>
                        </button>
                        <button type="button" className="processing__L">
                          <span>...</span>
                        </button>
                      </div>
                    )}
                    {!isUploadingViaApi && !lessonList[index].isLessonNameSaved
                    && (
                      <div className="save-remove-btn__L">
                        <button type="button" className="remove-btn__L" onClick={() => handleLessonDelete(index)}>
                          <span>Cancel</span>
                        </button>
                        <button type="button" className="save-btn__L" onClick={() => { handleLessonSave(index); }}>
                          <span>Save</span>
                        </button>
                      </div>
                    )}
                    {!isUploadingViaApi && lessonList[index].isLessonNameSaved
                    && (
                      <div className="save-remove-btn__L">
                        <button type="button" className="remove-btn__L_2" onClick={() => handleLessonRename(index)}>
                          <span>Rename</span>
                        </button>
                        <button type="button" className="remove-btn__L_2" onClick={() => handleLessonDelete(index)}>
                          <span>Delete</span>
                        </button>
                        <button type="button" className="save-btn__L_2" onClick={() => { handleLessonCreate(index); }}>
                          <span>Edit</span>
                        </button>
                      </div>
                    )}
                  </div>
                  )}
                </div>
                )}
            </div>
          </div>
        ))}
        {(!curriculum.sectionList[sectionIndex].isSectionNameSaved)
        && (
        <div className="add-entry__L">
          {lessonList.length < 4
            && (
              <button type="button" className="add-btn__unavailable">
                <FontAwesomeIcon icon={faPlusSquare} />
                <span>Unavailable</span>
              </button>
            )}
        </div>
        )}
        {(curriculum.sectionList[sectionIndex].isSectionNameSaved && (lessonList.length < 4))
        && (
        <div className="add-entry__L">
          {!curriculum.sectionList[sectionIndex].lessonList[curriculum.sectionList[sectionIndex].lessonList.length - 1].isLessonNameSaved
            && (
              <button type="button" className="add-btn__unavailable">
                <FontAwesomeIcon icon={faPlusSquare} />
                <span>Unavailable</span>
              </button>
            )}
          {curriculum.sectionList[sectionIndex].lessonList[curriculum.sectionList[sectionIndex].lessonList.length - 1].isLessonNameSaved
            && (
              <button type="button" className="add-btn__L" onClick={() => handleLessonAdd()}>
                <FontAwesomeIcon icon={faPlusSquare} />
                <span>Add New Lesson</span>
              </button>
            )}
        </div>
        )}
      </div>
    </div>
  );
}

export default AddLessons;
/*  eslint-enable no-unused-vars */
