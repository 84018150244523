/*  eslint-enable no-unused-vars */
import React from 'react';
import './CoursePublishState.css';
import CPState1 from './CPState1';
import CPState2 from './CPState2';
import CPState3 from './CPState3';
import CPState4 from './CPState4';
import './CPState.css';

function CoursePublishState({ s1Done, s2Done, s3Done, s4Done }) {
  return (
    <div className="CPS">
      <div className="CPS_A1">
        <CPState1 s1Done={s1Done} />
      </div>
      <div className="CPS_A2">
        <CPState2 s2Done={s2Done} />
      </div>
      <div className="CPS_A3">
        <CPState3 s3Done={s3Done} />
      </div>
      <div className="CPS_A4">
        <CPState4 s4Done={s4Done} />
      </div>
    </div>
  );
}

export default CoursePublishState;
/*  eslint-enable no-unused-vars */
