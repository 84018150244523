import React, { useState } from 'react';
import './CPublish.css';
import Modal from '../Modal';
import CPublishModal from './CPublishModal';

function CPublish() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="CPU" onClick={() => setIsOpen(true)}>
        <div className="CPU_A2">
          Publish
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <CPublishModal />
      </Modal>
    </div>
  );
}

export default CPublish;
