/*  eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './EPLessonPDF.css';
import AssetsApi from '../../../../../../../../../../../components/BackendServices/AssetsApi';

function EPLessonPDF(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes, userTokens } = props;
  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [hash1, setHash1] = useState('');
  const sectionIndex = enrolledAttributes.EnrolledCourse.userCursor.section;
  const lessonIndex = enrolledAttributes.EnrolledCourse.userCursor.lesson;
  const pdfArray = enrolledCurriculum.sectionList[sectionIndex - 1].lessonList[lessonIndex - 1].assets.pdf;
  const pdfKeyName = pdfArray[0].pdfName;
  console.log('EPLessonRichText', `pdf/${pdfKeyName}`);
  const urlAccessToken = `https://d2tc0jno74e7ty.cloudfront.net/pdf/${pdfKeyName}?Authorization=Bearer ${userTokens.jwtAccessToken}&hash=${hash1}`;
  console.log('urlAccessToken: ', urlAccessToken);
  // SyntaxError: Unexpected token '<' --  pdf.worker.min.js
  // pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'; // https://www.npmjs.com/package/react-pdf/v/6.0.0-beta.5
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; // https://github.com/wojtekmaj/react-pdf/issues/991
  /*
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();
  */

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  async function sha512(string) {
    const utf8 = new TextEncoder().encode(string);
    await timeout(1000);
    return crypto.subtle.digest('SHA-512', utf8).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
      return hashHex;
    });
  }

  useEffect(() => {
    (async () => {
      const reqObjVC = {
        key: 'Z4iLcO',
        SALT: '112233',
        name: 'video/vid1.mov',
      };
      await sha512(`${reqObjVC.key}|${reqObjVC.SALT}|${reqObjVC.name}`).then((hcode) => setHash1(hcode));
      /*
      const resp = await AssetsApi({ action: 'GET_PDF', key: 'pdf/Eumag.pdf' });
      if (resp.status !== 200) {
        console.log('GET_PDF returned error. Cutsom handling.', resp);
      }
      setPdfData(resp.data);
      */
    })();
  }, []);

  /* eslint-disable-next-line no-shadow */
  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log('numPages:', numPages);
    setNumPages(numPages);
  };

  return (
    <div className="LESSON_VDO">
      <div className="LESSON_VDO_1">
        PDF Header
      </div>
      <div className="LESSON_VDO_2">
        <Document file={urlAccessToken} onLoadSuccess={onDocumentLoadSuccess}>
          {/* eslint-disable-next-line prefer-spread */}
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            /* eslint-disable-next-line arrow-body-style */
            .map((page1) => {
              console.log('page:', page1);
              return (
                <Page
                  pageNumber={page1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              );
            })}
        </Document>
      </div>
      <div className="LESSON_VDO_3">
        PDF Footer
      </div>
    </div>
  );
}

export default EPLessonPDF;
/*  eslint-enable no-unused-vars */
