import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';

const TalkOne = (accessToken) => {
  console.debug('acessToken: ', accessToken);
  return (
    <div>
      <div>TalkOne</div>
      <div>
        <video
          preload="none"
          tabIndex="-1"
          className="talkTwo__zero"
          width="1040"
          height="480"
          type="video/mp4"
          style={{ marginTop: '5rem', marginLeft: '5rem' }}
          controls
          controlsList="nodownload"
        >
          <source src={`https://dds5tgku5k77a.cloudfront.net/videos/vid1.mp4?Authorization=${accessToken}`} />
          {/* <source src="https://dds5tgku5k77a.cloudfront.net/videos/vid1.mp4" /> */}
          <track src="" kind="captions" />
        </video>
      </div>
    </div>
  );
};

export default withAuthenticator(TalkOne);
