/*  eslint-disable no-unused-vars, no-restricted-syntax */
import axios from 'axios';
import FetchGroups from './FetchGroups';

const baseUrl = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1';

const getPresignedUrl = async (key, contentType, action, duration = '86400') => {
  const toks = await FetchGroups(true);
  console.log(action, duration);

  const config = {
    headers: { Authorization: toks.tokens.jwtIdToken },
    //  eslint-disable-next-line quote-props
    params: { 'key': key, 'duration': duration, 'contentType': contentType },
  };

  let apiResponse = '';
  if (action === 'GET') {
    apiResponse = await axios.get(`${baseUrl}/presignedurl/s3`, config)
      //  eslint-disable-next-line prefer-arrow-callback,func-names
      .catch(function (error) {
        console.log('error: ', error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('error.message:', error.message);
        }
        console.log(error.config);
        return 'error';
      });
    console.debug('axios.get() apiResponse: ', apiResponse);
    //  console.log(apiResponse.data.presignedUrl);
    //  return apiResponse.data.presignedUrl;
  } else if (action === 'PUT') {
    apiResponse = await axios.put(`${baseUrl}/presignedurl/s3`, null, config)
      //  eslint-disable-next-line prefer-arrow-callback,func-names
      .catch(function (error) {
        console.log('error: ', error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('error.message:', error.message);
        }
        console.log(error.config);
        return 'error';
      });
    console.debug('axios.get() apiResponse: ', apiResponse);
    //  console.log(apiResponse.data.presignedUrl);
    //  return apiResponse.data.presignedUrl;
  } else if (action === 'POST') {
    apiResponse = await axios.post(`${baseUrl}/presignedurl/s3`, null, config)
      //  eslint-disable-next-line prefer-arrow-callback,func-names
      .catch(function (error) {
        console.log('error: ', error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('error.message:', error.message);
        }
        console.log(error.config);
        return 'error';
      });
    console.debug('axios.get() apiResponse: ', apiResponse);
    //  console.log(apiResponse.data.presignedUrl);
    //  return apiResponse.data.presignedUrl;
  }
  return apiResponse.data.presignedUrl;
};

// https://stackoverflow.com/questions/54076283/how-to-upload-a-file-to-s3-using-presigned-url-with-react-js
const UploadToPresignedUrl2 = async (file, presignedUrlData, contentType, setUploadProgress) => {
  // append the fields in presignedPostData in formData
  const formData = new FormData();
  Object.keys(presignedUrlData.fields).forEach((key) => {
    formData.append(key, presignedUrlData.fields[key]);
  });
  formData.append('file', file);
  /*
  console.log('TTT1', file.size);
  for (const pair of formData.entries()) {
    console.log(`${pair[0]}, ${pair[1].size}`);
  }
  */
  // post the data on the s3 url
  const uploadResponse = await axios.post(presignedUrlData.url, formData, {
    headers: {
      // 'Content-Type': 'multipart/form-data',
      'Content-Type': contentType,
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      setUploadProgress(percentCompleted);
      // console.log(`Upload Progress: ${percentCompleted}%`);
    },
    //  eslint-disable-next-line prefer-arrow-callback
  }).then(function (response) {
    console.log('TTT2 UploadToPresignedUrl2 response', response);
  })
  //  eslint-disable-next-line prefer-arrow-callback
    .catch(function (error) {
      console.log('TTT3 UploadToPresignedUrl2 error', error);
    });
  console.log('TTT4 UploadToPresignedUrl2 uploadResponse', uploadResponse);
  return uploadResponse;
};

const UploadToPresignedUrlPost = async (presignedUrl, selectedFile, contentType, setUploadProgress) => {
  const uploadResponse = await axios.post(presignedUrl, selectedFile, {
    headers: {
      'Content-Type': contentType,
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      setUploadProgress(percentCompleted);
      // console.log(`Upload Progress: ${percentCompleted}%`);
    },
  });
  console.log('uploadResponse:', uploadResponse);
  return uploadResponse;
};

const UploadToPresignedUrlPut = async (presignedUrl, selectedFile, contentType, setUploadProgress) => {
  const uploadResponse = await axios.put(presignedUrl, selectedFile, {
    headers: {
      'Content-Type': contentType,
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      setUploadProgress(percentCompleted);
      // console.log(`Upload Progress: ${percentCompleted}%`);
    },
  });
  console.log('uploadResponse:', uploadResponse);
  return uploadResponse;
};

const S3Upload = async (file, filename, /* formData, */ contentType, setUploadProgress) => {
  console.log('S3Upload:', filename);
  //  ensure filesize is between 0 upto 2GB (2000000000, 2 million bytes)
  let res = '';
  if (contentType === 'video/mp4') {
    const presignedUrl = await getPresignedUrl(filename, contentType, 'POST');
    console.log('presignedUrl:', presignedUrl);
    res = await UploadToPresignedUrl2(file, presignedUrl, contentType, setUploadProgress);
    console.log('TTTT', res);
  } else {
    // first parameter file=value is not used in this case
    const presignedUrl = await getPresignedUrl(filename, contentType, 'PUT');
    console.log('presignedUrl:', presignedUrl);
    res = await UploadToPresignedUrlPut(presignedUrl, contentType, setUploadProgress);
    console.log('TTTT', res);
  }
  return res;
};

export default S3Upload;
/*  eslint-enable no-unused-vars */
