/*  eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './EPPublishedCoursesTable2.css';
import Dropdown from '../../components/Dropdown/Dropdown';
import DropdownItem from '../../components/DropdownItem/DropdownItem';
import CourseCurriculum from '../../../../../../../../components/BackendServices/CourseCurriculum';

function EPPublishedCoursesTable2(props) {
  console.log('EPPublishedCoursesTable2: ', props);
  const { curriculum, setCurriculum, initialCurriculum, selectedCatalogueIndex, setSelectedCatalogueIndex, curriculumArray } = props;
  const greenColor = 'rgba(34,139,34,0.6)';

  async function handleDeleteCourse(courseID, curri, index) {
    console.log('handleDeleteCourse', courseID, curri);
    setSelectedCatalogueIndex(-1);
    // instead of soft delete, hard delete will keep things simpler at present.
    // delete the courseID
    // delete all references to this courseID, for instance, last edited course, enrolled course, enrolled attributs, assets in s3,etc
    // or let users delete their content by themself - but they wont do it and it will lead to dangling or unwanted issues
  }
  async function handleLoadCourse(courseID, curri, index) {
    console.log('handleLoadCourse', courseID, curri);
    await setCurriculum(curri);
    setSelectedCatalogueIndex(index);
  }
  async function handleUnloadCourse(courseID, curri, index) {
    console.log('handleLoadCourse', courseID, curri);
    await setCurriculum(initialCurriculum);
    setSelectedCatalogueIndex(-1);
  }
  async function handleEnrollCourse(courseID, curri, index) {
    console.log('handleEnrollCourse', courseID, curri);
    const enrolledCourse = { doneStatus: {}, userCursor: { section: 1, lesson: 1 }, courseAttributes: { courseID: curri.courseID, courseName: curri.courseName, coursePrice: curri.coursePrice, courseCurrency: curri.courseCurrency, instituteName: curri.instituteName } };
    const resp = await CourseCurriculum({ enrolledCourse, action: 'ENROLLMENT' });
    console.log('ENROLLMENT', resp);
  }

  const items = [
    { name: 'Delete', handler: handleDeleteCourse },
    { name: 'Load', handler: handleLoadCourse },
    { name: 'Unload', handler: handleUnloadCourse },
    { name: 'Enroll', handler: handleEnrollCourse },
  ];

  return (
    <div className="EPPCTBL">
      {
        curriculumArray.map((courseItem, index) => {
          // unpublished courses can be skipped
          const { SavedDate, LastAction, ID, Type, CourseCurriculum: CourseCurriculum1 } = courseItem;
          return (
            <tr style={{ backgroundColor: (index === selectedCatalogueIndex) ? 'rgba(34,139,34, 0.6)' : 'transparent', color: CourseCurriculum1.isCoursePublished ? 'white' : 'black' }}>
              {/*
              <td className="last_action_val">{LastAction}</td>
              <td className="type_val">{Type}</td>
              <td className="id_val">{ID}</td>
              */}
              <td className="saved_date_val">{SavedDate}</td>
              <td className="type_val">{CourseCurriculum1.isCoursePublished ? 'Published' : 'Draft'}</td>
              <td className="type_val">{CourseCurriculum1.courseName}</td>
              <td className="type_val">{CourseCurriculum1.coursePrice} {CourseCurriculum1.courseCurrency}</td>
              <td className="type_val">{CourseCurriculum1.instituteName}</td>
              {/* <td>{JSON.stringify(CourseCurriculum1, null, 2)}</td> */}
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              {/* <td className="more_val" onClick={handleActions}><FontAwesomeIcon icon={faBars} /></td> */}
              <td className="more_val">
                <button type="button" className="save-btn">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <Dropdown
                    buttonText={<FontAwesomeIcon icon={faBars} />}
                    content={(
                      <>
                        {items.map((item, id) => (
                          <DropdownItem key={id} onClick={() => item.handler(ID, CourseCurriculum1, index)}>
                            {`${item.name}`}
                          </DropdownItem>
                        ))}
                      </>
                    )}
                  />
                </button>
              </td>

            </tr>
          );
        })
      }
    </div>
  );
}

export default EPPublishedCoursesTable2;
/*  eslint-enable no-unused-vars */
