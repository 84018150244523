import React from 'react';
import TextAndImagesList from './TextAndImagesList';
import './AddContentGrid.css';
import VideoContentList from './VideoContentList';
import PDFViewerList from './PDFViewerList';
import AudioContentList from './AudioContentList';
import BannerImageList from './BannerImageList';
import ResourceList from './ResourceList';
import CodeExampleList from './CodeExampleList';

function AddContentGridList({ curriculum, setCurriculum, clCardItem, setClCardItem, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  return (
    <div className="ACG">
      <div className="ACG_Header">
        Content
      </div>
      <div className="ACG_Body">
        <div className="ACG_Body_A1">
          <TextAndImagesList curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <VideoContentList curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <PDFViewerList curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <AudioContentList curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <BannerImageList curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <ResourceList curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="ACG_Body_A1">
          <CodeExampleList curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
      </div>
    </div>
  );
}

export default AddContentGridList;
