import React, { useState } from 'react';
import './EdTechPitchDeck1.css';
import { Document, Page, pdfjs } from 'react-pdf';

function EdTechPitchDeck1() {
  const [numPages, setNumPages] = useState();
  // SyntaxError: Unexpected token '<' --  pdf.worker.min.js
  // pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'; // https://www.npmjs.com/package/react-pdf/v/6.0.0-beta.5
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; // https://github.com/wojtekmaj/react-pdf/issues/991

  /* eslint-disable-next-line no-shadow */
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="PITCH_1">
      <div className="PITCH_3">
        EdTechPitchDeck1
      </div>
      <div className="PITCH_4">
        <div className="PITCH_2">
          <Document file="pdfs/CurvSort_EdTech.pdf" onLoadSuccess={onDocumentLoadSuccess}>
            {/* eslint-disable-next-line prefer-spread */}
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              /* eslint-disable-next-line arrow-body-style */
              .map((page1) => {
                console.log('page:', page1);
                return (
                  <Page
                    pageNumber={page1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    scale={0.4}
                  />
                );
              })}
          </Document>
        </div>
      </div>
    </div>
  );
}

export default EdTechPitchDeck1;
