/*  eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import EPClassroomLesson2 from './EPClassroomLesson2';
import './EPClassroomLesson.css';
import CourseCurriculum from '../../../../../../../../../../components/BackendServices/CourseCurriculum';

function EPClassroomLesson(props) {
  // curriculum can be changed and re-published, while enrolledCurriculum is stored separately with enroller
  // for instance, a new section is added into a curriculum, this will not be reflected into enrolledCurriculum unless an a elf-correction trigger is executed
  // curriculum published - keep modfied date, at webapp start or pages, compare last modified date of local copy (enrolledCurriculum) and published copy. Self-correct and apply delta on local copy and its db state.
  // here - assume enrolledCUrriculum is to be used. Or keep a check against lastModifiedDate wrt published curriculum.
  // TODO: keep a published version of curriculum and refer that.
  // TODO: present restriction: first save curriculum and then start enrolling it. After enroll, updates to curriculum will not be visible in enrolledCurriculum
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes } = props;
  console.log('EPClassroomLesson - enrolledAttributes: ', enrolledAttributes);
  console.log('EPClassroomLesson - enrolledCurriculum: ', enrolledCurriculum);
  const [userTokens, setUserTokens] = useState('');
  const sectionIndex = (enrolledAttributes === undefined) ? 0 : enrolledAttributes.EnrolledCourse.userCursor.section;
  const lessonIndex = (enrolledAttributes === undefined) ? 0 : enrolledAttributes.EnrolledCourse.userCursor.lesson;
  console.log(sectionIndex, lessonIndex);
  // eslint-disable-next-line prefer-destructuring
  const sectionName = (enrolledCurriculum === undefined) ? 0 : enrolledCurriculum.sectionList[sectionIndex - 1].sectionName;
  // eslint-disable-next-line prefer-destructuring
  const lessonName = (enrolledCurriculum === undefined) ? 0 : enrolledCurriculum.sectionList[sectionIndex - 1].lessonList[lessonIndex - 1].lessonName;

  useEffect(() => {
    (async () => {
      const resp = await CourseCurriculum({ action: 'JWT_ACCESS_TOKEN_GET' });
      if (resp.status !== 200) {
        console.log('JWT_ACCESS_TOKEN_GET failed. Custom error handling', resp.status);
      }
      setUserTokens(resp.data.tokens);
      console.log('access token:', resp.data.tokens.jwtAccessToken);
      console.log('id token:', resp.data.tokens.jwtIdToken);
    })();
  }, []);

  async function handleMarkSectionDone() {
    console.log('handleMarkSectionDone', enrolledCurriculum);
    const updatedSectionListRow = { ...enrolledCurriculum.sectionList[sectionIndex - 1], IsSectionDone: true };
    const updatedEnrolledCurriculum = { ...enrolledCurriculum };
    updatedEnrolledCurriculum.sectionList[sectionIndex - 1] = updatedSectionListRow;
    console.log(updatedEnrolledCurriculum);
  }

  async function handleSectionPrev() {
    console.log('handleSectionPrev', enrolledAttributes);
    const existingSectionIndex = enrolledAttributes.EnrolledCourse.userCursor.section;
    if (existingSectionIndex === 1) {
      console.log('cannot go below first sectionIndex');
      return;
    }
    const updatedUserCursor = { ...enrolledAttributes.EnrolledCourse.userCursor, section: existingSectionIndex - 1 };
    const updatedEnrolledAttributes = { ...enrolledAttributes };
    updatedEnrolledAttributes.EnrolledCourse.userCursor = updatedUserCursor;
    console.log(updatedEnrolledAttributes);
    setEnrolledAttributes(updatedEnrolledAttributes);
  }

  async function handleSectionNext() {
    console.log('handleSectionNext', enrolledAttributes);
    const maxSectionIndex = enrolledCurriculum.sectionList.length;
    const existingSectionIndex = enrolledAttributes.EnrolledCourse.userCursor.section;
    if (existingSectionIndex === maxSectionIndex) {
      console.log('cannot go above last sectionIndex', existingSectionIndex, maxSectionIndex);
      return;
    }
    const updatedUserCursor = { ...enrolledAttributes.EnrolledCourse.userCursor, section: existingSectionIndex + 1 };
    const updatedEnrolledAttributes = { ...enrolledAttributes }; // without spread operator, a new copy is avoided and setState doees not cause re-render of this component. "const updatedEnrolledAttributes = enrolledAttributes" would have missed re-rendering and re-fetching the updated state and indexes.
    updatedEnrolledAttributes.EnrolledCourse.userCursor = updatedUserCursor;
    console.log(updatedEnrolledAttributes);
    setEnrolledAttributes(updatedEnrolledAttributes);
  }

  async function handleMarkLessonDone() {
    console.log('handleMarkLessonDone', enrolledCurriculum);
    const updatedLessonListRow = { ...enrolledCurriculum.sectionList[sectionIndex - 1].lessonList[lessonIndex - 1], IsLessonDone: true };
    const updatedEnrolledCurriculum = { ...enrolledCurriculum };
    updatedEnrolledCurriculum.sectionList = enrolledCurriculum.sectionList;
    updatedEnrolledCurriculum.sectionList[sectionIndex - 1].lessonList[lessonIndex - 1] = updatedLessonListRow;
    console.log(updatedEnrolledCurriculum);
  }

  async function handleLessonPrev() {
    console.log('handleLessonPrev', enrolledCurriculum);
    const existingLessonIndex = enrolledAttributes.EnrolledCourse.userCursor.lesson;
    if (existingLessonIndex === 1) {
      console.log('cannot go below first lessonIndex');
      return;
    }
    const updatedUserCursor = { ...enrolledAttributes.EnrolledCourse.userCursor, lesson: existingLessonIndex - 1 };
    const updatedEnrolledAttributes = { ...enrolledAttributes };
    updatedEnrolledAttributes.EnrolledCourse.userCursor = updatedUserCursor;
    console.log(updatedEnrolledAttributes);
    setEnrolledAttributes(updatedEnrolledAttributes);
  }

  async function handleLessonNext() {
    console.log('handleLessonNext', enrolledCurriculum);
    const maxLessonIndex = enrolledCurriculum.sectionList[sectionIndex - 1].lessonList.length;
    const existingLessonIndex = enrolledAttributes.EnrolledCourse.userCursor.lesson;
    if (existingLessonIndex === maxLessonIndex) {
      console.log('cannot go above lastLessonIndex', existingLessonIndex, maxLessonIndex);
      return;
    }
    const updatedUserCursor = { ...enrolledAttributes.EnrolledCourse.userCursor, lesson: existingLessonIndex + 1 };
    const updatedEnrolledAttributes = { ...enrolledAttributes };
    updatedEnrolledAttributes.EnrolledCourse.userCursor = updatedUserCursor;
    console.log(updatedEnrolledAttributes);
    setEnrolledAttributes(updatedEnrolledAttributes);
  }

  const sectionRow = () => (
    <div className="EPCLLES_2">
      <div className="EPCLLES_31">
        Section {sectionIndex}: {sectionName}
      </div>
      <div className="EPCLLES_32" onClick={(e) => handleMarkSectionDone()}>
        Mark Section Done
      </div>
      <div className="EPCLLES_33">
        <div className="EPCLLES_331" onClick={(e) => handleSectionPrev()}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </div>
        <div className="EPCLLES_332" onClick={(e) => handleSectionNext()}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </div>
      </div>
    </div>
  );

  const lessonRow = () => (
    <div className="EPCLLES_3">
      <div className="EPCLLES_31">
        Lesson {lessonIndex}: {lessonName}
      </div>
      <div className="EPCLLES_32" onClick={(e) => handleMarkLessonDone()}>
        Mark Lesson Done
      </div>
      <div className="EPCLLES_33">
        <div className="EPCLLES_331" onClick={(e) => handleLessonPrev()}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <div className="EPCLLES_332" onClick={(e) => handleLessonNext()}>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="EPCLLES">
      <div className="EPCLLES_1">
        {sectionRow()}
        {lessonRow()}
      </div>
      <div className="EPCLLES_4">
        <EPClassroomLesson2 page={page} setPage={setPage} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} enrolledCurriculum={enrolledCurriculum} setEnrolledCurriculum={setEnrolledCurriculum} enrolledAttributes={enrolledAttributes} setEnrolledAttributes={setEnrolledAttributes} userTokens={userTokens} />
      </div>
    </div>
  );
}

export default EPClassroomLesson;
/*  eslint-enable no-unused-vars */
