import axios from 'axios';
import FetchJwtToken from './FetchJwtToken';

const FetchGroups2 = async () => {
  console.debug('FetchGroups2');
  const toks = await FetchJwtToken(true);
  const userChecks = {
    isUserexist: false, //  undefined,
    isUsertierone: false, //    undefined,
    tokens: toks,
  };
  if (toks.username === '') {
    console.debug('user does not exist!');
    userChecks.isUserexist = false;
    userChecks.isUsertierone = false;
  } else {
    console.debug('user exist');
    const baseURL = `https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/user/${toks.userpoolid}/${toks.username}/groups`;
    const response = await axios.get(baseURL, {
      headers: {
        //  "Content-Type": "text/json",
        // Authorization: `Bearer ${toks.jwtAccessToken}`,
        Authorization: `${toks.jwtIdToken}`,
      },
      params: {
        clientId: toks.clientid,
        refreshToken: toks.refToken,
      },
    })
      //  eslint-disable-next-line prefer-arrow-callback,func-names
      .catch(function (error) {
        console.log('error: ', error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.data === 'The incoming token has expired') {
            console.log('%cDisplay alert box: The incoming token has expired. User should signout and signin again', 'background: green', 'color: white;', 'CAPSCODE');
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('error.message:', error.message);
        }
        console.log(error.config);
        return userChecks;
      });
    console.debug('axios.get() response: ', response);
    /*
    const usertier = response.data.Tier;
    if ((response !== undefined) && (response.data !== undefined) && (response.data.Tier === 'USERTIER#1')) {
    setIsusertierone(true);
    }
    */
    if ((response !== undefined) && (response.data !== undefined)) {
      userChecks.isUserexist = true; // there might be an error, say UserDoesNotExist, hence set it here after above logic
      const groups = response.data;
      userChecks.isUsertierone = false;
      groups.forEach((element) => {
        console.debug('element', element);
        if (element.GroupName === 'UserGroup1') {
          console.debug('setting UserGroup1');
          userChecks.isUsertierone = true;
        }
      });
    }
  }
  console.debug('userChecks:', userChecks);
  return userChecks;
};

let groups = {};
const FetchGroups = async (fresh = false) => {
  console.debug('FetchGroups', fresh, groups);
  if ((fresh) || (!Object.hasOwnProperty.call(groups, 'isUsertierone'))) {
    const grps = await FetchGroups2();
    //  setTokens(toks);
    groups = grps;
    console.debug('calling rest api to fetch fresh groups: ', grps);
  } else {
    console.debug('skipping to call rest api fresh for groups');
  }
  return groups;
};

export default FetchGroups;
