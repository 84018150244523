import React from 'react';
import './VideoModal.css';
import DropFileInput from './DropFileInput';

function VideoModal({ curriculum, setCurriculum, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  const onFileChange = (files) => {
    console.log(files);
  };

  return (
    <div className="VM">
      <div className="VM_A1">
        Add Video
      </div>
      <div className="VM_A2">
        Upload video that can be played in your lesson. You can include a link for your students to download the file. Max file size is 2GB.
      </div>
      <div className="VM_A5">
        <div className="VM_A4">
          <div className="VM_A3">
            <DropFileInput onFileChange={(files) => onFileChange(files)} lessonParent={lessonParent} curriculum={curriculum} setCurriculum={setCurriculum} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoModal;
