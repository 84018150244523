import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AddContentGridList from './CatalogueItems/AddContentGridList';
import EducationalToolsList from './CatalogueItems/EducationalToolsList';
import MarketingToolsList from './CatalogueItems/MarketingToolsList';
import './Catalogue.css';

function Preview(props) {
  const { curriculum, setCurriculum, clCardItem, setClCardItem, lessonParent, isUploadingViaApi, setIsUploadingViaApi } = props;
  console.log(clCardItem, setClCardItem);

  return (
    <div className="CT">
      <div className="CT_Header">
        <div className="CT_Header_A1">
          List of Uploaded Content
        </div>
        <div className="CT_Header_A2">
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="CT_Body">
        <div className="CT_A1">
          <AddContentGridList curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="CT_A2">
          <EducationalToolsList curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
        <div className="CT_A3">
          <MarketingToolsList curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
        </div>
      </div>
    </div>
  );
}

export default Preview;
