import React from 'react';
import ReactDom from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'lightgray',
  padding: '50px',
  zIndex: 1000,
  width: 900,
  height: 600,
  overflow: 'scroll',
};

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0,0.7)',
  zIndex: 1000,
};

function Modal(props) {
  const { isOpen, children, setIsOpen } = props;

  if (isOpen) {
    console.log('Modal isOpen');
    //  https://www.youtube.com/watch?v=LyLa7dU5tp8&t=456s
    return ReactDom.createPortal(
      <div style={OVERLAY_STYLES}>
        <div style={MODAL_STYLES}>
          {children}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button type="button" onClick={() => { setIsOpen(false); console.log('closing modal'); }} style={{ width: '20px', height: '20px', position: 'absolute', top: '10px', right: '15px' }}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>,
      document.getElementById('portal'),
    );
  }

  return null;
}

export default Modal;
