import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSwift } from '@fortawesome/free-brands-svg-icons';
import { faAngleDoubleUp, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import { SubHeading } from '../../../components';
import { images } from '../../../constants';
import './SwiftHeader.css';
import '../Header.css';

//  eslint-disable-next-line arrow-body-style
const SwiftHeader = () => {
  const navigate = useNavigate();
  return (
    <div id="swift">
      {/* <div className="app__fillerForNavbar" /> */}
      <div className="app__headerS app__wrapper section__padding">
        <div className="app__wrapper_info">
          <SubHeading title="Interact with Apple technologies" />
          <div className="swiftLogo">
            <FontAwesomeIcon className="swiftLogoIcon" icon={faSwift} />
            <h1 className="app__header-h1">Swift</h1>
          </div>
          <p className="p__opensans" style={{ margin: '2rem 0' }}> Swift is a programming language created by Apple to allow access to its deep and wide set of technologies and hardware. </p>
          <div className="app__header_buttons">
            {/* <div className="exploreTrack" onClick={() => navigate('/talks')}> */}
            <div className="exploreTrack2 tooltip__HR">
              <span className="tooltiptext__HR"> Available Dec2024 </span>
              {/* <div>Explore</div> */}
              <div className="exploreTrack">
                <div>Posts</div>
                <FontAwesomeIcon className="angleRightLogo" icon={faAngleDoubleRight} />
              </div>
            </div>
            <FontAwesomeIcon onClick={() => { navigate('/'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} className="angleUpLogo" icon={faAngleDoubleUp} />
            {/* <button type="button" onClick={() => navigate('/talks')} className="custom__button fas fa-thumbs-up">Explore Track</button> */}
            {/* <button type="button" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="custom__button">Back to Top</button> */}
          </div>
        </div>

        <div className="app__wrapper_img">
          <img src={images.xcode} alt="header_img" />
        </div>
      </div>
    </div>
  );
};

export default SwiftHeader;
