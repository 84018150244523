import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../../../../../components';
import { images } from '../../../../../constants';
import './ProductsList2.css';
import SocialHandles from '../../../../../components/SocialHandles/SocialHandles';
import FetchGroups from '../../../../../components/BackendServices/FetchGroups';

const ProductsList2 = () => {
  const navigate = useNavigate();
  const [groups, setGroups] = useState({});

  useEffect(() => {
    (async () => {
      const userCheckGroups = await FetchGroups();
      /*
        No need to block rendering of whole Talks component.
        After Talks get rendered, user sees cards. Clicking upon this
        caed should take user either to VideoCard or to PayPage and that is when result of
        FetchGroups is needed.
      */
      //  setIsloading(false);
      setGroups(userCheckGroups);
    })();
  }, []);

  /*
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  */

  return (
    <div>
      <Navbar />
      <div className="talks_zero_container__PL">
        <div className="talks_zero__PL">
          {/* console.log('PPuserCheckGroups:', userCheckGroups)} */} {/* empty */}
          {console.debug('PPgroups:', groups)} {/* proper */}
          <div className="space__PL" />
          <div style={{ display: 'flex' }}>
            <div className="space__PL" />
            <div style={{ flex: '5', display: 'flex', alignItems: 'center', height: '600px' }}>
              <div className="talks_first__PL">
                <div
                  className="img__container__first__PL"
                  onClick={
                    () => ((groups.isUsertierone)
                      ? navigate('/videoCard', { state: { accessToken: groups.tokens.jwtAccessToken } })
                      : navigate('/payForTier1'))
                    }
                >
                  { (!groups.isUsertierone)
                  && (
                  <div className="overlay__talks_first__PL">
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                  )}
                  <div className="overlay__rightbottom__PL">50 min</div>
                  <img src={images.AArt1} />
                </div>
                <div>
                  <div className="one__PL">
                    <h2 className="textOne__PL"> Platform hosting the free and paid content</h2>
                    <h4 className="textTwo__PL">This is a platform where a logged-in user can view free content. To view paid content, a logged-in user is required to pay via a payment gateway. The content can be images, videos or data files.</h4>
                    <h4 className="textTwo__PL" style={{ color: '#873600' }}>Host your paid content with us in SaaS setup. Content can be online courses, art and more.</h4>
                    <br />
                    <div className="one_one__PL">
                      <div className="one_one_one__PL">
                        <h6 className="tagSA__PL"> Serverless Architectures </h6>
                        <h6 className="tagIOS__PL"> iOS App </h6>
                        <h6 className="tagRWA__PL"> ReactJS WebApp </h6>
                        <h6 className="tagBUY__PL"> Available to Buy (Rs6000, $70). Quickly ship your own brand online. </h6>
                      </div>
                      <div className="one_one_two__PL tagVD__PL">
                        <h6> VD123, Jan 22</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space__PL" />
            <div style={{ flex: '5', display: 'flex', alignItems: 'center', height: '600px' }}>
              <div className="talks_first__PL">
                <div
                  className="img__container__first__PL"
                  onClick={
                    () => ((groups.isUsertierone)
                      ? navigate('/videoCard', { state: { accessToken: groups.tokens.jwtAccessToken } })
                      : navigate('/payForTier1'))
                    }
                >
                  { (!groups.isUsertierone)
                  && (
                  <div className="overlay__talks_first__PL">
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                  )}
                  <div className="overlay__rightbottom__PL">50 min</div>
                  <img src={images.AArt1} />
                </div>
                <div>
                  <div className="one__PL">
                    <h2 className="textOne__PL"> Platform hosting the free and paid content</h2>
                    <h4 className="textTwo__PL">This is a platform where a logged-in user can view free content. To view paid content, a logged-in user is required to pay via a payment gateway. The content can be images, videos or data files.</h4>
                    <h4 className="textTwo__PL" style={{ color: '#873600' }}>Host your paid content with us in SaaS setup. Content can be online courses, art and more.</h4>
                    <br />
                    <div className="one_one__PL">
                      <div className="one_one_one__PL">
                        <h6 className="tagSA__PL"> Serverless Architectures </h6>
                        <h6 className="tagIOS__PL"> iOS App </h6>
                        <h6 className="tagRWA__PL"> ReactJS WebApp </h6>
                        <h6 className="tagBUY__PL"> Available to Buy (Rs6000, $70). Quickly ship your own brand online. </h6>
                      </div>
                      <div className="one_one_two__PL tagVD__PL">
                        <h6> VD123, Jan 22</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space__PL" />
              </div>
            </div>
            <div className="space__PL" />
          </div>
          {/* <div className="talks_second" onClick={() => navigate('/talkTwo')}> */}
          <div className="talks_second__PL">
            <div className="talks_second_one__PL tooltip__PL">
              <span className="tooltiptext__PL"> Available Dec2024 </span>
              <div className="img__container__PL">
                { (!groups.isUsertierone)
                  && (
                  <div className="overlay__topleft__PL">
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                  )}
                <div className="overlay__rightbottom__PL">20 min</div>
                <img src={images.AArt2} />
              </div>
              <div className="two__PL">
                <h2 className="textOne__PL"> Improved SEO for your App</h2>
                <h4 className="textTwo__PL"> This solution helps you rank your sebsite or mobile app to rank higher on search engine results.</h4>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h4 className="tagSA__PL"> Serverless Architectures </h4>
                </div>
                <div className="one_one_two__PL tagVD__PL">
                  <h4> VD124, Jan 22</h4>
                </div>
              </div>
            </div>
            <div className="talks_second_two__PL tooltip__PL">
              <span className="tooltiptext__PL"> Available Dec2024 </span>
              <div className="img__container__PL">
                <div className="overlay__rightbottom__PL">20 min</div>
                <img src={images.AArt3} />
              </div>
              <div className="three__PL">
                <h2 className="textOne__PL"> Present your products in 3D to customers</h2>
                <h4 className="textTwo__PL"> This app will let you present your products in 3D. Rendering will adapt to viewers seeing it on a 2D or 3D device. This is aplicable to a range of products and medical anatomy, construction etc.</h4>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h4 className="tagIOS__PL"> iOS App </h4>
                  <h4 className="tagVOS__PL"> VisionOS, AR,VR </h4>
                </div>
                <div className="one_one_two__PL tagVD__PL">
                  <h4> VD125, Jan 22</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="space__PL" />
          <div className="talks_third__PL">
            <div>
              <div className="talks_third_one__PL tooltip__PL">
                <span className="tooltiptext__PL"> Available Dec2024 </span>
                <div className="img__container__PL">
                  <div className="overlay__rightbottom__PL">20 min</div>
                  <img src={images.AArt4} />
                </div>
                <div className="four__PL">
                  <h2 className="textOne__PL"> Sell your products online on iOS app</h2>
                  <h4 className="textTwo__PL"> iOS app that helps you connect to customers on iPhone and let them do online shopping.</h4>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 className="tagSA__PL"> Serverless Architectures </h4>
                    <h4 className="tagIOS__PL"> iOS App </h4>
                  </div>
                  <div className="one_one_two__PL tagVD__PL">
                    <h4> VD126, Jan 22</h4>
                  </div>
                </div>
              </div>
              <div className="talks_third_two__PL tooltip__PL">
                <span className="tooltiptext__PL"> Available Dec20024 </span>
                <div className="img__container__PL">
                  <div className="overlay__rightbottom__PL">20 min</div>
                  <img src={images.AArt5} />
                </div>
                <div className="five__PL">
                  <h2 className="textOne__PL"> Virtual meeting with two remote participants and an interactive virtual object.</h2>
                  <h4 className="textTwo__PL"> A machine and its operating instructions can be discussed between two remote people. Discussion will be on mobile device that will show a virtual machine to participants. Virtual machine is single entity and can be interacted by anyone. In a different form, this solution can be easily adapted for a patient and remote doctor in case of emergency situations.</h4>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 className="tagIOS__PL"> iOS App </h4>
                    <h4 className="tagVOS__PL"> VisionOS, AR,VR </h4>
                  </div>
                  <div className="one_one_two__PL tagVD__PL">
                    <h4> VD127, Jan 22</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space__PL" />
        </div>
      </div>
      <SocialHandles />
    </div>
  );
};
//
export default ProductsList2;
