/*  eslint-disable no-unused-vars */
import React from 'react';
import PDFViewer from './PDFViewer';

function PDFViewerModal({ curriculum, setCurriculum, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  return (
    <div>
      <PDFViewer curriculum={curriculum} setCurriculum={setCurriculum} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
    </div>
  );
}

export default PDFViewerModal;
/*  eslint-enable no-unused-vars */
