import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAws } from '@fortawesome/free-brands-svg-icons';
import { faAngleDoubleUp, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import { SubHeading } from '../../../components';
import { images } from '../../../constants';
import './AwsHeader.css';
import '../Header.css';

//  eslint-disable-next-line arrow-body-style
const AwsHeader = () => {
  const navigate = useNavigate();
  return (
    //  gap: '4rem' is extra here
    <div id="aws">
      {/* <div className="app__fillerForNavbar" /> */}
      <div className="app__headerAWS app__wrapper section__padding" style={{ gap: '4rem' }}>
        <div className="app__wrapper_img">
          <div className="awsMobileBackendImage">
            {/* <div>Mobile Backend: Serverless Lens for Well Architected Framework</div> */}
            <div />
            <img src={images.awsServerless} alt="header_img" />
          </div>
        </div>

        <div className="app__wrapper_info">
          <SubHeading title="Scalable products and infrastructure" />
          <div className="swiftLogo">
            <FontAwesomeIcon className="awsLogoIcon" icon={faAws} />
            {/* <h1 className="app__header-h1">AWS</h1> */}
          </div>
          <p className="p__opensans" style={{ margin: '2rem 0' }}>Serverless architecture enable faster innovation and retained focus of team on the core product instead of worrying about managing and operating servers or runtimes. Build great products, microservices, web or mobile apps. </p>
          <div className="app__header_buttons">
            {/* <div className="exploreTrack" onClick={() => navigate('/talks')}> */}
            <div className="exploreTrack2 tooltip__HR" style={{ cursor: 'not-available' }}>
              <span className="tooltiptext__HR"> Available Dec2024 </span>
              {/* <div>Explore</div> */}
              <div className="exploreTrack">
                <div>Posts</div>
                <FontAwesomeIcon className="angleRightLogo" icon={faAngleDoubleRight} />
              </div>
            </div>
            <FontAwesomeIcon onClick={() => { navigate('/'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} className="angleUpLogo" icon={faAngleDoubleUp} />
            {/* <button type="button" onClick={() => navigate('/talks')} className="custom__button">Explore Track</button> */}
            {/* <button type="button" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="custom__button">Back to Top</button> */}
          </div>
        </div>

      </div>
    </div>
  );
};

export default AwsHeader;
