import React from 'react';
import './PayuPostForm.css';

const PayuPostForm = () => (
  <div style={{ border: '2px solid purple' }}>
    Payment available from Dec2024
  </div>
);

export default PayuPostForm;
