import React from 'react';
//  eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import { AboutUs, FindUs, SwiftHeader, GenAIHeader, AwsHeader, IntroHeader, ProductsHeader } from './container';
// import { AboutUs, FindUs, SwiftHeader, SwiftUIHeader, VisionOSHeader, AwsHeader, IntroHeader, WorkshopsHeader, ProductsHeader /* , Careers */ } from './container';
import { Navbar, SocialHandles } from './components';
import './App.css';

const App = () => (
  <div>
    <div>
      <Navbar />
      <IntroHeader />
      <ProductsHeader />
      <AwsHeader />
      <GenAIHeader />
      <SwiftHeader />
      {/* <SwiftUIHeader /> */}
      {/* <VisionOSHeader /> */}
      {/* <WorkshopsHeader /> */}
      <FindUs />
      <AboutUs />
      {/* <Careers /> */}
      <SocialHandles />
    </div>
  </div>
);

export default App;
//  export default withAuthenticator(App);
