import React from 'react';
import './MarketingTools.css';
import MarketingTool1 from './MarketingTool1';
import MarketingTool2 from './MarketingTool2';

function MarketingTools() {
  return (
    <div className="MT">
      <div className="MT_Header">
        Marketing Tools
      </div>
      <div className="MT_Body">
        <div className="MT_Body_A1">
          <MarketingTool1 />
        </div>
        <div className="MT_Body_A1">
          <MarketingTool2 />
        </div>
      </div>
    </div>
  );
}

export default MarketingTools;
