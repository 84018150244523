import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { SubHeading } from '../../../components';
import { images } from '../../../constants';
import './IntroHeader.css';

const IntroHeader = () => (
  //  gap: '4rem' is extra here
  <div id="intro">
    {/* <div className="app__fillerForNavbar" /> */}
    <div className="app__headerIntro app__wrapper section__padding">
      <div className="app__wrapper_img">
        <div className="introImage">
          {/* <div>Mobile Backend: Serverless Lens for Well Architected Framework</div> */}
          {/* <div style={{ width: 130, height: 130 }}> */}
          <div className="introImage1">
            <img src={images.Logo1} alt="header_img" />
          </div>
          {/* <div style={{ width: 500, height: 150 }}> */}
          <div className="introImage2">
            <img src={images.CurvSortOliveText} alt="header_img" />
          </div>
        </div>
      </div>

      <div className="app__wrapper_info_Intro">
        {/*
        <h3 style={{ color: 'green', border: '1.5px solid green', padding: '2px' }}>Launch Date: 01 July 2024</h3>
        <p style={{ margin: '2rem 0' }} />
        */}
        <SubHeading title="Consultation" />
        {/* <SubHeading title="Indie App Development" /> */}
        <SubHeading title="Product Development" />
        {/* <SubHeading title="Conducting Workshops" /> */}
        {/* <SubHeading title="Sharing Knowledge" /> */}
        <p className="p__opensans" style={{ margin: '2rem 0' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ marginRight: '25px' }}> Domains: </div>
            <div style={{ marginRight: '10px' }}> Serverless Architectures (AWS) </div>
            <div style={{ marginRight: '10px' }}> - </div>
            <div style={{ marginRight: '10px' }}> Generative AI, Machine Leaning (AWS, Apple) </div>
            <div style={{ marginRight: '10px' }}> - </div>
            <div style={{ marginRight: '10px' }}> Apple Tech (Intelligence, Audio, Video, Speech) </div>
            {/* <div style={{ marginRight: '10px' }}> iOS apps (Swift, SwiftUI, AWS) </div> */}
            {/* <div style={{ marginRight: '10px' }}> - </div> */}
            {/* <div style={{ marginRight: '10px' }}> Spatial Computing (VisionOS, AR, VR) </div> */}
          </div>
        </p>
        <FontAwesomeIcon className="angleDownLogo" icon={faAngleDoubleDown} />
      </div>

    </div>
  </div>
);

export default IntroHeader;
