/*  eslint-disable no-unused-vars */
import React from 'react';
import QuizList from './QuizList';
import './EducationalToolsList.css';

function EducationalToolsList({ curriculum, setCurriculum, clCardItem, setClCardItem, lessonParent, isUploadingViaAp, setIsUploadingViaApi }) {
  return (
    <div className="ETLST">
      <div className="ETLST_Header">
        EducationalTools
      </div>
      <div className="ETLST_Body">
        <div className="ETLST_Body_A1">
          <QuizList />
        </div>
      </div>
    </div>
  );
}

export default EducationalToolsList;
/*  eslint-enable no-unused-vars */
