/*  eslint-disable no-unused-vars */
import React from 'react';
import './EPClassroomLesson2.css';
import EPLessonVideo from './LessonItems/EPLessonVideo';
import EPLessonRichText from './LessonItems/EPLessonRichText';
import EPLessonPDF from './LessonItems/EPLessonPDF';

function EPClassroomLesson2(props) {
  const { page, setPage, isUploadingViaApi, setIsUploadingViaApi, enrolledCurriculum, setEnrolledCurriculum, enrolledAttributes, setEnrolledAttributes, userTokens } = props;
  const sectionIndex = enrolledAttributes.EnrolledCourse.userCursor.section;
  const lessonIndex = enrolledAttributes.EnrolledCourse.userCursor.lesson;
  // eslint-disable-next-line prefer-destructuring
  const noOfVideos = enrolledCurriculum.sectionList[sectionIndex - 1].lessonList[lessonIndex - 1].assets.video.length;
  const noOfRTs = enrolledCurriculum.sectionList[sectionIndex - 1].lessonList[lessonIndex - 1].assets.richText.length;
  const noOfPDFs = enrolledCurriculum.sectionList[sectionIndex - 1].lessonList[lessonIndex - 1].assets.pdf.length;
  console.log('EPClassroomLesson2', noOfVideos, noOfRTs, noOfPDFs, enrolledCurriculum);
  console.log('userTokens: ', userTokens);
  return (
    <div className="CLASS_LESSON">
      {((noOfVideos === 0) && (noOfRTs === 0) && (noOfPDFs === 0))
      && (
      <div className="CLASS_LESSON_4">
        Empty Lesson - No video, rich-text or pdfs.
      </div>
      )}

      { (noOfVideos > 0)
      && (
      <div className="CLASS_LESSON_1">
        <EPLessonVideo page={page} setPage={setPage} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} enrolledCurriculum={enrolledCurriculum} setEnrolledCurriculum={setEnrolledCurriculum} enrolledAttributes={enrolledAttributes} setEnrolledAttributes={setEnrolledAttributes} userTokens={userTokens} />
      </div>
      )}

      { (noOfRTs > 0)
      && (
      <div className="CLASS_LESSON_2">
        <EPLessonRichText page={page} setPage={setPage} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} enrolledCurriculum={enrolledCurriculum} setEnrolledCurriculum={setEnrolledCurriculum} enrolledAttributes={enrolledAttributes} setEnrolledAttributes={setEnrolledAttributes} userTokens={userTokens} />
      </div>
      )}

      { (noOfPDFs > 0)
      && (
      <div className="CLASS_LESSON_3">
        <EPLessonPDF page={page} setPage={setPage} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} enrolledCurriculum={enrolledCurriculum} setEnrolledCurriculum={setEnrolledCurriculum} enrolledAttributes={enrolledAttributes} setEnrolledAttributes={setEnrolledAttributes} userTokens={userTokens} />
      </div>
      )}
    </div>
  );
}

export default EPClassroomLesson2;
/*  eslint-enable no-unused-vars */
