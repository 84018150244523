import React from 'react';
import './Surl.css';

const Surl = ({ response }) => (
  <div className="surl">
    {console.debug('Surl')}
    Your transaction is successful
    <div>
      {response}
    </div>
  </div>
);

export default Surl;
