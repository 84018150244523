import React, { useState, useEffect } from 'react';
import { Authenticator, View, Button, ThemeProvider, useTheme } from '@aws-amplify/ui-react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import awsExports from '../../aws-exports';
import FetchGroups from '../BackendServices/FetchGroups';
import './Login2.css';
import FetchGroups2 from '../BackendServices/FetchGroups2';

//  const AWS = require('aws-sdk');

Amplify.configure(awsExports);
// import Navbar from '../Navbar/Navbar';

export default function Login2({ onClose, setAmplifyUser }) {
  //  let userCheckGroups = {};
  const [isLoading, setIsloading] = useState(true);
  const [groups, setGroups] = useState({});
  /*
  const fetchJwtToken = async () => {
    try {
      console.log('fetchJwtToken1');
      const session = await Auth.currentSession();
      const accesstoken = session.getAccessToken().getJwtToken();
      const idtoken = session.getIdToken().getJwtToken();
      const userInfo = await Auth.currentUserInfo();
      const authUser = await Auth.currentAuthenticatedUser();
      setJwtAccessToken(accesstoken);
      console.log('fetchJwtToken2', jwtAccessToken);
      console.log('jwt ID token', idtoken);
      console.log('session', session);
      console.log('currentAuthenticatedUser', authUser);
      console.log('userPoolId', authUser.pool.userPoolId);
      console.log('clientId', authUser.pool.clientId);
      console.log('currentUserInfo', userInfo);
      console.log('username', userInfo.username);
    } catch (error) {
      setAmplifyUser('Login');
      console.log('Error fetching JWT token:', error);
    }
  };
  */

  /*
    [] During signUp: user is still not baked in aws backend and there will be an error
       "user not found", which should be caught and ignored just fine.
    [] During login: user is already baked in aws backend and fetching it should not be
       a problem. This will be used to display username or Login in navbar right side.
       If somebody is not yet logged in, FetchGroups result must be used with caution as
       key undefined error will show up.

    Wrong: FetchJwtToken() can be called only after "await Auth.signUp()" is done, and
    definitely not in useEffect [].
  */
  /*
  useEffect(() => {
    (async () => {
      console.log('useEffect in Login2.js');
      const grps = await FetchGroups(); //  fresh fetch is only reqd after payment. SignIn, SignUp should still be okay with cached response of FetchGroups().
      if (grps.tokens.jwtAccessToken === '') {
        setAmplifyUser('Login'); // causes component update
      } else {
        setAmplifyUser(grps.tokens.useremail.split('@')[0]);
        //  setUser(userCheckGroups.tokens.useremail.split('@')[0]);
      }
      setGroups(grps); // not set immediately and access to groups.tokens.jwtAccessToken can crash saying not defined
      setIsloading(false); // only when this is done, rerender will happen, and main return will be executed. That time groups.tokens.jwtAccessToken will be accessible.
      console.log('groups:', groups, ', set to grps:', grps);
    })();
  }, []); //  empty array: dont run hook on every update, run only for the mounting of component.
  */

  /*
  useEffect(() => {
    (async () => {
      console.log('useEffect');
      //  fetchJwtToken();
      const tokens = await FetchJwtToken();
      if (tokens.jwtAccessToken === '') {
        setAmplifyUser('Login');
      } else {
        setJwtAccessToken(tokens.jwtAccessToken);
      }
    })();
  }, []);
  */

  /*
  const fetchJwtToken2 = async () => {
    try {
      console.log('fetchJwtToken2');
      const session = await Auth.currentSession();
      const accesstoken = session.getAccessToken().getJwtToken();
      const idtoken = session.getIdToken().getJwtToken();
      const userInfo = await Auth.currentUserInfo();
      const authUser = await Auth.currentAuthenticatedUser();
      console.log('fetchJwtToken2', accesstoken);
      console.log('jwt ID token', idtoken);
      console.log('session', session);
      console.log('currentAuthenticatedUser', authUser);
      console.log('userPoolId', authUser.pool.userPoolId);
      console.log('clientId', authUser.pool.clientId);
      console.log('currentUserInfo', userInfo);
      console.log('username', userInfo.username);
      return {
        jwtAccessToken: accesstoken,
        jwtIdToken: idtoken,
        username: userInfo.username,
        userPoolID: authUser.pool.userPoolId,
        clientid: authUser.pool.clientId,
      };
    } catch (error) {
      console.log('Error fetching JWT token:', error);
    }
    return {};
  };
  */

  const navigate = useNavigate();
  const components = {
    SignIn: {
      Footer() {
        // const { toResetPassword } = useAuthenticator();
        return (
          <View textAlign="center">
            <Button fontWeight="normal" onClick={() => navigate(-1)} size="small">
              Cancel
            </Button>
          </View>
        );
      },
    },
  };

  //  eslint-disable-next-line no-unused-vars
  const callIdempotentPostSignUpFunction1 = async () => {
    console.debug('callPostSignUpFunction1');
    const grps = await FetchGroups();
    //  userCheckGroups = await FetchGroups();
    //  console.debug('userCheckGroups: ', userCheckGroups);
    //  setIsloading(false);
    if (grps.tokens.jwtAccessToken === '') {
      setAmplifyUser('Login'); // causes component update
    } else {
      setAmplifyUser(grps.tokens.useremail.split('@')[0]);
      //  setUser(userCheckGroups.tokens.useremail.split('@')[0]);
      setGroups(grps);
    }
  };

  //  eslint-disable-next-line no-unused-vars
  const callIdempotentPostSignUpFunction = async () => {
    // make request to APIGW
    console.debug('callPostSignUpFunction');

    const session = await Auth.currentSession();
    console.debug('session', session);

    //  const jwtTokens = await fetchJwtToken2();
    const grp = await FetchGroups();
    const jwtTokens = grp.tokens; //  await FetchJwtToken();
    console.debug('jwtTokens', jwtTokens);

    //  Express will need regionID and username while invoking confirmSNSSubscription
    const serverUrl = 'http://localhost:3300/jwttokens';
    const res1 = await axios.post(serverUrl, jwtTokens);
    console.debug('Response from calling axios.post to server url ', serverUrl, ' : ', res1);

    //  invoke Lambda which in turn will invoke Express confirmSNSSubscription
    const baseURL = `https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/user/${jwtTokens.userPoolID}/${jwtTokens.username}/subscribeSNS`;
    const response = await axios.post(baseURL, {
      headers: {
        //  "Content-Type": "text/json",
        // Authorization: `Bearer ${jwtTokens.jwtAccessToken}`,
        Authorization: `${jwtTokens.jwtIdToken}`,
      },
    });
    console.debug('axios.post() response: ', response);
  };

  /* aws ticket 14304836441 */
  /*
  Hub.listen('auth', async (data) => {
    console.log('data:', data);
    switch (data?.payload?.event) {
      case 'autoSignIn': {
        //  occuring Y times, Y is varying - sometimes 1, sometimes 7, sometimes other.  calls here must be idempotent
        //  perform additional logic after sign up successful
        //
        //  data.payload: {event: 'autoSignIn', data: CognitoUser, message: 'blume0000@yahoo.com has signed in successfully'} ,
        //  data.payload.data: CognitoUser {username: 'ebe11e78-6735-4621-baf8-0fc19d32ea3d', pool: CognitoUserPool, Session: null, client: Client, signInUserSession: CognitoUserSession, …}
        //
        console.log('RRR data.payload:', data.payload, ', data.payload.data:', data.payload.data);
        //  callIdempotentPostSignUpFunction();
        callIdempotentPostSignUpFunction1();
        break;
      }
      default: {
        console.log('default case');
        break;
      }
    }
  });
  */

  useEffect(() => {
    (async () => {
      await FetchGroups2(setAmplifyUser, setGroups, setIsloading, true); // fresh=true is required because after logout the name should stay same as Login and not emailid.
    })();
  //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* aws ticket 14304836441 */
  useEffect(() => {
    const listenerCancel = Hub.listen('auth', async (data) => {
      console.debug('data:', data);
      console.count('Hub Auth event calls');
      switch (data?.payload?.event) {
        case 'autoSignIn': {
          console.debug('RRR data.payload:', data.payload, ', data.payload.data:', data.payload.data);
          //  callIdempotentPostSignUpFunction();
          console.count('autoSignIn calls');
          callIdempotentPostSignUpFunction1();
          break;
        }
        default: {
          console.debug('default case');
          break;
        }
      }
    });
    return () => {
      listenerCancel();
    };
  //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serv = {
    async handleSignUp(formData) {
      //  { // perform the logic before entering the verification code.

      //  }
      //  eslint-disable-next-line prefer-const
      let { username, password, attributes } = formData;
      // custom username
      username = username.toLowerCase();
      attributes.email = attributes.email.toLowerCase();
      console.debug('formData', formData, username);
      const response = Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      });
      console.debug('Auth.signUp() response', response);
      return response;
    },
  };

  /*
  const services = {
    async handleConfirmSignUp(formData) {
      try {
        console.log('handleConfirmSignUp');
        const { username, code } = formData;
        const res = await Auth.confirmSignUp(username, code);
        console.log('response of Auth.confirmSignUp: ', res);
        {
          console.log('your custom code to add other functionality.');
          //  rest api - axios - post endpoint - /user/<regionID>/<userid>/subscribeSNS
          //  lambda - subscribe() endpoint - express - confirmSubscribe axios call
          //  /user/<regionID>/<userid>/confirmSubscribeSNS  -  confirmSNS(token)
          const jwtTokens = await fetchJwtToken2();
          const regionID = '1111';
          const baseURL = `https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/user/${regionID}/${username}`;
          const response = await axios.post(baseURL, {
            headers: {
              //  "Content-Type": "text/json",
              Authorization: `Bearer ${jwtTokens.jwtAccessToken}`,
            },
          });
          console.log('response: ', response);
        }
      } catch (error) {
        console.log('error confirming sign up', error);
      }
    },
    async handleSignUp(formData) {
      const { username, password, attributes } = formData;
      // custom username
      //  const usern = username.toLowerCase();
      //  console.log('your custom code to add other functionality.', formData);
      //  console.log('usern', usern);
      attributes.email = attributes.email.toLowerCase();
      const res = await Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      });
      console.log('handleSignUp', res);
      //  {
      //  console.log('your custom code to add other functionality.');
      //  rest api - axios - post endpoint - /user/<regionID>/<userid>/subscribeSNS
      //  lambda - subscribe() endpoint - express - confirmSubscribe axios call
      //  /user/<regionID>/<userid>/confirmSubscribeSNS  -  confirmSNS(token)
      //  }
      return res;
    },
  };

  /*
  const globalSignOut = async (jwtAccessToken2) => {
    const cognitoidentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
      region: 'ap-south-1',
      apiVersion: '2016-04-18',
      logger: console,
    });
    const params = {
      AccessToken: jwtAccessToken2,
    };
    console.log('globalSignOut', jwtAccessToken2);
    cognitoidentityServiceProvider.globalSignOut(params, (err, data) => {
      if (err) {
        console.log('err:', err, err.stack); // an error occurred
      } else {
        console.log('data:', data);
      }
    });
  };
  */

  const { tokens } = useTheme();
  const theme = {
    name: 'Auth Example Theme',
    width: '600px',
    height: '500px',
    background: 'yellow',
    border: '2px solid red',
    tokens: {
      colors: {
        background: {
          primary: {
            value: tokens.colors.neutral['90'].value,
          },
          secondary: {
            value: tokens.colors.neutral['100'].value,
          },
        },
        font: {
          interactive: {
            value: tokens.colors.white.value,
          },
        },
        brand: {
          primary: {
            10: tokens.colors.teal['100'],
            80: tokens.colors.teal['40'],
            90: tokens.colors.teal['20'],
            100: tokens.colors.teal['10'],
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: tokens.colors.white.value,
              },
            },
            _hover: {
              color: {
                value: tokens.colors.yellow['80'].value,
              },
            },
            _active: {
              color: {
                value: tokens.colors.white.value,
              },
            },
          },
        },
      },
    },
  };

  /*
    Without this, in the main return groups.tokens.jwtAccessToken is accessed and crashes
    as jwtAccessToken is unavailable from the call to FetchGroups() in useEffect
  */
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <div className="one">
      <div className="oneOne" />
      <div className="containerLogin2">
        <ThemeProvider theme={theme}>
          <Authenticator services={serv} components={components}>
            {/* {({ signOut, user }) => ( */}
            {/* eslint-disable-next-line no-unused-vars */}
            {({ signOut, user }) => (
              <main>
                {console.debug('user', user)}
                <h1>Hello {user.attributes.email.split('@')[0]}</h1>
                {/* <button type="button" onClick={() => { console.debug('ayyy'); signOut(); navigate(-1); }}>Sign out</button> */}
                <button
                  type="button"
                  onClick={async () => {
                    onClose();
                    //  signOut();
                    await Auth.signOut();
                    //  await globalSignOut(user.getSignInUserSession().getAccessToken().getJwtToken()); // calling this here seems optional
                    console.debug('globalSignOut() done. Now setting user to Login');
                    setAmplifyUser('Login'); // any subsequent execution of FetchGroups() will retrieve the username properly again from aws, hence need to guard this logic in application side itself. How?
                    navigate('/');
                    console.debug('N2');
                  }}
                >
                  Sign out
                </button>
                {console.debug(user)}
                {setAmplifyUser(user.attributes.email)}
                <h4 className="wrappedContent">
                  Your JWT ID token:{groups.tokens.jwtIdToken}
                </h4>
                <h4 className="wrappedContent">
                  Your JWT Access token:{groups.tokens.jwtAccessToken}
                </h4>
              </main>
            )}
          </Authenticator>
        </ThemeProvider>
      </div>
      <div className="oneTwo" />
    </div>
  );
}
