/*  eslint-disable padded-blocks, prefer-destructuring, no-unused-vars */
import axios from 'axios';
import FetchGroups from './FetchGroups';

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

async function sha512(string) {
  const utf8 = new TextEncoder().encode(string);
  await timeout(1000);
  return crypto.subtle.digest('SHA-512', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  });
}

const GetAsset = async ({ key, action }) => {
  console.log('GetAsset', key, action);
  const toks = await FetchGroups(true);
  const reqObjVC = {
    key: 'Z4iLcO',
    SALT: '112233',
    name: 'video/vid1.mov',
  };
  // await sha512(`${reqObjVC.key}|${reqObjVC.SALT}|${reqObjVC.name}`).then((hcode) => setHash1(hcode));
  const hash1 = await sha512(`${reqObjVC.key}|${reqObjVC.SALT}|${reqObjVC.name}`);
  console.log('hash1:', hash1);
  const baseURL = `https://d2tc0jno74e7ty.cloudfront.net/${key}?Authorization=Bearer ${toks.tokens.jwtAccessToken}&hash=${hash1}`;
  let apiResponse = '';
  apiResponse = await axios.get(baseURL)
  //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
      return error;
    });
  // console.log('axios.get() response: ', apiResponse);
  return apiResponse;
};

const AssetsApi = async (props) => {
  console.log('AssetsApi:', props);
  const { action, key } = props;

  let apiResp = '';

  if ((action === 'GET_RICH_TEXT')
    || (action === 'GET_PDF')) {
    apiResp = GetAsset({ key, action });
  } else {
    console.log('Unknown action: ', action, props);
  }

  return apiResp;
};

export default AssetsApi;
