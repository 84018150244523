import React from 'react';
import YetToBeImplemented from './YetToBeImplemented/YetToBeImplemented';

function CodeExampleModal({ curriculum, setCurriculum, clCardItem, setClCardItem, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  return (
    <div>
      <YetToBeImplemented curriculum={curriculum} setCurriculum={setCurriculum} clCardItem={clCardItem} setClCardItem={setClCardItem} lessonParent={lessonParent} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} />
    </div>
  );
}

export default CodeExampleModal;
