import React from 'react';
//  eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import FetchJwtToken from '../../BackendServices/FetchJwtToken';

const AWS = require('aws-sdk');

const cognitoidentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
  region: 'ap-south-1',
  apiVersion: '2016-04-18',
  logger: console,
});

const cognitoidentity = new AWS.CognitoIdentity({
  region: 'ap-south-1',
  apiVersion: '2016-04-18',
  logger: console,
});
const stsClient = new AWS.STS({
  region: 'ap-south-1',
  apiVersion: '2016-04-18',
  logger: console,
});

const cfClient = new AWS.CloudFront({
  region: 'ap-south-1',
  apiVersion: '2016-04-18',
  logger: console,
});

const initiateAuth = () => {
  console.log('initiateAuth');
  const param = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    AuthParameters: {
      USERNAME: 'rejji_01@yahoo.com',
      PASSWORD: 'HelloThere',
    },
    ClientId: '5ifq1ckvj1cu4d8nifme36ja6m',
  };
  return cognitoidentityServiceProvider.initiateAuth(param).promise();
};

const getId = (idToken) => {
  console.debug('getId');
  const param = {
    IdentityPoolId: 'ap-south-1:e672ed93-e79f-4274-b4fc-2ee489eb661d',
    //  IdentityPoolId: 'ap-south-1:87804342-d8d0-41cb-b437-48dbdf7bb33b',
    AccountId: '791455415518',
    Logins: {
      'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_nwLPCJQt7': idToken,
    },
  };
  return cognitoidentity.getId(param).promise();
};

const getCredentialsForIdentity = (identityId, idToken) => {
  console.debug('getCredentialsForIdentity');
  const params = {
    IdentityId: identityId,
    Logins: {
      'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_nwLPCJQt7': idToken,
    },
  };
  return cognitoidentity.getCredentialsForIdentity(params).promise();
};

const getCallerIdentity = () => {
  console.debug('getCallerIdentity');
  const params = {
  };
  let res;
  try {
    res = stsClient.getCallerIdentity(params).promise();
  } catch (e) {
    console.debug('exception in sts getCallerIdentity() ', e);
  }
  return res;
};

const cfListDistributions = () => {
  console.debug('cfListDistributions');
  const params = {
  };
  let res;
  try {
    res = cfClient.listDistributions(params).promise();
  } catch (e) {
    console.debug('exception in cfClient.listDistributions() ', e);
  }
  return res;
};

async function movefn() {
  /*
  const fetchJwtToken = async () => {
    let token = '';
    try {
      console.log('fetchJwtToken1');
      const session = await Auth.currentSession();
      token = session.getIdToken().getJwtToken();
      //  console.log('fetchJwtToken2', token);
      const currentUserCredentials = await Auth.currentUserCredentials();
      //  console.log('currentUserCredentials', currentUserCredentials);
      console.log(currentUserCredentials.accessKeyId, currentUserCredentials.secretAccessKey, currentUserCredentials.sessionToken, currentUserCredentials.identityId);
      const credResponse = await Auth.currentCredentials();
      //  console.log('credResponse', credResponse);
      console.log(credResponse.accessKeyId, credResponse.secretAccessKey, credResponse.sessionToken, credResponse.identityId);
    } catch (error) {
      //  setAmplifyUser('Login');
      console.log('Error fetching JWT token:', error);
    }
    return token;
  };
  */
  const tokenSet = await FetchJwtToken();
  const token = tokenSet.jwtAccessToken;
  const decoded = jwt_decode(token);
  console.debug('jwt_decode', decoded);

  /*
  const credentials = fromProcess({
    // Optional. The configuration profile to use. If not specified, the provider will use the value
    // in the `AWS_PROFILE` environment variable or a default of `default`.
    profile: 'profile',
    // Optional. The path to the shared credentials file. If not specified, the provider will use
    // the value in the `AWS_SHARED_CREDENTIALS_FILE` environment variable or a default of
    // `~/.aws/credentials`.
    filepath: '~/.aws/credentials',
    // Optional. The path to the shared config file. If not specified, the provider will use the
    // value in the `AWS_CONFIG_FILE` environment variable or a default of `~/.aws/config`.
    configFilepath: '~/.aws/config',
  });
  console.log('credentials: ', credentials);
  */

  const initiateAuthResponse = await initiateAuth();
  const idToken2 = initiateAuthResponse.AuthenticationResult.IdToken;
  //  const accessToken2 = initiateAuthResponse.AuthenticationResult.AccessToken;
  console.debug('initiateAuthResponse', initiateAuthResponse);
  //  console.log(idToken2);
  //  console.log(accessToken2);

  const idTokenResponse = await getId(idToken2);
  console.debug('getId response', idTokenResponse);
  const identityId = idTokenResponse.IdentityId;

  const getCredentialsResponse = await getCredentialsForIdentity(identityId, idToken2);
  //  const accessKeyId = getCredentialsResponse.Credentials.AccessKeyId;
  //  const secretKey = getCredentialsResponse.Credentials.SecretKey;
  //  const sessionToken = getCredentialsResponse.Credentials.SessionToken;
  console.debug('getCredentialsForIdentityResponse', getCredentialsResponse);
  //  console.log('accessKeyId: ', accessKeyId);
  //  console.log('secretKey: ', secretKey);
  //  console.log('sessionToken: ', sessionToken);

  const stsGetCallerIdentityResponse = await getCallerIdentity();
  console.debug('stsGetCallerIdentityResponse', stsGetCallerIdentityResponse);

  const listDistributionsResponse = await cfListDistributions();
  console.debug('listDistributionsResponse', listDistributionsResponse);

  /*
   * Not very sure about following and the parameters to be used
   * Doc is using completion handler. I think it is recommended to use async/await. Since it is not critical will address that afterward.

  //  GetID
  //  (what should be params?)
  //  https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentity.html#getId-property
  const cognitoidentity1 = new AWS.CognitoIdentity();
  const params1 = {
    IdentityPoolId: 'ap-south-1:87804342-d8d0-41cb-b437-48dbdf7bb33b',
    AccountId: '791455415518',
    Logins: {
      'Amazon Cognito user pool': 'arn:aws:cognito-idp:ap-south-1:791455415518:userpool/ap-south-1_nwLPCJQt7',
    },
  };
  //  eslint-disable-next-line prefer-arrow-callback
  cognitoidentity1.getId(params1, function (err, data) {
    console.log('getId');
    if (err) console.log(err, err.stack); // an error occurred
    else console.log(data); // successful response
  });

  //  GetCredentialsForIdentity: this will give AccessKeyId, SecretKey, SessionToken, Expiration
  //  (what should be params?)
  //  https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentity.html#getCredentialsForIdentity-property
  const cognitoidentity2 = new AWS.CognitoIdentity();
  const params2 = {
    IdentityId: 'STRING_VALUE', // required
    CustomRoleArn: 'STRING_VALUE',
    Logins: {
      '<IdentityProviderName>': 'STRING_VALUE',
    },
  };
  //  eslint-disable-next-line prefer-arrow-callback
  cognitoidentity2.getCredentialsForIdentity(params2, function (err, data) {
    console.log('getCredentialsForIdentity');
    if (err) console.log(err, err.stack); // an error occurred
    else console.log(data); // successful response
  });
  cognitoidentity2.getCredentialsForIdentity(params2).promise();

  //Use these variables in next api calls

  //STS.GetCallerIdentity (to check the assumed identity)
  // (which variant to be used?)
  //https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/STS.html#getCallerIdentity-property
  var sts = new AWS.STS();
  var params = {
  };
  sts.getCallerIdentity(params, function(err, data) {
    if (err) console.log(err, err.stack); // an error occurred
    else     console.log(data);           // successful response
    //
    //data = {
    // Account: "123456789012",
    // Arn: "arn:aws:sts::123456789012:assumed-role/my-role-name/my-role-session-name",
    // UserId: "AKIAI44QH8DHBEXAMPLE:my-role-session-name"
    //}
    //
  });

  //CloudFront.ListDistributions()
  var cloudfront = new AWS.CloudFront();
  var params = {
    Marker: 'STRING_VALUE',
    MaxItems: 'STRING_VALUE'
  };
  cloudfront.listDistributions(params, function(err, data) {
    if (err) console.log(err, err.stack); // an error occurred
    else     console.log(data);           // successful response
  });
  */
}

const MoveUser = () => (
  <div onClick={async () => { await movefn(); }}>MoveUser</div>
);

export default MoveUser;
