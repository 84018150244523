import React from 'react';
import YetToBeImplemented from '../YetToBeImplemented/YetToBeImplemented';

function CPreviewModal() {
  return (
    <div>
      <YetToBeImplemented />
    </div>
  );
}

export default CPreviewModal;
