import React from 'react';
import './DropdownItem.css';
//  eslint-disable-next-line arrow-body-style
const DropdownItem = ({ children, onClick }) => {
  return (
    <div className="dropdown-item" onClick={onClick}>
      {children}
    </div>
  );
};

export default DropdownItem;
