import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import './EPPublish.css';
import CourseCurriculum from '../../../../../../../../components/BackendServices/CourseCurriculum';

function EPPublish(props) {
  const { page, setPage, setS1Done, setS2Done, setS3Done, setS4Done, curriculum, setCurriculum, isUploadingViaApi, setIsUploadingViaApi } = props;
  console.log(page, setPage, setS1Done, setS2Done, setS3Done, setS4Done, curriculum, setCurriculum, isUploadingViaApi, setIsUploadingViaApi);

  const handleCancel = async () => {
    console.log('handleBack');
    if (curriculum.isCoursePublished) {
      setIsUploadingViaApi(true);
      const updatedCurriculum = curriculum;
      updatedCurriculum.isCoursePublished = false;
      const resp = await CourseCurriculum({ curriculum: updatedCurriculum, action: 'RESET_COURSE_PUBLISH' }); // only rename
      if (resp.status !== 200) {
        console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
      }
      updatedCurriculum.courseID = resp.data.courseID;
      updatedCurriculum.coursePublishedID = resp.data.coursePublishedID;
      await setCurriculum(updatedCurriculum);
      setIsUploadingViaApi(false);
      setS4Done(false);
    }
  };
  const handleSave = async () => {
    console.log('handleContinue');
    if (!curriculum.isCoursePublished) {
      setIsUploadingViaApi(true);
      const updatedCurriculum = curriculum;
      updatedCurriculum.isCoursePublished = true;
      const resp = await CourseCurriculum({ curriculum: updatedCurriculum, action: 'SET_COURSE_PUBLISH' });
      if (resp.status !== 200) {
        console.log('wait for 0.5s, try one more api call (ensure idempotency), if still fails then revert and flash message to user');
      }
      updatedCurriculum.courseID = resp.data.courseID;
      updatedCurriculum.coursePublishedID = resp.data.coursePublishedID;
      await setCurriculum(updatedCurriculum);
      setIsUploadingViaApi(false);
      setS4Done(true);
    }
  };
  function goAddPrice() {
    console.log('goAddPrice');
    setPage('pricing');
  }

  return (
    <div className="EPPUB">

      <div className="EPPR_A20" onClick={goAddPrice}>
        <div className="EPPR_A21">
          Add Price
        </div>
        <div className="EPPR_A22">
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </div>
      </div>

      <div className="EPPUB_A1">
        Publish your course
      </div>

      {!((curriculum.isInstituteNameSaved) && (curriculum.isCourseNameSaved))
        && (
          <div className="unavailable_pricing">
            Institute Name and Course Name are to be saved first. These can be renamed later.
          </div>
        )}

      {!(curriculum.isInstituteNameSaved && curriculum.isCourseNameSaved)
        && (
        <div className="EPPUB_A6">
          <div className="EPPUB_A4">
            <button type="button" className="EPPUB_A4_5_unavailable">
              <span>Unavailable</span>
            </button>
            <button type="button" className="EPPUB_A4_5_unavailable">
              <span>Unavailable</span>
            </button>
          </div>
        </div>
        )}

      {(curriculum.isInstituteNameSaved && curriculum.isCourseNameSaved)
        && (
        <div className="EPPUB_A6">
          { isUploadingViaApi
            && (
            <div className="EPPUB_A4">
              <button type="button" className="EPPUB_A4_5">
                <span>Processing..</span>
              </button>
              <button type="button" className="EPPUB_A4_5">
                <span>Processing...</span>
              </button>
            </div>
            )}
          { (!isUploadingViaApi && !curriculum.isCoursePublished)
            && (
            <div className="EPPUB_A4">
              <button type="button" className="EPPUB_A4_1" onClick={() => handleCancel()}>
                <span>Cancel</span>
              </button>
              <button type="button" className="EPPUB_A4_2" onClick={() => handleSave()}>
                <span>Publish</span>
              </button>
            </div>
            )}
          { (!isUploadingViaApi && curriculum.isCoursePublished)
            && (
            <div className="EPPUB_A4">
              <button type="button" className="EPPUB_A4_4" onClick={() => handleCancel()}>
                <span>Unpublish</span>
              </button>
              <button type="button" className="EPPUB_A4_3">
                <span>Published</span>
              </button>
            </div>
            )}
        </div>
        )}
    </div>
  );
}

export default EPPublish;
