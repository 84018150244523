import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { SubHeading } from '../../../components';
import { images, data } from '../../../constants';
import './WorkshopsHeader.css';
import '../Header.css';

const AwardCard = ({ award: { imgUrl, title, subtitle } }) => (
  <div className="app__laurels_awards-card">
    <img src={imgUrl} alt="awards" />
    <div className="app__laurels_awards-card_content">
      <p className="p__cormorant" style={{ color: '#DCCA87' }}>{title}</p>
      <p className="p__opensans">{subtitle}</p>
    </div>
  </div>
);

//  eslint-disable-next-line arrow-body-style
const WorkshopsHeader = () => {
  const navigate = useNavigate();
  return (
    <div id="workshops">
      {/* <div className="app__fillerForNavbar" /> */}
      <div className="app__headerVOS app__wrapper section__padding">
        <div className="app__wrapper_info">
          <SubHeading title="Workshops that journey from building model to architecture to implementation" />
          <h1 className="headtext__cormorant">Workshops</h1>

          <div className="app__laurels_awards">
            {data.workshops.map((award) => <AwardCard award={award} key={award.title} />)}
          </div>
          <div className="app__header_buttons">
            {/* <div className="exploreTrack" onClick={() => navigate('/talks')}> */}
            <div className="exploreTrack2 tooltip__HR">
              <span className="tooltiptext__HR"> Available Dec2024 </span>
              {/* <div>Explore</div> */}
              <FontAwesomeIcon className="angleRightLogo2" icon={faAngleDoubleRight} />
            </div>
            <FontAwesomeIcon onClick={() => { navigate('/'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} className="angleUpLogo" icon={faAngleDoubleUp} />
            {/* <button type="button" onClick={() => navigate('/talks')} className="custom__button">Explore Track</button> */}
            {/* <button type="button" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="custom__button">Back to Top</button> */}
          </div>
        </div>

        <div className="app__wrapper_img">
          <img src={images.workshop} alt="laurels_img" />
        </div>
      </div>
    </div>
  );
};

export default WorkshopsHeader;
