import React, { useState, useEffect } from 'react';
import { Navbar } from '../../../../../components';
import Sidebar from './Sidebar';
import './EdTech.css';
import EdTechPage from './AddCurriculum/EdTechPage';
import CourseCurriculum from '../../../../../components/BackendServices/CourseCurriculum';

function EdTech() {
  const [page, setPage] = useState('information');
  // Each entity is represented by ID. It carries attributes like Name etc and is searched on corresponding indexes at backend
  const initialInstituteName = 'New Institute Name';
  const initialCurriculum = {
    courseName: 'New Course Name',
    courseID: '', // assigned by backend and retrieved from apis
    isCourseNameSaved: false,
    coursePrice: '100',
    isCoursePriceSaved: false,
    courseCurrency: 'INR',
    isCoursePublished: false,
    instituteName: 'New Institute Name',
    instituteID: '',
    isInstituteNameSaved: false,
    ownerName: 'New Owner Name',
    ownerID: '',
    sectionList: [{
      sectionName: 'New Section Name',
      sectionID: '',
      isSectionNameSaved: false,
      isSectionPublished: false,
      lessonList: [{
        lessonName: 'New Lesson Name',
        lessonID: '',
        isLessonNameSaved: false,
        isLessonPublished: false,
        /*
          Keep only one curriculum in memory for a given user else it grows very large.
          This also allows to keep assets of that curriculum in memory.
          But keeping multiple curriculums in memory can be huge and not doing that in browser is good.
        */
        assets: {
          video: [], // videoName, videoID
          richText: [], // richTextName, richTextID
          pdf: [], // pdfName, pdfID
          audio: [], // audioName, audioID
        },
      }],
    }], //  (re)start with an empty section list
  };
  const [curriculum, setCurriculum2] = useState(initialCurriculum);
  const [selectedCatalogueIndex, setSelectedCatalogueIndex] = useState(100000);
  /*
    isUploadingViaApi is Used to serialize updates on curriculum. Different buttons are
    disabled when one button has started operating/updating thsi common shared data.
    Example: When a section update is being uploaded, a lesson in a different section
    is not allowed. This keeps the design simple and prevent race conditions while
    updating common shared 'curriculum' data object.
  */
  const [isUploadingViaApi, setIsUploadingViaApi] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [enrolledCoursesDoneArray, setEnrolledCoursesDoneArray] = useState([]);
  const [enrolledCoursesProgressingArray, setEnrolledCoursesProgressingArray] = useState([]);
  const [enrolledAttributes, setEnrolledAttributes2] = useState();
  const [enrolledCurriculum, setEnrolledCurriculum] = useState();

  const setCurriculum = async (updatedCurriculum, loadDBToApp = false, setToInitialCurriculum = false) => {
    if (setToInitialCurriculum) {
      setIsProcessing(true);
      const resp = await CourseCurriculum({ action: 'GET_INSTITUTE' });
      console.log('EPInstitute CourseCurriculum GET_INSTITUTE', resp);
      console.log('resp: ', resp.data.backendResponse);
      console.log('count: ', resp.data.backendResponse.Count);
      if (resp.status !== 200) {
        console.log('EPInstitute CourseCurriculum GET_INSTITUTE failed with status code:', resp.status);
      }
      const updatedCurriculum1 = initialCurriculum; // clear any dangling data in local object memory
      updatedCurriculum1.instituteName = resp.data.backendResponse.Items[0].Name.slice('INSTITUTE_NAME#'.length);
      updatedCurriculum1.isInstituteNameSaved = resp.data.backendResponse.Items[0].IsSavedByPublisher;
      updatedCurriculum1.instituteID = resp.data.backendResponse.Items[0].ID.slice('INSTITUTE_ID#'.length);
      console.log('Items: ', resp.data.backendResponse.Items[0]);
      await setCurriculum(updatedCurriculum1);
      console.log('updatedCurriculum1', updatedCurriculum1);
      setIsProcessing(false);
    } else {
      if (!loadDBToApp) {
        const resp2 = await CourseCurriculum({ curriculum: updatedCurriculum, action: 'SET_CURRICULUM' });
        if (resp2.status !== 200) {
          console.log('custom error handling');
        }
      }
      /*
      // Keeping following line as first line in function would eliminate surfacing up
      //  certain bugs. It is better to keep this line below.
      // Note: setCurriculum2 updates the state and re-render components using it. But
      //  the re-rendering should better made to wait till async invocation of setCurriculm
      //  is finished and then only re-rendering should happen. For example the AddSecition
      //  operation: In file AddSections.js, setSectionList() should better happen only
      //  after awaiting setCurriculum is done as otherwise setSectionList() would cause
      //  re-rendering of AddLessons component on premature curriculum where sectionList
      //  is not yet appended and will result in Index out of range error. This bug would
      //  be hidden and gets circumvented is following line is made the first line in this
      //  function.
      */

      // load the curriculum into webapp memory
      setCurriculum2(updatedCurriculum);
    }
  };

  const setEnrolledAttributes = async (updatedEnrolledCourse, loadDBToApp = false) => {
    if (!loadDBToApp) {
      const resp2 = await CourseCurriculum({ enrolledCourse: updatedEnrolledCourse, action: 'ENROLLED_COURSE_UPDATE' });
      if (resp2.status !== 200) {
        console.log('ENROLLED_COURSE_UPDATE failed. custom error handling');
      }
    }
    setEnrolledAttributes2(updatedEnrolledCourse);
  };

  const loadInitialSetup = async () => {
    // setIsUploadingViaApi(true);
    setIsProcessing(true);
    const resp = await CourseCurriculum({ action: 'GET_INSTITUTE' });
    console.log('EPInstitute CourseCurriculum GET_INSTITUTE', resp);
    console.log('resp: ', resp.data.backendResponse);
    console.log('count: ', resp.data.backendResponse.Count);
    if (resp.status !== 200) {
      console.log('EPInstitute CourseCurriculum GET_INSTITUTE failed with status code:', resp.status);
    }
    if (resp.data.backendResponse.Items.length === 0) {
      // Institute is not yet created, this is first time.
      const updatedCurriculum = initialCurriculum; // clear any dangling data in local object memory
      updatedCurriculum.instituteName = initialInstituteName;
      updatedCurriculum.isInstituteNameSaved = false;
      updatedCurriculum.instituteID = '';
      await setCurriculum(updatedCurriculum);
      console.log('updatedCurriculum', updatedCurriculum);
    } else {
      const updatedCurriculum = curriculum;
      updatedCurriculum.instituteName = resp.data.backendResponse.Items[0].Name.slice('INSTITUTE_NAME#'.length);
      updatedCurriculum.isInstituteNameSaved = resp.data.backendResponse.Items[0].IsSavedByPublisher;
      updatedCurriculum.instituteID = resp.data.backendResponse.Items[0].ID.slice('INSTITUTE_ID#'.length);
      console.log('Items: ', resp.data.backendResponse.Items[0]);
      await setCurriculum(updatedCurriculum);
      console.log('updatedCurriculum', updatedCurriculum);
    }

    // aa
    const resp2 = await CourseCurriculum({ action: 'GET_LAST_EDITED_COURSE' });
    console.log('resp2: ', resp2);
    if (resp2.status !== 200) {
      console.log('GET_LAST_EDITED_COURSE api error. take custom action', resp2.status);
    }
    if (Object.keys(resp2.data).includes('Item')) {
      const lastEditedCourseID = resp2.data.Item.CourseID;
      console.log('lastEditedCourseID', lastEditedCourseID);
      const resp22 = await CourseCurriculum({ courseID: lastEditedCourseID, action: 'GET_CURRICULUM' });
      console.log('resp22: ', resp22);
      if (resp22.status !== 200) {
        console.log('GET_CURRICULUM api error. take custom action', resp2.status);
      }
      console.log(resp22.data.Item.CourseCurriculum);
      setCurriculum(resp22.data.Item.CourseCurriculum);
    } else {
      setCurriculum(initialCurriculum);
    }

    /*
      Loading webapp, initially clicking on Enrolled->ClassRoom->Class should bring up the class/pages that the user was last reading upon (this is one of the Enrolled classes that user had enetered into).
      handleEnterClassRoom() in EPInProgressTable. There, user can chose to enter another class or leave the class.
      Here, the user is loaded with the existing state (where the user had left off last time).
      This involves:
        GET_ENTERED_CLASSROOM - get the courseID
        ENTER_CLASSROOM - use this courseID, (backend) gets courseCurriculum
        ENROLLMENT_GET_ATTRIBUTES -  for given username,courseID, load the attributes like which section/lesson user was reading at the time
    */
    const resp4 = await CourseCurriculum({ action: 'GET_ENTERED_CLASSROOM' });
    if (resp4.status !== 200) {
      console.log('GET_ENTERED_CLASSROOM failed. Custom error handling', resp4.status);
    }
    console.log(resp4);
    if (resp4.data.Item === undefined) {
      console.log('GET_ENTERED_CLASSROOM Item is null', resp4.data.Item);
    } else {
      console.log('GET_ENTERED_CLASSROOM courseID:', resp4.data.Item.CourseID);
      const resp5 = await CourseCurriculum({ courseID: resp4.data.Item.CourseID, action: 'ENTER_CLASSROOM' });
      if (resp5.status !== 200) {
        console.log('ENTER_CLASSROOM api failed. Take custom action.');
      }
      setEnrolledCurriculum(resp5.data.Item.CourseCurriculum);
      const resp6 = await CourseCurriculum({ courseID: resp4.data.Item.CourseID, action: 'ENROLLMENT_GET_ATTRIBUTES' });
      if (resp6.status !== 200) {
        console.log('ENROLLMENT_GET_ATTRIBUTES failed. Custom error handling', resp6.status);
      }
      const ll3 = resp6.data.backendResponse;
      console.log('ll3: ', ll3);
      if (ll3.Items.length === 0) {
        console.log('ENROLLMENT_GET_ATTRIBUTES returned 0 length');
      } else {
        setEnrolledAttributes(resp6.data.backendResponse.Items[0]); // this will give 400 error on 0 length
      }
    }

    // setIsUploadingViaApi(false);
    setIsProcessing(false);
  };

  useEffect(() => {
    (async () => {
      try {
        await loadInitialSetup();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="main">
        <div className="sidebar">
          <Sidebar page={page} setPage={setPage} />
        </div>
        <div className="container">
          {(isProcessing)
          && (
            <div>
              Loading..
            </div>
          )}
          {(!isProcessing)
          && (
          /* eslint-disable-next-line max-len */
          <EdTechPage page={page} setPage={setPage} curriculum={curriculum} setCurriculum={setCurriculum} initialCurriculum={initialCurriculum} selectedCatalogueIndex={selectedCatalogueIndex} setSelectedCatalogueIndex={setSelectedCatalogueIndex} isUploadingViaApi={isUploadingViaApi} setIsUploadingViaApi={setIsUploadingViaApi} enrolledAttributes={enrolledAttributes} setEnrolledAttributes={setEnrolledAttributes} enrolledCurriculum={enrolledCurriculum} setEnrolledCurriculum={setEnrolledCurriculum} enrolledCoursesDoneArray={enrolledCoursesDoneArray} setEnrolledCoursesDoneArray={setEnrolledCoursesDoneArray} enrolledCoursesProgressingArray={enrolledCoursesProgressingArray} setEnrolledCoursesProgressingArray={setEnrolledCoursesProgressingArray} />
          )}
        </div>
      </div>
    </div>
  );
}

export default EdTech;
