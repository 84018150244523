import React, { useState, useEffect } from 'react';
import { Navbar } from '../../../components';
// import Prod1 from './Prods/Prod1';
import Prod2 from './Prods/Prod2';
// import Prod3 from './Prods/Prod3';
// import Prod4 from './Prods/Prod4';
// import Prod5 from './Prods/Prod5';
// import Prod6 from './Prods/Prod6';
import FetchGroups from '../../../components/BackendServices/FetchGroups';

function ProductsList() {
  const [groups, setGroups] = useState({});

  useEffect(() => {
    (async () => {
      const userCheckGroups = await FetchGroups();
      /*
        No need to block rendering of whole Talks component.
        After Talks get rendered, user sees cards. Clicking upon this
        caed should take user either to VideoCard or to PayPage and that is when result of
        FetchGroups is needed.
      */
      //  setIsloading(false);
      setGroups(userCheckGroups);
    })();
  }, []);

  return (
    <div style={{ backgroundColor: 'gray' }}>
      <Navbar />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', background: 'purple', margin: '50px' }}>
              <Prod2 groups={groups} />
            </div>
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', background: 'purple', margin: '50px' }}>
              {/* <Prod1 groups={groups} /> */}
            </div>
          </div>
          {/*
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', background: 'purple', margin: '50px' }}>
              <Prod3 groups={groups} />
            </div>
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', background: 'purple', margin: '50px' }}>
              <Prod4 groups={groups} />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', background: 'purple', margin: '50px' }}>
              <Prod5 groups={groups} />
            </div>
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', background: 'purple', margin: '50px' }}>
              <Prod6 groups={groups} />
            </div>
          </div>
          */}
        </div>
      </div>
    </div>
  );
}

export default ProductsList;
