import React from 'react';
import './Overlay.css';

export function Overlay({ isLoginOpen, onClose, children }) {
  return (
    <>
      {isLoginOpen && (
        <div className="overlay">
          <div className="overlay__background" onClick={onClose} />
          <div className="overlay__container">
            <div className="overlay__controls">
              <button
                className="overlay__close"
                type="button"
                onClick={onClose}
                aria-label="Cancel"
              />
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default Overlay;
