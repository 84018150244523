/*  eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PDFViewerListItem from './PDFViewerListItem';

function PDFViewerListModal({ curriculum, setCurriculum, clCardItem, setClCardItem, lessonParent, isUploadingViaApi, setIsUploadingViaApi }) {
  console.log('PDFViewerListModal', lessonParent, curriculum);
  const sectionIndex = lessonParent.SectionIndex;
  const lessonIndex = lessonParent.LessonIndex;
  const data = curriculum.sectionList[sectionIndex].lessonList[lessonIndex].assets.pdf;

  if (data.length === 0) {
    return (
      <div className="VLMOD_1">
        <h1>Asset List: PDFs</h1>
        <div>
          No items
        </div>
      </div>
    );
  }

  return (
    <div className="VLMOD_1">
      <h1>Asset List: PDFs</h1>
      <ul>
        { data.map((item) => <PDFViewerListItem name={item.pdfName} id={item.pdfID} />) }
      </ul>
    </div>
  );
}

export default PDFViewerListModal;
/*  eslint-enable no-unused-vars */
