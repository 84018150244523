import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { SubHeading } from '../../../components';
//  import { images } from '../../../constants';
import './Careers.css';

const Careers = () => {
  const navigate = useNavigate();
  return (
  //  gap: '4rem' is extra here
    <div id="careers">
      {/* <div className="app__fillerForNavbar" /> */}
      <div className="app__headerIntro app__wrapper section__padding">
        <div className="app__wrapper_info_Intro">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 6 }}>
              <SubHeading title="You can initially register as a freelancer.
                Based on a successful tenure and available openings you can move
                to being an employee. It is a remote working setup. Share your
                interest with portfoliio at careers@curvsort.com."
              />
            </div>
            <div style={{ flex: 1, alignSelf: 'flex-end' }}>
              <FontAwesomeIcon
                onClick={() => { navigate('/'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}
                className="angleUpLogo"
                icon={faAngleDoubleUp}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Careers;
