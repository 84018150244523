import axios from 'axios';

const BuildWithUsBS = async (d) => {
  console.debug('BuildWithUs: ', d);
  const data = {
    bemail: d.bemail,
    fname: d.fname,
    lname: d.lname,
    cname: d.cname,
    phone: d.phone,
    country: d.country,
    industry: d.industry,
    category: d.category,
    msg: d.msg,
  };
  const baseURL = 'https://ew3yay8ui9.execute-api.ap-south-1.amazonaws.com/v1/buildwithus';
  const response = await axios.post(baseURL, data)
    //  eslint-disable-next-line prefer-arrow-callback,func-names
    .catch(function (error) {
      console.log('error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message:', error.message);
      }
      console.log(error.config);
    });
  console.debug('axios.post() response: ', response);
};

export default BuildWithUsBS;
